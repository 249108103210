<template>
        <section class="mt-2 w-100" v-if="checkoutSteps.addressOptionSelected != 0">
            <div class="position-relative">
                <!-- Stepper HTML -->
                <div class="progress-line" :class="[!confirmationTab ? 'progress-line-reponsive' : '']" v-if="paymentTab"></div>
                <div class="step" :class="{'step-active': addressTab && !confirmationTab && !paymentTab}">
                        <div>
                            <div class="circle"></div> 
                        </div>
                    <div v-if="checkoutSteps.addressOptionSelected === 1 || checkoutSteps.addressOptionSelected === 2" class="animation-slide-down">
                        <div class="title">{{$t("checkout.delivery")}}</div>
                        <div class="caption" v-if="!addressSelected">
                            <h6 class="color-dark-gray-300 size-14 ps-2 mt-2 mt-md-0">{{$t('checkout.you_dont_have_any_address_added')}} :(</h6>
                        </div>
                        <div class="caption" v-else>
                            <div class="d-flex align-items-center">
                                <span class="title-14 mb-0 text-limited text-capitalize">{{ addressSelected.name | textFormat}}</span>
                                <span v-if="addressSelected.default" class="color-dark-gray-200 ms-2">|</span>
                                <div class="d-flex align-items-center ms-2" v-if="addressSelected.default">
                                    <icon-star :size="13" class="me-1"/>
                                    <span class="text-limited title-14 mb-0">{{$t("checkout.default")}}</span>
                                </div>
                            </div>
                            <div class="d-flex flex-column">
                                <span class="text-limited subtitle-13 weight-300" v-if="addressSelected">{{addressSelected.address_line2 | textFormat}}, {{addressSelected.city.city | textFormat}}</span>
                                <span class="text-limited weight-300 subtitle-13 color-dark-gray-400">Ref: {{addressSelected.reference | textFormat}}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="checkoutSteps.addressOptionSelected === 3" class="animation-slide-down">
                        <div class="title">{{$t("checkout.pick_up")}}</div>
                        <div class="caption" v-if="partnerSelected">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="d-flex flex-column">
                                    <div class="d-flex flex-column">
                                        <span class="text-limited title-14 mb-0 text-capitalize">{{branchSelected.description | textFormat}}</span>
                                        <p class="weight-300 subtitle-13 mb-0 color-dark-gray-400 text-capitalize">{{branchSelected.address | textFormat}}, {{branchSelected.city_name | textFormat}}</p>
                                    </div>
                                    <span class="text-limited weight-300 subtitle-13 color-dark-gray-400" style="color:#a5b2b7" v-if="branchSelected.telephone">Tel: <span class="color-dark-gray-200">{{branchSelected.telephone}}</span></span>
                                </div>
                            </div>
                            <div class="d-flex flex-column mt-2">
                                <span class="title-14 mb-0">{{$t("checkout.will_pick_it_up")}}</span>
                                <span class="text-limited weight-300 subtitle-13 color-dark-gray-400 text-capitalize">{{partnerSelected.id === 0 ? '(Yo)' : ''}} {{customName | textFormat}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="step" v-if="paymentTab" :class="{'step-active': addressTab && !confirmationTab && paymentTab}">
                    <div>
                        <div class="circle"></div>
                    </div>
                    <div>
                        <div class="title">{{$t("checkout.payment_methods")}}</div>
                        <div class="caption">
                            <div class="d-flex flex-column mb-2 mt-2 animation-slide-down" v-if="cashSelected && cashSelected.balance != null && cashSelected.balance != 0 && !isNaN(cashSelected.balance)">
                                <span class="title-14 mb-0">{{$t("checkout.cash")}}</span>
                                <span class="weight-300 subtitle-13">{{$t("checkout.total")}}: {{cashSelected.balance | moneyFormat}}</span>
                            </div>
                            <div class="d-flex flex-column mb-2 mt-2 animation-slide-down" v-if="stripeSelected != null && stripeSelected.balance">
                                <span class="title-14 mb-0">{{$t("Stripe cash")}}</span>
                                <span class="weight-300 subtitle-13">{{$t("checkout.total")}}: {{stripeSelected.balance | moneyFormat}}</span>
                            </div>
                            <div class="d-flex flex-column mb-2 mt-2 animation-slide-down" v-if="creditSelected && creditSelected.balance != null && creditSelected.balance != 0 && !isNaN(creditSelected.balance)">
                                <span class="title-14 mb-0">{{$t("checkout.credit_payment")}}</span>
                                <span class="weight-300 subtitle-13">{{$t("checkout.total")}}: {{creditSelected.balance | moneyFormat}}</span>
                            </div>
                            <div class="d-flex flex-column mb-2 animation-slide-down" v-if="depositSelected.length && amountDeposit > 0">
                                <span class="title-14 mb-0">{{$t("checkout.available_deposits")}}</span>
                                <span class="weight-300" :key="item.document_id"  v-for="item in depositSelected" v-show="!isNaN(item.balanceSelected) && item.balanceSelected > 0">
                                    {{$t("checkout.total")}}: {{showValueMoney()}}$ {{parseFloat(item.balanceSelected)}}
                                </span>
                            </div>
                            <div class="d-flex flex-column animation-slide-down" v-if="depositExpressSelected.balance && depositExpressSelected.images.length >= 1">
                                <span class="title-14 mb-0">{{$t("checkout.deposit")}}</span>
                                <span class="weight-300 subtitle-13">{{$t("checkout.total")}}: {{depositExpressSelected.balance | moneyFormat}}</span>
                            </div>
                            <div class="d-flex flex-column animation-slide-down" v-if="creditNoteSelected.length && amountNote > 0">
                                <span class="title-14 mb-0">{{$t("checkout.credit_note")}}</span>
                                <span class="weight-300 subtitle-13" :key="item.document_id"  v-for="item in creditNoteSelected" v-show="!isNaN(item.balanceSelected) && item.balanceSelected > 0">
                                    {{$t("checkout.total")}}: {{item.balanceSelected | moneyFormat}}
                                </span>
                            </div>
                            <div class="d-flex flex-column animation-slide-down" v-if="paypalSelected != null  && paypalSelected.balance > 0">
                                <span class="title-14 mb-0">{{$t("checkout.paypal")}}</span>
                                <span class="weight-300 subtitle-13">{{$t("checkout.total")}}: {{paypalSelected.balance | moneyFormat}}</span>
                            </div>
                            <div class="d-flex flex-column animation-slide-down" v-if="cardSelected != null && cardSelected.balance">
                                <span class="title-14 mb-0">{{$t("checkout.credit_debit_card")}}</span>
                                <span class="weight-300 subtitle-13">{{$t("checkout.total")}}: {{cardSelected.balance | moneyFormat}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="step" :class="{'step-active': addressTab && confirmationTab && paymentTab}" v-if="confirmationTab">
                    <div>
                        <div class="circle"></div>
                    </div>
                    <div>
                        <div class="title">{{$t("checkout.confirmation")}}</div>
                    </div>
                </div>
            </div>
            <hr class="my-0 mt-4 w-100">
        </section>
</template>
<script>
import { mapGetters } from "vuex"
import IconStar from '../../../common/svg/iconStar.vue'

export default {
    name: 'stepperIndicator',
    computed:{
        ...mapGetters({
            cardSelected: "checkout/cardSelected",
            paypalSelected:  "checkout/paypalSelected",
            singleCardSelected: "checkout/singleCardSelected",
            paymentOptionSelected: "checkout/optionSelected",
            cashSelected: "checkout/cashSelected",
            stripeSelected: "checkout/stripeSelected",
            creditSelected: "checkout/creditSelected",
            depositSelected: "checkout/depositSelected",
            depositExpressSelected: "checkout/depositExpressSelected",
            creditNoteSelected: "checkout/creditNoteSelected",
            checkoutSteps: "checkout/checkoutSteps",
            addressTab: "checkout/addressTab",
            paymentTab: "checkout/paymentTab",
            confirmationTab: "checkout/confirmationTab",
            customer_name: "checkout/customer_name",
            amount: "cart/amount",
            partnerSelected: 'checkout/partnerSelected',
            addressSelected: "account/addressSelected",
            branchSelected: "branches/branchSelected",

        }),
        amountDeposit(){
            let sum = 0;
            this.depositSelected.forEach(element =>  sum += element.balanceSelected ? parseFloat(element.balanceSelected) : 0);
            return sum
        },
        amountNote(){
            let sum = 0;
            this.creditNoteSelected.forEach(element =>  sum += element.balanceSelected ? parseFloat(element.balanceSelected) : 0);
            return sum
        },
        customName(){
            if(!this.customer_name){
                return this.partnerSelected.full_name
            }
            return this.customer_name
        }
    },
    components:{
        IconStar
    },
}

</script>

<style scoped>
/* Steps */
.step {
    position: relative;
    min-height: 1em;
    color: gray;
}
.step + .step {
    margin-top: 1.5em
}
.step > div:first-child {
    position: static;
    height: 0;
}
.step > div:not(:first-child) {
    margin-left: 1.5em;
}

.step.step-active .circle {
    background-color: var(--main-red);
}

/* Circle */
.circle {
    background: #212e3d;
    position: relative;
    width: 0.8em;
    height: 0.8em;
    line-height: 15px;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    z-index: 1;
}

.progress-line{
    position: absolute;
    left: calc(2% + 1px);
    height: calc(100% - 4%);
    width: 1px;
    background-color: #212e3d;
    z-index: 1;
}
.step:last-child .circle:after {
    display: none
}

/* Stepper Titles */
.title {
    line-height: 1.5em;
    font-weight: bold;
    color: #212e3d;
    margin-top: -5px;
}

.caption {
    font-size: 0.8em;
    /* margin-left: 1.5em; */
    margin-top: 10px;
}
.titleSpan{
    color: #212e3d;
    font-size: 14px;
}
.iconSize{
    font-size: 10px;
    margin-bottom: 2px;
}
</style>
