<template>
        <main-collapse class="mt-3" id="collapse" @open="open" :class="[collapse ? 'open-collapse' : 'close-collapse']">
            <div class="row justify-content-between">
                <div class="col-12" :class="[addresses.length > 0 ? 'col-md-7' : 'col-md-8']">
                    <h4  @click="collapse = !collapse" class="d-flex justify-content-between title-18 mb-2 mb-md-1">
                        {{$t("checkout.my_addresses")}} 
                        <icon-arrow class="icon-normal d-block d-md-none mb-1" :class="[{'icon-active': collapse}]" color="var(--dark-gray-200)"/>
                    </h4>
                    <div class="d-flex flex-column" v-if="checkoutSteps.addressOptionSelected != 2">
                        <div class="d-flex justify-content-between">
                            <h6 class="subtitle-14 mb-0 d-flex align-items-center color-dark-gray-300">
                                <icon-check-order :size="20" class="me-2 d-none d-md-block"/>
                                {{$t(`checkout.${getDimensions ? 'mobile_delivery_from_4_to_5' : 'delivery_from_4_to_5'}`)}}
                            </h6>
                            <span v-if="addresses.length > 0" class="d-block d-md-none subtitle-14">
                                {{$t("checkout.delivery_service")}} 
                                <b>{{ checkoutSteps.addressOptionSelected == 2 ? carrierSelected.amount : addressSelectedAvailable.price | moneyFormat}}</b>
                            </span>
                        </div>
                        <div class="d-flex d-md-none subtitle-14 justify-content-between">
                            <span>Lun - Vier</span>
                            <span class="main-link" @click="showDeliveryDetail = true">{{$t("checkout.see_details")}}</span> 
                        </div>
                    </div>
                    <div v-if="checkoutSteps.addressOptionSelected == 2" class="d-flex flex-column">
                        <div class="d-flex justify-content-between">
                            <h6 class="subtitle-14 mb-0 d-flex align-items-center color-dark-gray-300">
                                <template  v-if="carrierSelected.object_id">
                                    <img :src="carrierSelected.provider_image_75" :size="20" class="me-2 d-none d-md-block">
                                    {{$t(carrierSelected.duration_terms || carrierSelected.servicelevel.display_name)}}
                                </template>
                            </h6>
                            
                            <!-- <span v-if="addresses.length > 0" class="d-block d-md-none subtitle-14">
                                {{$t("checkout.delivery_service")}} 
                                <b>{{ checkoutSteps.addressOptionSelected == 2 ? carrierSelected.amount : addressSelectedAvailable.price | moneyFormat}}</b>
                            </span> -->
                        </div>
                        <!-- <div class="d-flex d-md-none subtitle-14 justify-content-between">
                            <span>Lun - Vier</span>
                            <span class="main-link" @click="showDeliveryDetail = true">{{$t("checkout.see_details")}}</span> 
                        </div> -->
                    </div>
                </div>
                <div class="container-arrow d-none d-md-flex align-items-end flex-column ps-0" :class="[addresses.length > 0 ? 'col-md-5' : 'col-md-4']">
                    <icon-arrow class="icon-normal mb-1" :class="[{'icon-active': collapse}]" color="var(--dark-gray-200)"/>
                    <div class="d-flex flex-column flex-xl-row justify-content-end align-items-end ">
                        <h6 v-if="addressSelectedAvailable" class="d-flex flex-column align-items-end subtitle-14 pt-1 mb-0">
                            <span>{{$t("checkout.delivery_service")}} <b>{{ checkoutSteps.addressOptionSelected == 2 ? carrierSelected.amount : addressSelectedAvailable.price | moneyFormat}}</b></span>
                        </h6>
                        <span v-if="checkoutSteps.addressOptionSelected != 2" class="main-link subtitle-14 ms-4" @click="showDeliveryDetail = true">{{$t("checkout.see_details")}}</span> 
                        <span v-if="checkoutSteps.addressOptionSelected == 2"   class="main-link subtitle-14 ms-4" @click="showShippoDelivery()">{{$t("Show carrier method")}}</span> 
                    </div>
                </div>
            </div>
            <span v-if="checkoutSteps.addressOptionSelected == 2" class="main-link subtitle-14 d-block d-md-none" @click="showShippoDelivery()">{{$t("Show carrier method")}}</span>
            <alert-warning v-if="checkoutSteps.addressOptionSelected == 2" style="padding: 2px 10px; background-color: #e9bb0b1f;" :size="50" :show="!carrierSelected.object_id" title="You have no carrier selected" :showCounter="false" class="mt-3" :vibration="true" id="alert-warning-without-delivery" />
            <alert-warning v-if="checkoutSteps.addressOptionSelected != 2" style="padding: 2px 10px" :size="50" :show="addressSelectedAvailable ? !addressSelectedAvailable.available : false"
                :title="`account.${getDimensions ? 'the_branch_does_not_have_delivery_from_this_branch' : addresses.length > 0 ?
                'we_are_sorry_at_the_current_address_you_have_selected' : 'you_must_create_and_select_at_least_one_address_to_continue'}`" 
                :showCounter="false" class="mt-3" :class="{'address-alert-warning':rebound}" id="alert-warning-without-delivery"/>
            <hr />
            <div @click="collapse = !collapse">
                <h6 v-if="!mobile" class="title-16 mb-2 mb-md-3">{{$t("checkout.selected_address")}} 
                    <span class="subtitle-16 color-dark-gray-300 ms-1">({{$t("checkout.click_to_change")}})</span>
                </h6>
                <h6 v-if="addresses.length == 0" class="color-dark-gray-300 size-14 ps-2 mt-2 mt-md-0">{{$t('checkout.you_dont_have_any_address_added')}} :(</h6>
                <address-selected v-if="!collapse" class="mt-3 mb-0" />
            </div>
            <hr v-if="!collapse || addresses.length == 0">
            <list-address v-if="addresses.length > 0 && collapse" :addresses="addresses" class="mt-0" @openModal="showAddressModal =  true" @addressData="(data) => {addressEditData = data}"/>
            <button class="title-16 mb-0 d-flex align-items-center bg-transparent border-0" @click="addressEditData = null; showAddressModal = true">
                <icon-add :size="25" class="me-2"/>
                {{$t("checkout.add_new_address") | textFormat}}
            </button>
            <create-address v-if="showAddressModal" @closeModal="showAddressModal = false" :addressEditData="addressEditData" @showShortNotify="(data)=>{notifyTitle = data;showShortNotify = true}"/>
            <delivery-detail @closeModal="showDeliveryDetail = false" :branch="branchSelected" :delivery="addressSelectedAvailable.delivery" :lat="addressSelected.latitud" :long="addressSelected.longitud" :quantity="quantity" v-if="showDeliveryDetail"/>
            <Checkout-shippo :mutateProducts="mutateProducts"  v-if="showShippo" @closeModal="showShippo = false "/>
        </main-collapse>
</template>

<script>
import MainCollapse from '../../../../common/components/collapse/MainCollapse.vue'
import ListAddress from '../../../account/components/addresses/ListAddress.vue'
import { mapGetters } from "vuex"
import IconAdd from '../../../../common/svg/iconAdd.vue'
import CreateAddress from '../../../account/components/addresses/modals/CreateAddress.vue'
import IconArrow from '../../../../common/svg/iconArrow.vue'
import IconCheckOrder from '../../assets/svg/iconCheckOrder.vue'
import AddressSelected from '../../../account/components/addresses/AddressSelected.vue'
import CheckoutShippo from './CheckoutShippo.vue'
import AlertWarning from '../../../../common/components/alerts/AlertWarning.vue'
import DeliveryDetail from '../modals/DeliveryDetail.vue'

export default {
    name: "CheckoutDelivery",
    data() {
        return {
            collapse: true,
            showAddressModal: false,
            addressEditData: null,
            showShortNotify: false,
            showDeliveryDetail: false,
            rebound: false,
            showShippo: false,
            reboundCounter: null,
            mutateProducts: null,
        }
    },
    props: {
        addressAvailable: {
            type: Number
        }
    },
    components:{
        MainCollapse,
        ListAddress,
        IconAdd,
        CreateAddress,
        IconArrow,
        IconCheckOrder,
        AddressSelected,
        AlertWarning,
        DeliveryDetail,
        CheckoutShippo
    },
    computed: {
        ...mapGetters({
                addresses: "account/getAddresses",
                configurations: "common/configurations",
                checkoutSteps: "checkout/checkoutSteps",
                addressSelectedAvailable: "account/addressSelectedAvailable",
                addressSelected: "account/addressSelected",
                branchSelected: "branches/branchSelected",
                quantity: "cart/quantityProducts",
                mobile: "common/mobile",
                carrierSelected: "account/carrierSelected",
                cartProducts: 'cart/getProducts'
        }),
        getDimensions(){
            return window.innerWidth <= 576
        }
    },
    methods: {
        open(data) {
            if(data.id === "collapse")
            this.collapse = !this.collapse;
        },
        showShippoDelivery(){
            let products = this.$store.getters['cart/getProducts'];
            let productByPlaces = {};
            productByPlaces[this.branchSelected.codplace] = []
            for (let product of products){
                if(this.checkoutSteps.addressOptionSelected === 2 && this.configurations.delivery_options.local_delivery.code == product.product.codarticulo){
                    // console.log(hola);
                }else{
                    productByPlaces[this.branchSelected.codplace].push(
                        {
                        codarticulo: product.product.codarticulo,
                        quantity: product.quantity,
                        note: product.note
                    })
                }
            }
            this.mutateProducts = productByPlaces
            this.showShippo = true
        }
    },
    watch: {
        addressAvailable(){
            let alert = document.getElementById('alert-warning-without-delivery')
            window.scroll({top: alert.getBoundingClientRect().top, behavior: 'smooth'});

            setTimeout(() => {
                this.rebound = true
            }, 500);
            this.reboundCounter = setTimeout(() => {
                this.rebound = false
            }, 1000);
        },
    },
    beforeDestroy(){
        clearTimeout(this.reboundCounter);
    },
}
</script>

<style scoped>
    #collapse {
        user-select: none;
        transition: var(--transition-1);
    }
    #collapse:hover {
        border-color: #ffffff00 !important;
        cursor: pointer;
        box-shadow: 0px 0px 6px 0px rgb(144 157 164 / 13%);
    }
    #collapse:hover > .row > .container-arrow > svg {
        fill: var(--main-navy);
    }

    .address-alert-warning {
        animation: 0.2s horizontal-shaking;
        animation-iteration-count: infinite;
    }
    @keyframes horizontal-shaking {
        0% { transform: translateX(0) }
        25% { transform: translateX(5px) }
        50% { transform: translateX(-5px) }
        75% { transform: translateX(5px) }
        100% { transform: translateX(0) }
    }

/* re-adjusting list-address */
    .list-address::v-deep .info-separator {
        display: none !important;
    }
    .list-address::v-deep .address-phone {
        display: block !important;
    }
    .list-address::v-deep .address-name {
        display: block !important;
        max-width: 141px !important;

        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
    }
    @media (min-width: 1200px) {
        .list-address::v-deep .address-name {
            display: inline-block !important;
            vertical-align: bottom;
        }
    }
    @media (min-width: 1400px) {
        .list-address::v-deep .info-separator {
            display: inline-block !important;
        }
        .list-address::v-deep .address-phone {
            display: inline !important;
        }
    }


</style>