<template>
    <card>
        <h4 class="title-20 mb-0">{{$t("checkout.order_summary")}}</h4>
        <h6 class="title-15 mb-0 color-dark-gray-300 weight-500 text-nowrap text-capitalize">{{$t("checkout.branch")}} <b class="weight-600">{{branchSelected.description | textFormat}}</b> </h6>
        <hr class="w-100">
        <div v-if="user.entity.code == 1" class="mb-0">           
            <h6 class="size-14 mb-1">{{$t('auth.name')}}</h6>
            <span class="text-danger weight-600" v-if="this.customer_name == null || this.customer_name == ''">* {{ $t("common.required_field") }}</span>
            <main-input id="name" placeholder="Cliente" type="text" :value="fullName" @changeValue="(data) => {fullname = data}" v-model="customer_name">
                <icon-person :size="15" :color="'var(--dark-gray-200)'"/>
            </main-input>
            <hr class="w-100">
        </div>
        <stepper-indicator/>
        <div class="w-100" :class="[checkoutSteps.addressOptionSelected === 0 ? 'mt-1' : 'mt-4']">
            <div class="d-flex justify-content-between">
                    <h6 class="title-15 color-dark-gray-300 weight-400">{{$t("cart.subtotal")}}</h6>
                    <h6 class="title-15 weight-400">{{orderConfirm.total_order_without_taxes | moneyFormat}}</h6>
            </div>
            <div class="d-flex justify-content-between">
                    <h6 class="title-15 color-dark-gray-300 weight-400">{{$t("account.tax")}}</h6>
                    <h6 class="title-15 weight-400">{{orderConfirm.total_order_taxes | moneyFormat}}</h6>
            </div>
            <div class="d-flex justify-content-between" v-if="!confirmationTab">
                    <h6 class="title-15 color-dark-gray-300 weight-400">{{$t("checkout.total_to_pay")}}</h6>
                    <h6 class="title-15 weight-400">{{orderConfirm.total_order | moneyFormat}}</h6>
            </div>
            <div class="d-flex justify-content-between" v-if="!confirmationTab">
                    <h6 class="title-15 color-dark-gray-300 weight-600">{{$t("checkout.total_paid")}}</h6>
                    <h6 class="title-15 weight-600">{{amountSelected | moneyFormat}}</h6>
            </div>
            <div class="mt-3 dashed"></div>
            <div class="d-flex justify-content-between mt-3" v-if="!confirmationTab">
                    <h6 class="title-16 mb-0 color-dark-main-navy weight-600">{{$t("checkout.difference")}}</h6>
                    <span v-show="difference > 0" class="text-danger weight-600">
                        - {{difference | moneyFormat}}
                    </span>
                    <span v-show="difference <= 0" class="color-green-600 weight-600">
                        {{difference | moneyFormat}}
                    </span>
            </div>
            <div class="p-1 d-flex justify-content-between mt-3" v-else>
                    <h6 class="m-0 weight-600">{{$t("checkout.total_to_pay")}}</h6>
                    <span class="color-green-600 weight-600 align-items-center">
                        {{ orderConfirm.total_order | moneyFormat}}
                    </span>
            </div>
            <div class="mt-3"  v-if="confirmationTab">
                <template  v-if="this.configurations.general.web_page.tax_receipt"> 
                    <hr>
                        <a href="#" class="d-flex justify-content-center py-2 main-link subtitle-14" @click.prevent="showTaxReceipt = true" v-if="!rnc">
                            {{$t("checkout.do_you_want_tax_receipt")}} ?
                        </a>
                            <tax-receipt-information v-if="rnc" :rnc="rnc" @change="showTaxReceipt = true"/>
                </template>
                    <hr>
                <div class="d-flex flex-column justify-content-center align-items-center mt-4">
                    <!-- <pay-pal :amount="amountUSD" currency="USD" :client="paypal" env="sandbox" v-if="showPaypal"
                        :button-style="{label: 'checkout',size:  'medium',shape: 'rect',color: 'silver'}" 
                        @payment-completed="(data)=>{$emit('makeOrderWithPaypal', data)}" class="mb-4" :key="`${rnc}-1`">
                    </pay-pal> -->
                    <main-button :title="'checkout.make_a_purchase'" @click="$emit('makeOrder')" class="mb-4" v-if="!showPaypal"/>
                    <span class="text-center subtitle-13" style="max-width: 230px">{{$t("checkout.by_purchasing_you_agree_to_the")}} 
                        <a href="/home/policies/view-privacy" target="_blank">{{$t("checkout.privacy_policy")}}</a>  {{$t("checkout.and_the")}} 
                        <a href="/home/policies/pr" target="_blank">{{$t("checkout.terms_of_use")}}</a> 
                    </span>
                </div>
                <tax-receipt @closeModalTaxReceipt="showTaxReceipt = false" v-if="showTaxReceipt"/>
            </div>
        </div>
    </card>
</template>

<script>
import Card from "../../../common/components/cards/Card.vue"
import IconPerson from '../../../common/svg/iconPerson.vue'
import MainInput from '../../../common/components/inputs/MainInput.vue'
import { mapGetters } from "vuex"
import StepperIndicator from './StepperIndicator';
import MainButton from '../../../common/components/buttons/MainButton.vue';
import TaxReceipt from './modals/TaxReceipt.vue';
import TaxReceiptInformation from './TaxReceiptInformation.vue';

export default {
    name: "GeneralInformation",
    components: { Card, StepperIndicator, MainButton, TaxReceipt, TaxReceiptInformation, MainInput, IconPerson },
    computed:{
        ...mapGetters({
            branchSelected: "branches/branchSelected",
            user: "auth/userData",
            orderConfirm: "checkout/orderConfirm",
            addressSelectedAvailable: "account/addressSelectedAvailable",
            addresses: "account/getAddresses",
            addressSelected: "account/addressSelected",
            confirmationTab: "checkout/confirmationTab",
            difference: "checkout/difference",
            rnc: 'checkout/rnc',
            checkoutSteps: "checkout/checkoutSteps",
            amountSelected: "checkout/amountSelected",
            configurations: "common/configurations"


        }),
        fullName(){
            return this.$options.filters.fullName(this.user.entity);           
        },
    },
    props:{
        changeRnc: {
            type: Boolean
        },
        amountUSD:{
            type: [Number, String]
        },
        showPaypal: {
            type: Boolean
        }
    },
    data() {
        return {
            showTaxReceipt: false,
            customer_name: '',
            paypal: {
                sandbox: null,
                production: null
            },
        }
    },
    watch:{
        customer_name(val) {
            this.$store.commit("checkout/SET_CUSTOMER_NAME", val);
        },
        changeRnc(){
            this.showTaxReceipt = true
        },
    },
    created(){
        this.paypal.sandbox = this.configurations.general.web_page.paypal_develop_var
        this.paypal.production = this.configurations.general.web_page.paypal_production_var
    }
}
</script>

<style scoped>
    .dashed {
        background-color: #fff;
        border-top: 2px dashed #607D8B;
        opacity: 0.2;
    }
</style>
