<template>
    <div class="row mt-4 mb-3">
        <div class="col-sm-12 ">
            <span class="title-16">{{$t("checkout.gift_card_balance")}}: <b class="color-green-600 weight-400">$0.00</b></span>
        </div>
        <div class="col-sm-6 mt-2">
            <main-input :title="'checkout.enter_amount_to_pay'" :id="'amount'" :placeholder="'RD$ 0.00'">
            </main-input>
        </div>
        <div class="col-sm-6 mt-2">
            <main-input :title="'checkout.new_gift_card'" :id="'new-card'" :placeholder="'checkout.enter_your_code_here'">
                <icon-gitf-card/>
            </main-input>
        </div>
        <div class="col-sm-12 mt-3 d-flex align-items-center">
            <main-switch class="me-2" :id="'pay-full'"/>
            <span class="subtitle-13">{{$t("checkout.pay_full")}}</span>
        </div>
    </div>
</template>

<script>
import MainInput from '../../../../common/components/inputs/MainInput.vue'
import MainSwitch from '../../../../common/components/switch/MainSwitch.vue'
import IconGitfCard from '../../assets/svg/iconGitfCard.vue'
export default {
    components: { MainInput, IconGitfCard, MainSwitch },
    name: "GiftCard"
}
</script>