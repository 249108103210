<template>
    <div class="row">
        <!-- <div class="col-md-6 d-flex align-items-center"> -->
        <div class="col-lg-8 d-flex justify-content-between size-14">
            <div class="text-limited d-flex align-items-center text-capitalize">
                <radio-button :checked="true" style="margin-top: 3px;" class="me-2"/>
                <div class="me-1">
                    <icon-star :size="17" v-if="partnerSelected.default"/>
                </div>
                {{ partnerSelected.full_name | textFormat}}
            </div>
                <span class="mx-3 color-dark-gray-200">|</span>
            <div class="text-limited">
                <span>Tel:</span>
                <span class="subtitle-14 color-dark-gray-300 mr-2 ml-1">
                    {{partnerSelected.whatsapp | phoneFormat }}
                </span>
            </div>
                <span class="mx-3 color-dark-gray-200">|</span>
            <div class="text-limited">
                <span>ID:</span>
                <span class="color-dark-gray-300">
                    {{ partnerSelected.identification }}
                </span>
            </div>
        </div>
        <div class="col-lg-4 d-flex justify-content-end size-14">
            <a href="#" @click.prevent="editPartner()" class="main-link" v-if="partnerSelected.id != 0">{{$t("checkout.edit")}}</a>
            <span class="ms-4 me-4 color-dark-gray-300" v-if="partnerSelected.id != 0">|</span>
            <a href="#" @click.prevent="deletePartner(partnerSelected.id)" class="main-link" v-if="partnerSelected.id != 0">{{$t("checkout.remove")}}</a>
        </div>
        <!-- </div> -->
        <!-- <div class="col-md-6 d-flex" v-if="partnerSelected.identification">
        </div> -->
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import RadioButton from '../../../../common/components/radio/RadioButton.vue'
import IconStar from '../../../../common/svg/iconStar.vue'
export default {
    components: { RadioButton, IconStar },
    name: "SelectedParnet",
    computed:{
        ...mapGetters({
            partnerSelected: "checkout/partnerSelected",

        }),
    },

}
</script>
