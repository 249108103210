<template>
    <icon-base :width="size + 'px'" :fill="color">
        <switch>
            <g i:extraneous="self">
                <g>
                    <circle class="st0" cx="125" cy="125" r="124.5"/>
                </g>
                <g>
                <g>
                    <path class="st1" d="M86,82.3c2.3,0,4.1-1.9,4.1-4.1V60.6c0-2.3-1.9-4.1-4.1-4.1c-2.3,0-4.1,1.9-4.1,4.1v17.6      C81.8,80.5,83.7,82.3,86,82.3z"/>
                    <path class="st1" d="M122.3,82.3c2.3,0,4.1-1.9,4.1-4.1V60.6c0-2.3-1.9-4.1-4.1-4.1s-4.1,1.9-4.1,4.1v17.6      C118.2,80.5,120,82.3,122.3,82.3z"/>
                    <path class="st1" d="M158.7,82.3c2.3,0,4.1-1.9,4.1-4.1V60.6c0-2.3-1.9-4.1-4.1-4.1c-2.3,0-4.1,1.9-4.1,4.1v17.6      C154.5,80.5,156.4,82.3,158.7,82.3z"/>
                    <path class="st1" d="M123.1,160.7c0-20.3,16.5-36.8,36.8-36.8c11.1,0,21,4.9,27.8,12.7V84.5c0-8.3-6.8-15.1-15.1-15.1H166v8.8      c0,4.1-3.3,7.4-7.4,7.4c-4.1,0-7.4-3.3-7.4-7.4v-8.8h-21.6v8.8c0,4.1-3.3,7.4-7.4,7.4c-4.1,0-7.4-3.3-7.4-7.4v-8.8H93.3v8.8      c0,4.1-3.3,7.4-7.4,7.4c-4.1,0-7.4-3.3-7.4-7.4v-8.8h-6.2c-8.3,0-15.1,6.8-15.1,15.1v88.9c0,8.3,6.8,15.1,15.1,15.1h63.5      C128,181.7,123.1,171.7,123.1,160.7z M158.5,104.8c3.1,0,5.6,2.5,5.6,5.6c0,3.1-2.5,5.6-5.6,5.6c-3.1,0-5.6-2.5-5.6-5.6      C152.9,107.3,155.4,104.8,158.5,104.8z M134.3,104.8c3.1,0,5.6,2.5,5.6,5.6c0,3.1-2.5,5.6-5.6,5.6c-3.1,0-5.6-2.5-5.6-5.6      C128.8,107.3,131.2,104.8,134.3,104.8z M86,164.3c-3.1,0-5.6-2.5-5.6-5.6s2.5-5.6,5.6-5.6s5.6,2.5,5.6,5.6S89,164.3,86,164.3z       M86,140.1c-3.1,0-5.6-2.5-5.6-5.6c0-3.1,2.5-5.6,5.6-5.6s5.6,2.5,5.6,5.6C91.5,137.6,89,140.1,86,140.1z M86,115.9      c-3.1,0-5.6-2.5-5.6-5.6c0-3.1,2.5-5.6,5.6-5.6s5.6,2.5,5.6,5.6C91.5,113.4,89,115.9,86,115.9z M110.1,164.3      c-3.1,0-5.6-2.5-5.6-5.6s2.5-5.6,5.6-5.6c3.1,0,5.6,2.5,5.6,5.6S113.2,164.3,110.1,164.3z M110.1,140.1c-3.1,0-5.6-2.5-5.6-5.6      c0-3.1,2.5-5.6,5.6-5.6c3.1,0,5.6,2.5,5.6,5.6C115.7,137.6,113.2,140.1,110.1,140.1z M110.1,115.9c-3.1,0-5.6-2.5-5.6-5.6      c0-3.1,2.5-5.6,5.6-5.6c3.1,0,5.6,2.5,5.6,5.6C115.7,113.4,113.2,115.9,110.1,115.9z"/>
                    <path class="st1" d="M171.6,166.5l-9.2-6.9v-14c0-1.4-1.1-2.5-2.5-2.5s-2.5,1.1-2.5,2.5v15.3c0,0.8,0.4,1.6,1,2l10.2,7.6      c0.5,0.3,1,0.5,1.5,0.5c0.8,0,1.5-0.3,2-1C173,168.9,172.7,167.3,171.6,166.5z"/>
                    <path class="st1" d="M159.9,127.8c-18.1,0-32.9,14.7-32.9,32.9s14.7,32.9,32.9,32.9c18.1,0,32.9-14.7,32.9-32.9      S178,127.8,159.9,127.8z M159.9,188.4c-15.3,0-27.8-12.5-27.8-27.8c0-15.3,12.5-27.8,27.8-27.8c15.3,0,27.8,12.5,27.8,27.8      C187.7,176,175.2,188.4,159.9,188.4z"/>
                </g>
                </g>
            </g>
        </switch>
    </icon-base>
</template>
<script>

export default {
name: 'iconCash',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>
    .st0{fill:#DCE4EA;}
    .st1{fill:#111820;}
</style>
