<template>
    <div class="position-relative">
        <div class="container container-checkout">
            <div class="row my-2 my-md-5 mx-0 justify-content-between checkout-page">
                <div class="col-12 d-flex justify-content-between p-0 mb-4 mb-md-5">
                    <a @click="reset" class="d-flex align-items-center main-link-container mt-0">
                        <icon-arrow :size="14" style="transform:rotate(180deg);" color="var(--blue-600)" />
                        <span class="ms-2 main-link d-none d-md-block">
                            {{$t("checkout.back_to_cart")}}
                        </span>
                    </a>
                    <h4 class="text-center weight-700 mb-0 d-block d-md-none">Checkout</h4>
                    <!-- <div v-if="selectedOption.id === 2 && paypalSelected.balance > 0" id="paypal-button-container"></div> -->
                    <a v-if="paypalSelected.balance == 0" class="d-flex align-items-center main-link-container mt-0" @click="next">
                        <span class="me-2 main-link d-none d-md-block">
                            {{selectedOption.id != 2 ? $t('checkout.next') : $t('checkout.finish')}}
                        </span>
                        <icon-arrow :size="14" :color="'var(--blue-600)'"/>
                    </a>
                </div>
                <div class="col-md-12 col-xl-3 col-lg-3 p-0 general-information-desktop">
                    <div class="contaner-general">
                        <div class="row">
                            <div class="col-xl-6 col-lg-12">
                                <div class="d-flex align-items-center mb-4 title-22"> <icon-buy :size="25" class="me-1"/> {{$t("checkout.checkout")}}</div>
                            </div>
                            <div v-if="this.selectedQuotation" class="col-xl-6 col-lg-12">
                                <p class="mt-1 text-xl-end color-blue-600">{{ this.selectedQuotation }}</p>
                            </div>
                        </div>

                        <general-information class="p-4" @makeOrder="makeOrder" :changeRnc="changeRnc" :amountUSD="amountUSD" :showPaypal="selectedOption.id === 2 && paypalSelected.balance > 0" 
                            @makeOrderWithPaypal="makeOrderWithPaypal"/>
                        <div class="card-links d-flex flex-column mt-5">
                            <router-link to="/home/policies/view-privacy" target="_blank" class="mt-0">{{$t("checkout.view_security_&_privacy_policy")}}</router-link>
                            <a href="#" @click.prevent="showBankAccounts = true">{{$t("checkout.view_bank_accounts")}}</a>
                            <router-link to="/home/policies/delivery" target="_blank">{{$t("checkout.delivery_policies")}}</router-link>
                        </div>
                    </div>
                </div>
                <div class="p-0 col-md-12 col-lg-8">
                    <div class="wrapper-nav-tab">
                        <second-nav-tab :options="options" :selectedOption="selectedOption" @select="selectOptionTab" class="mb-0 mb-md-4"/>
                    </div>
                    <alert-warning style="padding: 2px 15px" :show="showAlert.show" @showAlert="selectOne = false; payDifference = false; paymentTabRequired = false" 
                    :title="showAlert.title" :showCounter="true" shaking :animationReplay="shaking"/>
                    <checkout-address v-show="addressTab && !paymentTab && !confirmationTab" :addressAvailable="addressNotAvailable" @optionNotAvailable="optionNotAvailable = true"/>
                    <checkout-payments v-show="addressTab && paymentTab && !confirmationTab"/>
                    <checkout-confirmation v-show="addressTab && paymentTab && confirmationTab" @changeRnc=" changeRnc = !changeRnc"/>
                    <div class="d-none mt-5 d-md-flex align-items-start" :class="[selectedOption.id != 0 ? 'justify-content-between' : 'justify-content-end']">
                        <second-button :title="'checkout.back'" @click="back" v-if="selectedOption.id != 0" :icon="true" style="min-width: 111px;">
                            <icon-arrow :size="13" class="me-2" style="transform: rotate(180deg);"/>
                        </second-button>
                        <div v-if="selectedOption.id === 2 && paypalSelected.balance > 0" id="paypal-button-container1"></div>
                        <main-button :title="selectedOption.id != 2 ? 'checkout.next' : 'checkout.finish' " :icon="true" :iconRight="true" :class="[checkoutSteps.addressOptionSelected != 3 && !addressSelectedAvailable ||  checkoutSteps.addressOptionSelected === 0 ? 'disabled' : '']" @click="next" v-else>
                            <icon-arrow :size="13" :color="'#fff'" class="ms-2"/>  
                        </main-button>
                    </div>
                </div>
                <button @click="payWithAzul" class="btn btn-primary" v-if="false">pay with azul</button>
                <make-order-card  @closeModal="cardProblem = false" @confirm="makeOrder" :description="errorDescription" :products="productsError" v-if="cardProblem"/>
                <make-order-try-again @closeModal="showAlertTryAgain = false" @confirm="makeOrder" @reset="reset" :description="errorDescription" :products="productsError" v-if="showAlertTryAgain"/>
                <option-not-available v-if="optionNotAvailable" @closeModal="optionNotAvailable = false"/>
                <bank-accounts v-if="showBankAccounts" @closeModal="showBankAccounts = false"/>
                <check-loading v-if="loading"/>
                <order-confirmed :orderDocument="order.order_token_id" :orderId="order.id" @closeOrderConfirmed="finalizeOrder" v-if="orderConfirmed"/>
            </div>
        </div>
        <div class="bottom-sheet d-block d-md-none">
            <div class="container d-flex justify-content-between align-items-center">
                <div>
                    <h6 class="size-13 mb-1">{{$t("checkout.total_to_pay")}}</h6>
                    <h3 class="weight-700 size-17 color-main-red mb-0">{{ orderConfirm.total_order | moneyFormat}}</h3>
                </div>
                <div class="d-flex my-2 justify-content-center">
                    <div  v-if="selectedOption.id === 2 && paypalSelected.balance > 0" id="paypal-button-container2" :class="{'position-button-paypal' : !verifyUSTECH()}"></div>
                    <main-button v-else :title="selectedOption.id != 2 ? 'checkout.next' : 'checkout.make_a_purchase' " style="width: 200px;height: 38px;border-radius: 50px;" :icon="true" :iconRight="true" :class="[checkoutSteps.addressOptionSelected != 3 && !addressSelectedAvailable || checkoutSteps.addressOptionSelected === 0 ? 'disabled' : '']" @click="next"> 
                        <icon-arrow :size="13" :color="'#fff'" class="ms-2"/>  
                    </main-button>
                </div>
            </div>
        </div>
        <error-cart v-if="errorModal" @closeModal="errorModal = false" title="Error procesando su orden" description="Hubo un error :(" />
        <invoiced-difference @confirm="ignoreDiffFunction" @closeModal="showDiff = false" :totalDifference="totalDifference" v-if="showDiff"/>
        <checkout-shippo :mutateProducts="mutateProducts" v-if="showCarrier" @closeModal="showCarrier = false " />
    </div>
</template>

<script>
import MainButton from '../../../common/components/buttons/MainButton.vue'
import SecondNavTab from '../../../common/components/tabs/SecondNavTab.vue'
import CheckoutAddress from '../components/CheckoutAddress.vue'
import CheckoutShippo from '../components/address/CheckoutShippo.vue'
import CheckoutConfirmation from '../components/CheckoutConfirmation.vue'
import CheckoutPayments from '../components/CheckoutPayments.vue'
import GeneralInformation from "../components/GeneralInformation.vue"
import { mapGetters } from "vuex"
import AlertWarning from '../../../common/components/alerts/AlertWarning.vue'
import SecondButton from '../../../common/components/buttons/SecondButton.vue'
import { orderService } from "../../account/services/order-service";
import IconArrow from '../../../common/svg/iconArrow.vue'
import OptionNotAvailable from '../components/modals/OptionNotAvailable.vue'
import BankAccounts from '../components/modals/BankAccounts.vue'
import CheckLoading from '../components/CheckLoading.vue'
import OrderConfirmed from '../components/OrderConfirmed.vue'
import InvoicedDifference from '../components/modals/InvoicedDifference.vue'
import { http } from "../../../common/services/web-backend";
import IconBuy from '../../../common/svg/iconBuy.vue'
import MakeOrderTryAgain from '../components/modals/MakeOrderTryAgain.vue'
import ErrorCart from '../../cart/components/modals/ErrorCart.vue'
import MakeOrderCard from '../components/modals/MakeOrderCard.vue'
import { parseResponse } from '../../../common/helpers/responses/parser'
import { loadScript } from "@paypal/paypal-js";
export default {
    name: "Checkout" ,
    components: { GeneralInformation, CheckoutAddress, SecondNavTab, MainButton, CheckoutPayments, CheckoutConfirmation, AlertWarning, SecondButton, IconArrow, 
        OptionNotAvailable, 
    BankAccounts, CheckLoading, OrderConfirmed, IconBuy, MakeOrderTryAgain, MakeOrderCard, ErrorCart, InvoicedDifference, 
    CheckoutShippo
},
    data() {
        return {
            options: [
                {
                    id: 0,
                    value:'addressTab',
                    title: 'checkout.address',
                    icon: 'iconCheckoutAddress',
                    selected:  true
                },
                {
                    id: 1,
                    value:'paymentTab',
                    title: 'checkout.payment',
                    icon: 'IconDollar',
                    selected:  false

                },
                {
                    id: 2,
                    value:'confirmationTab',
                    title: 'checkout.confirmation',
                    icon: 'IconCheck',
                    selected:  false
                }
            ],
            selectedOption: {
                id: 0,
                value:'addressTab',
                title: 'checkout.address',
                icon: '',
            },
            showGeneralInformation: false,
            selectOne: false,
            showCarrier: false,
            payDifference: false,
            addressNotAvailable: 0,
            mutateProducts: null,
            paypal: {
                sandbox: null,
                production: null
            },
            optionNotAvailable: false,
            showBankAccounts: false,
            paymentTabRequired: false,
            changeRnc: false,
            loading: false,
            orderConfirmed: false,
            rate: 0,
            error: false,
            showAlertTryAgain: false,
            oldScrollValue: null,
            timeoutScroll: null,
            cardProblem: false,
            closeY: 0.75,
            order: {},
            lastMessage: null,
            errorModal: false,
            shaking: false,
            errorDescription: '',
            ignoreDiff: 0,
            showDiff: false,
            totalDifference: 0,
            productsError: []
        }
    },
    computed: {
        ...mapGetters({
            deliveryQuotationID: "checkout/deliveryQuotationID",
            addressTab: "checkout/addressTab",
            paymentTab: "checkout/paymentTab",
            cartProducts: 'cart/getProducts',
            confirmationTab: "checkout/confirmationTab",
            checkoutSteps: "checkout/checkoutSteps",
            difference: "checkout/difference",
            branchSelected: "branches/branchSelected",
            addressSelected: "account/addressSelected",
            orderConfirm: 'checkout/orderConfirm',
            user: "auth/userData",
            
            depositSelected: "checkout/depositSelected",
            cashSelected: "checkout/cashSelected",
            stripeSelected: "checkout/stripeSelected",
            creditNoteSelected: "checkout/creditNoteSelected",
            creditSelected: "checkout/creditSelected",
            depositExpressSelected: "checkout/depositExpressSelected",
            cardSelected: "checkout/cardSelected",
            paypalSelected:  "checkout/paypalSelected",
            carrierSelected: "account/carrierSelected",

            partnerSelected: 'checkout/partnerSelected',
            addressSelectedAvailable: "account/addressSelectedAvailable",

            singlePaymentActive: "checkout/singlePaymentActive",
            configurations:"common/configurations",

            amountSelected: "checkout/amountSelected",
            
            rnc: "checkout/rnc",
            
            selectedQuotation: "account/selectedQuotation",

            currency: "checkout/currency",
            note: "checkout/note",

            customer_name: "checkout/customer_name",
            elements: "checkout/elements",

            employeeActiveCode: 'auth/employeeActiveCode',

        }),
        amountUSD() {
            return parseFloat(this.orderConfirm?.total_order / this.currency?.taxe_amount ?? 0).toFixed(2)
        },
        showAlert(){
            let obj = {
                title: this.selectOne ? 'checkout.description_alert_message_select_one' : this.payDifference 
                ? 'checkout.please_pay_the_difference_before_finalizing_the_purchase' : this.paymentTabRequired 
                ? 'checkout.description_alert_payment_required' : '',
                show: (this.selectOne && this.checkoutSteps.addressOptionSelected == 0) || (this.payDifference && this.difference != 0) || this.paymentTabRequired
            }
            return obj
        }
    },
    watch: {
        'checkoutSteps.confirmationTab'(val){
            if(!val && !this.checkoutSteps.paymentTab){
                this.selectedOption.value = 'addressTab'
                this.selectedOption.id = 0
                this.options[1].selected = false
                this.options[2].selected = false
            }  else if(!val && this.checkoutSteps.paymentTab){
                this.selectedOption.value = 'paymentTab'
                this.selectedOption.id = 1
                this.options[1].selected = true
                this.options[2].selected = false
            }
        },
        async selectedOption(val, oldVal){
            this.selectOne = false 
            this.payDifference = false 
            this.paymentTabRequired = false
            if(this.checkoutSteps.addressOptionSelected === 0){
                this.selectOne = true
                return 
            }
            if(this.selectedOption.value === val.value){
                this.options[val.id] = {
                        id: val.id,
                        value: val.value,
                        title: val.title,
                        icon: val.icon,
                        selected:  true
                    }
            }
            if(val.id === 0){
                this.$store.commit("checkout/SET_ADDRESS_STEP", true)
                if(oldVal.id === 1 || oldVal.id === 2){
                    this.$store.commit("checkout/SET_CONFIRMATION_STEP", false)
                    this.$store.commit("checkout/SET_PAYMENT_STEP", false)
                    this.options[1].selected = false
                    this.options[2].selected = false
                }
            }else if(val.id === 1){ 
                this.$store.commit("checkout/SET_PAYMENT_STEP", true)
                if(oldVal.id === 2){
                    this.$store.commit("checkout/SET_CONFIRMATION_STEP", false)
                    this.options[2].selected = false
                }else{
                    if(!this.deliveryQuotationID)
                    await this.calculate()
                }
            }else if(val.id === 2 && parseFloat(this.difference) === 0){
                // let params = {
                //     amount: this.orderConfirm.total_order,
                // }
                setTimeout(() => {
                    if(this.isMobileDevice()){
                        this.paypalCheckout('paypal-button-container2');
                    }else {
                        this.paypalCheckout('paypal-button-container1');
                    }
                    // this.paypalCheckout()
                }, 1000);
                const deposit = this.depositSelected.filter(element => element.balanceSelected == '' || element.balanceSelected == 0);
                for (let i = 0; i < deposit.length; i++) {
                    const element = deposit[i];
                    this.$store.commit('checkout/SET_DEPOSIT', element) 
                }
                this.options[1].selected = true
                this.$store.commit("checkout/SET_PAYMENT_STEP", true)
                this.$store.commit("checkout/SET_CONFIRMATION_STEP", true)
            }
        },
    },
    methods:{
        async stripePayments(){
            try {
                const response = await this.$stripe.confirmPayment({
                    elements: this.elements,
                    confirmParams: {
                        // return_url: "http://localhost:8081/checkout/cart",
                    },
                    redirect: 'if_required' 
                });
                console.log("before response error")
                if(response.error){
                    console.log(response)
                    let errorMessage = `code: ${response.error.code}, message: ${response.error.message} `
                    if(response.error.decline_code){
                        errorMessage += ` decline_code: ${response.error.decline_code}`;
                    }
                    this.$store.commit('common/SHOW_MODAL_ERROR', {
                        show: true,
                        title: `checkout.an_error_occurred`,
                        description: errorMessage
                    });
                }

                // if(response.error){
                //     if(response?.error?.decline_code == 'insufficient_funds'){
                //     this.$store.commit('common/SHOW_MODAL_ERROR', {
                //         show: true,
                //         title: `checkout.an_error_occurred`,
                //         description: 'checkout.insufficient_funds'
                //     })
                //     } else if(response.error.code == 'processing_error') {
                //         this.$store.commit('common/SHOW_MODAL_ERROR', {
                //             show: true,
                //             title: `checkout.an_error_occurred`,
                //             description: 'checkout.try_again_later'
                //         })
                //     } if(response.error.decline_code == 'generic_decline' || response.error.decline_code == 'lost_card' || response.error.decline_code == 'stolen_card') {
                //         this.$store.commit('common/SHOW_MODAL_ERROR', {
                //             show: true,
                //             title: `checkout.an_error_occurred`,
                //             description: 'checkout.card_rejected'
                //         })
                //     } else {
                //         console.error(response, 'here');

                //         this.$store.commit('common/SHOW_MODAL_ERROR', {
                //             show: true,
                //             title: `checkout.an_error_occurred`,
                //             description: `${response.error.code}: ${response.error.message}`
                //         });
                //     }
                // }
                this.stripeSelected.paymentIntentId = response.paymentIntent.id
                return response.paymentIntent.id;
            } catch (error) {
                console.error(error);

                // this.$store.commit('common/SHOW_MODAL_ERROR', {
                //     show: true,
                //     title: `checkout.an_error_occurred`,
                //     description: error
                // });

                // if(error){
                //     this.$store.commit('common/SHOW_MODAL_ERROR', {
                //             show: true,
                //             title: `checkout.an_error_occurred`,
                //             description: 'checkout.try_again_later'
                //     })
                // }
            }
        },
        isMobileDevice() {
            return window.matchMedia("(max-width: 768px)").matches;
        },
        async preConfirm(){
            let paymentsFinal = {
                    // stripeSelected: this.stripeSelected.balance === 0 ? null : this.stripeSelected.data != null ? this.stripeSelected.data : {card_token_id: this.cardSelected.card.id, balance: this.cardSelected.balance, tax_amount: this.orderConfirm.total_order_taxes},
                    cashSelected: this.cashSelected.balance === 0 ? null : this.cashSelected,
                    expressDepositSelected: this.depositExpressSelected.balance === 0 && !this.depositExpressSelected.images.length? null : this.depositExpressSelected,
                    azulSelected: this.cardSelected.balance === 0 ? null : this.cardSelected.detailts != null ? this.cardSelected.detailts : {card_token_id: this.cardSelected.card.id, balance: this.cardSelected.balance, tax_amount: this.orderConfirm.total_order_taxes},
                    paypal: this.paypalSelected.balance === 0 ? null : this.paypalSelected,
                    creditSelected: this.creditSelected.balance === 0 ? null : this.creditSelected,
                    depositSelected: this.depositSelected.length === 0 ? null : this.depositSelected,
                    creditNoteSelected: this.creditNoteSelected.length === 0 ?  null : this.creditNoteSelected,
            }
            if(this.configurations.payment_methods.stripe_backend_implementation.active && this.cardSelected.card != null){
                paymentsFinal =  {
                    stripeSelected: this.cardSelected.balance === 0 ? null : this.cardSelected.detailts != null ? {payment_method_id: this.cardSelected.card.id, save_payment_method: 0, balance: this.cardSelected.balance} : {card_token_id: this.cardSelected.card.id, balance: this.cardSelected.balance}
                }
            }
            let products = this.$store.getters['cart/getProducts'];
                let productByPlaces = {};
                productByPlaces[this.branchSelected.codplace] = []
                for (let product of products){
                    productByPlaces[this.branchSelected.codplace].push(
                        {
                        codarticulo: product.product.codarticulo,
                        quantity: product.quantity,
                        product_id: product.product._id,
                        note: product.note
                    })
                }
            let params = {
                    payments: paymentsFinal,
                    only_confirm: 1,
                    products: productByPlaces,
                    option: this.checkoutSteps.addressOptionSelected,
                    partner: this.partnerSelected.id,
                    address: this.checkoutSteps.addressOptionSelected == 3 ? null : this.addressSelected?.id,
                    branch: this.branchSelected.codplace,
                    rnc: this.rnc,
                    quotation: this.selectedQuotation,
                    amount: this.orderConfirm.total_order,
                    ignoreDiff: this.ignoreDiff,
                    note: this.note,
                    customer_name: this.customer_name
                }
            const response = await orderService.createOrder(params)
            return response.status
        },
        /**
         * This method is used to finish the confirmation of a PaymentIntent with the requires_action status, for example a 3D Secure authentication.
         * It takes a client secret(returned by the backend), as a parameter and uses Stripe's handleNextAction method to handle the next action.
         * If the next action response contains an error, it commits an error to the Vuex store to show an error modal with the error message.
         * If the next action response indicates that the payment has succeeded, it sets the paymentIntentId of the stripeSelected object 
         * to the id of the payment intent returned by the handleNextAction method and then calls the makeOrder method.
         */
        async stripeHandleNextAction(clientSecret) {
            try {
                // Finish the confirmation of a PaymentIntent with the requires_action status
                // A modal with be shown to the user to authenticate the payment with 3D Secure
                const nextActionResponse = await this.$stripe.handleNextAction({
                    clientSecret: clientSecret
                });
    
                if (nextActionResponse.error) {
                    // something went wrong with the 3D Secure authentication
                    this.$store.commit('common/SHOW_MODAL_ERROR', {
                        show: true,
                        title: `checkout.an_error_occurred`,
                        description: nextActionResponse.error.message
                    });

                    return;
                }

                // The payment has been processed!
                if (nextActionResponse.paymentIntent.status === 'succeeded') {
                    this.stripeSelected.paymentIntentId = nextActionResponse.paymentIntent.id;
                    this.makeOrder();
                    return;
                }
            } catch (error) {
                console.error(error);
            }
        },
        async makeOrderWithPaypal(id){
            this.$store.commit("checkout/SET_PAYPAL_SELECTED", {
                id: id,
                currency_code: "USD",
                value: this.amountUSD,
                paid: this.orderConfirm.total_order,
                balance: this.orderConfirm.total_order
            });
            try {
                this.loading = true
                await this.createOrder();
            } catch (error) {
                console.log(error);
            }finally{
                this.loading = false
            }
        },
        async createOrderPaypal() {
            try {
                const dataPaypal = {
                        currency_code: "USD",
                        value: process.env.VUE_APP_VIDVIE != 1 ? (this.orderConfirm.total_order / this.currency.taxe_amount).toFixed(2) : this.orderConfirm.total_order ,
                    }
                const response = await http.post(`/paypal/create-order`, dataPaypal);
                let orderData = response.data.data
                if (orderData.id) {
                    return orderData.id;
                } else {
                    const errorDetail = orderData?.details?.[0];
                    const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                    : JSON.stringify(orderData);
                    
                    throw new Error(errorMessage);
                }

            } catch (error) {
                let e = parseResponse(error.response.data)
                if(e.data != []){
                    const warning = {
                        show: true,
                        title: 'Error',
                        description: 'PAYPAL:' + ' ' + e.data.name,
                    }
                    this.$store.commit('common/SHOW_MODAL_ERROR', warning)
                }else if(e.code == '85442080'){
                    const warning = {
                        show: true,
                        title: 'Error',
                        description: 'Sorry, your transaction could not be processed..',
                    }
                    this.$store.commit('common/SHOW_MODAL_ERROR', warning)
                }
            }
        },
        async onApprove(data, actions) {
            try {
                const response = await http.post(`/paypal/capture-order/${data.orderID}`);

                let orderData = await response.data.data;
                const errorDetail = orderData?.details?.[0];

                if (errorDetail?.issue == "INSTRUMENT_DECLINED") {
                    return actions.restart();
                } else if(errorDetail) {
                    throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
                } else if (!orderData.purchase_units) {
                    throw new Error(JSON.stringify(orderData));
                } else {
                    const transaction = orderData?.purchase_units?.[0]?.payments?.captures?.[0] || orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
                    console.log(`${transaction.status}: ${transaction.id} See console for all available details `);
                    this.makeOrderWithPaypal(data.orderID)
                }
            } catch (error) {
                let e  = parseResponse(error.response.data)
                if(e.data != []){
                    const warning = {
                        show: true,
                        title: 'Error',
                        description: 'PAYPAL:' + ' ' + e.data.name,
                    }
                    this.$store.commit('common/SHOW_MODAL_ERROR', warning)
                }else if(e.code == '85442080'){
                    const warning = {
                        show: true,
                        title: 'Error',
                        description: 'Sorry, your transaction could not be processed..',
                    }
                    this.$store.commit('common/SHOW_MODAL_ERROR', warning)
                }
            }
        },
        async paypalCheckout(containerId){
            loadScript({ 'client-id': this.paypal.sandbox, 'currency': 'USD', 'disable-funding': 'card' }).then((paypal) => {
                paypal.Buttons({
                    style: {
                        layout: 'vertical',
                        color:  'silver',
                        shape:  'rect',
                        label:  'checkout',
                        tagline: false
                    },
                    createOrder: this.createOrderPaypal,
                    onApprove: this.onApprove,
                }).render(`#${containerId}`);
            });
        },
        async stripeFinalPayments(){
            if(await this.preConfirm() == 'success'){
                    return await this.stripePayments()
            } else if(await this.preConfirm() == null) {                    
                this.$store.commit('common/SHOW_MODAL_ERROR', {
                    show: true,
                    title: `An error occurred`,
                    description: 'Try again later'
                })
            }
        },
        selectOptionTab(data, back){
            this.shaking = !this.shaking
            
            if(back) return true

            if(this.checkoutSteps.addressOptionSelected === 0){
                this.selectOne = true
                return false 
            }else if(data.id === 1 && this.paymentTab && this.difference == 0){
                this.selectedOption = data
                return true 
            }else if((data.id === 2 && this.difference > 0) || this.paymentTab && this.difference > 0 && data.id != 0){
                this.paymentTabRequired = true
                return false 
            }else if(this.checkoutSteps.addressOptionSelected === 2 ? false : !this.addressSelectedAvailable?.available && this.checkoutSteps.addressOptionSelected != 3){
                this.addressNotAvailable += 1
                return false 
            }else if(this.checkoutSteps.addressOptionSelected === 2){
                if(this.carrierSelected.object_id){
                    this.optionNotAvailable = false
                    this.selectedOption = data
                    return true
                } else {
                    let products = this.$store.getters['cart/getProducts'];
                    let productByPlaces = {};
                    productByPlaces[this.branchSelected.codplace] = []
                    for (let product of products){
                        if(this.checkoutSteps.addressOptionSelected === 2 && this.configurations.delivery_options.local_delivery.code == product.product.codarticulo){
                            // console.log(hola);
                        }else{
                            productByPlaces[this.branchSelected.codplace].push(
                                {
                                codarticulo: product.product.codarticulo,
                                quantity: product.quantity,
                                note: product.note
                            })
                        }
                    }
                    this.mutateProducts = productByPlaces

                    this.showCarrier = true
                    return false
                }
            }else{
                this.selectedOption = data
                return true
            }

        },
        async next(){
            const validation = this.selectOptionTab(this.selectedOption)
            if(!validation){
                return
            }
            if(this.selectedOption.id === 0){
                this.$store.commit("checkout/SET_PAYMENT_STEP", true)
                this.selectedOption = this.options[1]
            }else if(this.selectedOption.id === 1){
                this.$store.commit("checkout/SET_CONFIRMATION_STEP", true)
                this.selectedOption = this.options[2]
            }else if(this.selectedOption.id === 2){
                if(this.stripeSelected.select == true){
                    await this.stripeFinalPayments()
                    if(this.stripeSelected.paymentIntentId ){
                        this.makeOrder()
                    } else{ 
                        this.loading = false
                    }
                } else {
                    this.makeOrder()
                }
            }
            window.scrollTo(0,0)
        },
        back(){
            const validation = this.selectOptionTab(this.selectedOption, true)
            if(!validation){
                return
            }
            if(this.selectedOption.id === 1){
                this.$store.commit("checkout/SET_PAYMENT_STEP", false)
                this.selectedOption = this.options[0]
            }else if(this.selectedOption.id === 2){
                this.$store.commit("checkout/SET_CONFIRMATION_STEP", false)
                this.selectedOption = this.options[1]
            }
            window.scrollTo(0,0)

        },
        async makeOrder(){
            try {
                this.error = false
                this.loading = true
                if (parseFloat(this.difference)  === 0){
                    await this.createOrder()
                }
            }catch (e) {
                console.log(e);
                this.error = true
            }finally {
                this.loading = false
            }
        },
        async createOrder(){
            try{
                let paymentsFinal = {
                    // stripeSelected: this.stripeSelected.balance === 0 ? null : this.stripeSelected.data != null ? this.stripeSelected.data : {card_token_id: this.cardSelected.card.id, balance: this.cardSelected.balance, tax_amount: this.orderConfirm.total_order_taxes},
                    cashSelected: this.cashSelected.balance === 0 ? null : this.cashSelected,
                    expressDepositSelected: this.depositExpressSelected.balance === 0 && !this.depositExpressSelected.images.length? null : this.depositExpressSelected,
                    azulSelected: this.cardSelected.balance === 0 ? null : this.cardSelected.detailts != null ? this.cardSelected.detailts : {card_token_id: this.cardSelected.card.id, balance: this.cardSelected.balance},
                    paypal: this.paypalSelected.balance === 0 ? null : {order_id:this.paypalSelected.id, balance: this.paypalSelected.balance} ,
                    creditSelected: this.creditSelected.balance === 0 ? null : this.creditSelected,
                    depositSelected: this.depositSelected.length === 0 ? null : this.depositSelected,
                    creditNoteSelected: this.creditNoteSelected.length === 0 ?  null : this.creditNoteSelected,
                }
                if(this.configurations.payment_methods.stripe_backend_implementation.active && this.cardSelected.card != null){
                    // if the paymentIntent is not null, it means the payment was processed on the frontend
                    if (this.stripeSelected.paymentIntentId != null) {
                        paymentsFinal = {
                            stripeSelected: {
                                payment_intent_id: this.stripeSelected.paymentIntentId, 
                                balance: this.cardSelected.balance
                            }
                        }
                    } else {
                        paymentsFinal =  {
                            stripeSelected: this.cardSelected.balance === 0 ? null : this.cardSelected.detailts != null ? {payment_method_id: this.cardSelected.card.id, save_payment_method: 0, balance: this.cardSelected.balance} : {card_token_id: this.cardSelected.card.id, balance: this.cardSelected.balance}
                        }
                    }
                }
                let products = this.$store.getters['cart/getProducts'];
                let productByPlaces = {};
                productByPlaces[this.branchSelected.codplace] = []
                for (let product of products){
                    productByPlaces[this.branchSelected.codplace].push(
                        {
                        codarticulo: product.product.codarticulo,
                        quantity: product.quantity,
                        product_id: product.product._id,
                        note: product.note
                    })
                }
                let shipments = {
                    shippo: {
                            rate_id: this.carrierSelected?.object_id
                    }
                }
                let params = {
                    payments: paymentsFinal,
                    only_confirm: 0,
                    products: productByPlaces,
                    option: this.checkoutSteps.addressOptionSelected,
                    partner: this.partnerSelected.id,
                    address: this.checkoutSteps.addressOptionSelected == 3 ? null : this.addressSelected?.id,
                    branch: this.branchSelected.codplace,
                    rnc: this.rnc,
                    quotation: this.selectedQuotation,
                    amount: this.orderConfirm.total_order,
                    ignoreDiff: this.ignoreDiff,
                    note: this.note,
                    tax_amount: this.orderConfirm.total_order_taxes,
                    customer_name: this.customer_name,
                    shipments: shipments
                }
                const response = await orderService.createOrder(params)
                this.order = response.data
                await this.$store.dispatch('cart/clearCart');
                this.$store.commit('checkout/RESET_STATE');
                this.$store.commit('checkout/RESET_STRIPE_ELEMENTS');
                this.$store.commit('account/RESET_CARRIER');
                this.$store.commit("checkout/SET_DELIVERY_QUOTATION", null)
                this.orderConfirmed = true;
                this.$store.commit('account/SELECT_QUOTATION', null)
            }catch (e) {
                if(e?.data?.data?.total_amount_difference){
                    this.showDiff = true;
                    this.totalDifference = e.data.data.total_amount_difference
                    return
                } else if(e?.data.code == 562603){
                    this.productsError = e.data.data;
                    this.errorDescription = 'Lamentablemente la existencia de los siguientes productos ha cambiado, tendras que volver al carrito.';
                    this.showAlertTryAgain = true;
                    return
                } else if(e.data.code == 5699 && this.stripeSelected.select){
                    this.errorDescription = 'checkout.message_stripe';
                    this.showAlertTryAgain = true;
                    return
                } else if(e.data.code == 47170250){
                    this.errorDescription = 'checkout.message_stripe';
                    this.showAlertTryAgain = true;
                    return
                } else if(e.data.code == 14891281){
                    if(e.data.code == 14891281 && this.lastMessage == null){
                        this.lastMessage =  e.data.message
                    }
                    this.cardProblem = true
                    this.errorDescription = e.data.message
                    return
                } else if(e.data.code == 78222318){
                    if(e.data.code == 78222318 && this.lastMessage == null){
                        this.lastMessage =  e.data.message
                    }
                    this.cardProblem = true
                    this.errorDescription = this.lastMessage
                    return
                } else if (e.data.code == 553648) {
                    // 3D Secure authentication is required
                    // payment intent secret from the backend response, that will be used to handle the next action
                    const paymentIntentSecret = e.data.data.payment_intent_client_secret;
                    this.stripeHandleNextAction(paymentIntentSecret);
                    return;
                }
                this.showAlertTryAgain = true;
                this.errorDescription = e.data.message
            }
        },
        async reset(){
            this.$store.commit("checkout/RESET_STATE")
            this.$store.commit('account/RESET_CARRIER')
            this.$store.commit('checkout/RESET_STRIPE_ELEMENTS');
            this.$store.commit("checkout/SET_DELIVERY_QUOTATION", null)

            this.$router.push("/cart")
            if(this.selectedQuotation)
            await this.$store.dispatch('cart/clearCart');

            await this.$store.dispatch('cart/getCartByBranch', {codplace: this.branchSelected.codplace});

            this.$store.commit('account/SELECT_QUOTATION', null)
        },
        async calculate(){
            let deliveryOption = null;
            if(this.checkoutSteps.addressOptionSelected === 1){
                deliveryOption = 'local_delivery';
            }else if(this.checkoutSteps.addressOptionSelected === 2){
                deliveryOption = 'nacional_delivery';
            }else{
                deliveryOption = 'pick_up';
            }
            let products = this.$store.getters['cart/getProducts'];
            let productByPlaces = {};
            productByPlaces[this.branchSelected.codplace] = []
            for (let product of products){
                if(this.checkoutSteps.addressOptionSelected == 3 && this.configurations.delivery_options.local_delivery.code == product.product.codarticulo){
                    // console.log(hola);
                }else{
                    productByPlaces[this.branchSelected.codplace].push(
                        {
                        codarticulo: product.product.codarticulo,
                        quantity: product.quantity,
                        product_id: product._id,
                        note: product.note
                    })
                }
            }
            let shipments = {
                shippo: {
                        rate_id: this.carrierSelected?.object_id
                }
            }
            try {
                const response = await orderService.calculateDelivery(productByPlaces, deliveryOption, this.addressSelected.id, this.selectedQuotation, shipments);
                this.$store.commit("cart/SET_CART", response.data.data)
                this.$store.commit("cart/SET_PRODUCTS", response.data.data.products[this.branchSelected.codplace])
                this.$store.commit('checkout/SET_ORDER_CONRFIRM', response.data.data);
            } catch (error) {
                console.log(error);
                
            }
        },
        finalizeOrder(){
            // this.$router.push("/account/orders")
            setTimeout(() => {

                if(this.employeeActiveCode)
                this.$store.dispatch("auth/logoutCustomer");
                
                this.orderConfirmed = false
            }, 1000);
        },
        async payWithAzul(){
            try{
                // console.log( parseFloat(this.diference).formatMoney().replace(/[ ,.]/g,'').toString())
                // return
                console.log(this.orderConfirm);
                let params = {
                    MerchantId: process.env.VUE_APP_AZUL_MERCHANT_ID,
                    MerchantName: "Mundo Movil",
                    MerchantType:"Ecommerce",
                    CurrencyCode: "$",
                    OrderNumber: this.orderNumber,
                    Amount:  this.orderConfirm.total_order,
                    ITBIS:  this.orderConfirm.total_order_taxes,
                    ApprovedUrl: process.env.VUE_APP_PAYMENT_PATH+"?azul_status=approved",
                    DeclinedUrl: process.env.VUE_APP_PAYMENT_PATH+"?azul_status=declined",
                    CancelUrl: process.env.VUE_APP_PAYMENT_PATH+"?azul_status=cancel",
                    UseCustomField1: "0",
                    DatavaultToken: this.cardSelected.DataVaultToken,
                    CustomField1Label: "",
                    CustomField1Value: "",
                    UseCustomField2: "0",
                    CustomField2Label: "",
                    CustomField2Value:"",
                    AuthHash:"",
                    // SaveToDataVault: "1"
                    // SaveToDataVault: 1
                }
                    const data = await http.post("payments/hash/pay", params);
                    params.AuthHash = data.data.data;
                    let form = document.createElement('FORM');
                    form.method='POST';
                    form.action = process.env.VUE_APP_AZUL_URL+'/Default.aspx';
                    form.target = '_self'; // Specify the name of the window(second parameter to window.open method.)
                    for(let param in params){
                    let input = document.createElement("INPUT");
                    input.id = param;
                    input.name= param;
                    input.type="hidden";
                    input.value= params[param];
                    form.appendChild(input);
                    document.body.appendChild(form);
                }

                // console.log(form)
                form.submit();
            }catch (e){
                console.log(e)
            }
        },
        async ignoreDiffFunction(){
            this.ignoreDiff = 1;
            try {
                this.loading = true
                await this.createOrder();
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        }
    },
    async created(){
        try {
            this.$store.commit("common/SET_STATE_LOADING", true)
            await this.$store.dispatch('checkout/getPartners')
            await this.$store.dispatch('checkout/getCurrencyActive')
        } catch (error) {
            console.log(error);
        }finally{
            this.$store.commit("common/SET_STATE_LOADING", false)
        }

        this.$store.commit("checkout/RESET_STATE")
        this.$store.commit('account/RESET_CARRIER')
        this.$store.commit("checkout/RESET_STRIPE_ELEMENTS")

        if (this.selectedQuotation && this.cartProducts.length == 0) {
            try {
                const quotation = await this.$store.dispatch('account/getQuotationByID', {
                    id: this.selectedQuotation,
                    refresh: false
                })

                this.$store.commit("cart/SET_CART", {total_amount: quotation.amount, total_items_products: quotation.number_of_items})
                this.$store.dispatch('cart/setProducts', quotation.order_details)
            } catch (error) {
                console.error(error)
            }
        }
        this.paypal.sandbox = this.configurations.general.web_page.paypal_develop_var
        this.paypal.production = this.configurations.general.web_page.paypal_production_var
    },
    beforeDestroy(){
        localStorage.removeItem('orderDetail')
        localStorage.removeItem('orderConfirm')
    }
}
</script>

<style scoped>
    @media (max-width: 991px) {
        .general-information-desktop{
            display: none;
        }
    }
    #general .card-container{
            cursor: pointer;
            max-width: none;
            box-sizing: border-box;
            position: relative;
            padding: 0;
            border-radius: 20px;
            background: white;
            box-shadow: none;
            transition: var(--transition-1);
            border: none;
            
    }
    #general .card-links{
        max-width: none;
    }
    .card-links{
        background: var(--light-gray-100);
        padding: 1.3rem 1.5rem;
        border-radius: 7px;
        /* max-width: 270px; */
        margin: 0 auto;
    }
    .iconRotate {
        transform: rotate(270deg) !important;
    }
    .iconArrow {
    transform: rotate(90deg);
    transition: var(--transition-1);
    }
    .card-links a, a{
        font-size: 14px;
        margin-top: 5px;
    }
    .contaner-general{
        position: sticky;
        top: 5%;
    }

    .main-link-container > span{
        transition: var(--transition-1) !important;
    }
    .main-link-container:hover > span{
        color: var(--main-red) !important;
    }
    .main-link-container > svg {
        transition: all 0.05s ease-in-out !important;
    }
    .main-link-container:hover > svg {
        fill: var(--main-red) !important;
    }
    .disabled.main-button::v-deep{
        background: var(--gray-1001) !important;
        cursor: no-drop;
    }
    .bottom-sheet{
        position: sticky;
        bottom: 0px;
        background: #ffffff;
        z-index: 2;
        padding: 10px;
        box-shadow: 0 -5px 11px -5px #212e3d1c;
        width: 100%;
        /*height: 60px;*/
        border-radius: 20px 20px 0px 0px;
    }

    @media (max-width:476px) {
        .container-checkout{
            min-height: 92vh;
        }
        .wrapper-nav-tab{
            position: sticky;
            top: 0;
            z-index: 2;
            background-color: #fff;
            padding: 5px 0px;
        }
    }
    .general-information-desktop{
        z-index: 3;
    }
    .position-button-paypal{
        position: relative;
        right: 50px;
    }
</style>
