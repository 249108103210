<template>
    <div class="px-md-4">
        <div class="d-flex justify-content-between">
            <div>
                <h3 class="m-0 title-20 weight-400">{{$t("checkout.payment_methods")}}</h3>
                <span class="m-0 mt-3 subtitle-16 color-dark-gray-300">{{$t("checkout.confirm_or_change_your_payment_methods")}}</span>
            </div>
            <div class="main-link edit-btn d-flex align-items-center ms-1" @click="editPayments">
                <icon-edit :color="'var(--blue-600)'" class="me-2" :size="15"/>
                <span>{{$t("checkout.edit")}}</span>
            </div>
        </div>
        <ul class="payments-list mt-4">
            <li v-if="cashSelected.balance > 0">
                <div class="d-flex align-items-center justify-content-between">
                    <span>
                        <icon-confirmation-cash :size="50" color="var(--green-600)" class="me-5"/>
                        {{$t("checkout.cash")}}
                    </span>
                    <div class="d-flex flex-column flex-sm-row">
                        <span class="color-dark-gray-300 pe-2">{{$t("checkout.total_to_pay")}}: </span>
                        <b class="weight-500 color-dark-main-navy subtitle-15">{{cashSelected.balance | moneyFormat}}</b>
                    </div>
                </div>
            </li>
            <!-- <li v-if="stripeSelected.balance > 0">
                <div class="d-flex align-items-center justify-content-between">
                    <span>
                        <icon-card :size="50" color="var(--green-600)" class="me-5"/>
                        {{$t("checkout.stripe_payment")}}
                    </span>
                    <div class="d-flex flex-column flex-sm-row">
                        <span class="color-dark-gray-300 pe-2">{{$t("checkout.total_to_pay")}}: </span>
                        <b class="weight-500 color-dark-main-navy subtitle-15">{{stripeSelected.balance | moneyFormat}}</b>
                    </div>
                </div>
            </li> -->
            <li v-if="paypalSelected.balance > 0">
                <div class="d-flex align-items-center justify-content-between">
                    <span>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/a/a4/Paypal_2014_logo.png" alt="paypal" width="50" class="me-5">
                        {{$t("checkout.paypal")}}
                    </span>
                    <div>
                        <span class="color-dark-gray-300">{{$t("checkout.total_to_pay")}}: 
                            <b class="weight-500 color-dark-main-navy">{{paypalSelected.balance | moneyFormat}}</b>
                        </span>
                    </div>
                </div>
            </li>
            <li v-if="cardSelected.balance > 0">
                <div v-if="getCard" class="row align-items-center justify-content-between">
                    <div class="col-md-6 d-flex align-items-center">
                        <icon-visa :size="70" v-if="getCard.card_brand.toLowerCase() === 'visa'"/>
                        <icon-mastercard v-if="getCard.card_brand.toLowerCase() === 'mastercard'" :size="70"/>
                        <icon-discover v-else-if="getCard.card_brand.toLowerCase() == 'discover'" :size="70" class="mt-3" />
                        <icon-troy v-else-if="getCard.card_brand.toLowerCase() == 'troy'" :size="70" />
                        <icon-union-pay v-else-if="getCard.card_brand.toLowerCase() == 'unionpay'" :size="70" /> 
                        <icon-diners-club v-else-if="getCard.card_brand.toLowerCase() == 'dinersclub'" :size="58"/>
                        <icon-j-c-b v-else-if="getCard.card_brand.toLowerCase() == 'jcb'" :size="70" />
                        <icon-american-express v-else-if="getCard.card_brand.toLowerCase() == 'amex'" :size="100" />
                        
                        <div class="d-flex flex-column ms-3">
                            <div class="subtitle-14 d-flex">
                                <span class="color-dark-gray-300 me-2">{{getCard.card_brand}}</span>
                                <span> **** {{getCard.card_last_four_digits}}</span>
                                <span class="ms-2 me-2 font-weight-light color-dark-gray-200"> | </span>
                                <div class="d-flex align-items-center" v-if="getCard.is_default">
                                    <icon-star :size="15" class="me-2"/>
                                    <span class="subtitle-14">{{$t("checkout.default")}}</span>
                                </div>
                            </div>
                            <span class="subtitle-14 weight-500">{{$t("checkout.expiration_date")}} {{fMonth(getCard.card_expiry_month)}} / {{getCard.card_expiry_year}}</span>
                            <span v-if="getCard.last_used != null" class="subtitle-14 color-dark-gray-200 weight-300">{{$t("checkout.last_time_used")}}: <b>{{ fDate(getCard.last_used) }}</b></span>
                        </div>
                    </div>
                    <div class="col-md-6 d-flex justify-content-end">
                        <span class="color-dark-gray-300">{{$t("checkout.total_to_pay")}}: 
                            <b class="weight-500 color-dark-main-navy">{{cardSelected.balance | moneyFormat}}</b>
                        </span>
                    </div>
                </div>
                <div v-else class="row align-items-center justify-content-between">
                    <div class="col-md-6 d-flex align-items-center">
                        <template v-if="cardSelected.detailts.card_brand">
                            <icon-visa :size="70" v-if="cardSelected.detailts.card_brand.toLowerCase() === 'visa'"/>
                            <icon-mastercard v-if="cardSelected.detailts.card_brand.toLowerCase() === 'mastercard'" :size="70"/>
                            <icon-discover v-else-if="cardSelected.detailts.card_brand.toLowerCase() == 'discover'" :size="70" class="mt-3" />
                            <icon-troy v-else-if="cardSelected.detailts.card_brand.toLowerCase() == 'troy'" :size="70" />
                            <icon-union-pay v-else-if="cardSelected.detailts.card_brand.toLowerCase() == 'unionpay'" :size="70" /> 
                            <icon-diners-club v-else-if="cardSelected.detailts.card_brand.toLowerCase() == 'dinersclub'" :size="58"/>
                            <icon-j-c-b v-else-if="cardSelected.detailts.card_brand.toLowerCase() == 'jcb'" :size="70" />
                            <icon-american-express v-else-if="cardSelected.detailts.card_brand.toLowerCase() == 'amex'" :size="100" />
                        </template>
                        <icon-card v-else :size="60" color="var(--green-600)" class="me-5"/>
                    
                        <div class="d-flex flex-column ms-3">
                            <div class="subtitle-14 d-flex">
                                <span class="color-dark-gray-300 me-2">{{cardSelected.detailts.card_brand}}</span>
                                <span> **** {{cardSelected.detailts.card_number.slice(-4)}}</span>
                            </div>
                            <span class="subtitle-14 weight-500">{{$t("checkout.expiration_date")}} {{fMonth(cardSelected.detailts.card_expiry_month)}} / {{cardSelected.detailts.card_expiry_year}}</span>
                            <!-- <span class="subtitle-14 color-dark-gray-200 weight-300">{{$t("checkout.last_time_used")}}: Martes, 10 Oct 2021</span> -->
                        </div>
                    </div>
                    <div class="col-md-6 d-flex justify-content-end">
                        <span class="color-dark-gray-300">{{$t("checkout.total_to_pay")}}: 
                            <b class="weight-500 color-dark-main-navy">{{cardSelected.balance | moneyFormat}}</b>
                        </span>
                    </div>
                </div>
            </li>
            <li v-if="creditSelected.balance > 0">
                <div class="d-flex align-items-center justify-content-between">
                    <span>
                        <icon-only-credit :size="70"/>
                        {{$t("checkout.credit_payment")}}
                    </span>
                    <div>
                        <span class="color-dark-gray-300">{{$t("checkout.total_to_pay")}}: 
                            <b class="weight-500 color-dark-main-navy">{{creditSelected.balance | moneyFormat}}</b>
                        </span>
                    </div>
                </div>
            </li>
            <li v-if="depositExpressSelected.balance > 0 && depositExpressSelected.images.length">
                <div class="d-flex align-items-center justify-content-between">
                    <span>
                        <icon-deposit-express :size="50" class="me-5"/>
                        {{$t("checkout.bank_deposit")}}
                    </span>
                    <div>
                        <span class="color-dark-gray-300">{{$t("checkout.total_to_pay")}}: 
                            <b class="weight-500 color-dark-main-navy">{{depositExpressSelected.balance | moneyFormat}}</b>
                        </span>
                    </div>
                </div>
            </li>
            <li v-if="depositSelected.length">
                <div class="d-flex align-items-center justify-content-between">
                    <span>
                        <icon-deposit :size="50" class="me-5"/>
                        {{$t("checkout.bank_deposit")}}
                    </span>
                    <div>
                        <span class="color-dark-gray-300">{{$t("checkout.total_to_pay")}}: 
                            <b class="weight-500 color-dark-main-navy">{{amountDeposit | moneyFormat}}</b>
                        </span>
                    </div>
                </div>
            </li>
            <li v-if="creditNoteSelected.length">
                <div class="d-flex align-items-center justify-content-between">
                    <span>
                        <icon-note-credit :size="50" class="me-5"/>
                        {{$t("checkout.credit_note")}}
                    </span>
                    <div>
                        <span class="color-dark-gray-300">{{$t("checkout.total_to_pay")}}: 
                            <b class="weight-500 color-dark-main-navy">{{amountCredit | moneyFormat}}</b>
                        </span>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import IconStar from '../../../../common/svg/iconStar.vue'
import IconConfirmationCash from '../../assets/svg/iconConfirmationCash.vue'
import IconDeposit from '../../assets/svg/iconDeposit.vue'
import IconDepositExpress from '../../assets/svg/iconDepositExpress.vue'
import IconEdit from '../../assets/svg/iconEdit.vue'
import IconMastercard from '../../assets/svg/iconMastercard.vue'
import IconNoteCredit from '../../assets/svg/iconNoteCredit.vue'
import IconOnlyCredit from '../../assets/svg/iconOnlyCredit.vue'
import IconVisa from '../../assets/svg/iconVisa.vue'
import iconCard from "../../../../common/svg/iconCard.vue"
import iconAmericanExpress from "../../assets/svg/iconAmericanExpress.vue"
import iconDinersClub from "../../assets/svg/iconDinersClub.vue"
import iconJCB from "../../assets/svg/iconJCB.vue"
import iconUnionPay from "../../assets/svg/iconUnionPay.vue"
import iconTroy from "../../assets/svg/iconTroy.vue"
import iconDiscover from "../../assets/svg/iconDiscover.vue"

export default {
    components: {IconConfirmationCash, IconEdit, IconVisa, IconMastercard, IconDepositExpress, IconOnlyCredit, IconDeposit, IconStar, IconNoteCredit, iconCard, iconAmericanExpress, iconDinersClub, iconJCB, iconUnionPay, iconTroy, iconDiscover},
    name: "PaymentMethods",
    computed:{
        ...mapGetters({
            cardSelected: "checkout/cardSelected",
            paypalSelected:  "checkout/paypalSelected",
            stripeSelected: "checkout/stripeSelected",
            cashSelected: "checkout/cashSelected",
            creditSelected: "checkout/creditSelected",
            depositSelected: "checkout/depositSelected",
            depositExpressSelected: "checkout/depositExpressSelected",
            creditNoteSelected: "checkout/creditNoteSelected",
            cards: "account/cards"
        }),
        amountDeposit(){
            if(this.depositSelected.length >= 1){
                let total = 0
                for (let i = 0; i < this.depositSelected.length; i++) {
                    total += parseFloat(this.depositSelected[i].balanceSelected) 
                }
                return total
            }
            return 0
        },
        amountCredit(){
            if(this.creditNoteSelected.length >= 1){
                let total = 0
                for (let i = 0; i < this.creditNoteSelected.length; i++) {
                    total += parseFloat(this.creditNoteSelected[i].balanceSelected) 
                }
                return total
            }
            return 0
        },
        getCard(){
            let filterCard = this.cards.filter((card)=>{
                return card.id == this.cardSelected.card.id
            })
            return filterCard[0]
        }      
    },
    methods:{
        fDate(fechaISO) {
            const date = new Date(fechaISO);
            const daysWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

            const dayWeek = daysWeek[date.getUTCDay()];
            const month = months[date.getUTCMonth()];
            const year = date.getUTCFullYear();

            return `${dayWeek}, ${date.getUTCDate()} ${month} ${year}`;
        },
        fMonth(month) {
            return month < 10 ? '0' + month.toString() : month; 
        },
        editPayments(){
            this.$store.commit("checkout/SET_CONFIRMATION_STEP", false)
        }
    }
}
</script>

<style scoped>
    .payments-list{
        list-style: none;
        padding: 0;
    }
    .payments-list > li{
        margin: 40px 0px;
        padding-bottom: 40px;
        border-bottom: 1px solid var(--gray-100);
    }
    .payments-list > li > div > span{
        display: flex;
        align-items: center;
    }
    ul > li:last-child{
        padding: 0 !important;
        border: none;
    }
    .edit-btn > svg {
        transition: all .05s ease-in-out
    }
    .edit-btn:hover > svg {
        fill: var(--main-red) !important;
    }
    .edit-btn:hover span {
        color: var(--main-red) !important;
    }
    .edit-btn span{
        transition: var(--transition-1);
        color: var(--blue-600);
    }
</style>
