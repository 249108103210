<template>
    <div>
        <div class="col-sm-12 mb-4">
            <h3 class="mt-2 mt-sm-0 mb-0 color-blue-700 title-24 weight-700">{{$t("checkout.order_details")}}</h3>
            <span>{{$t("checkout.description_order_details")}}.</span>
            <div class="mt-4">
                <to-delivery class="mb-5"/>
                <hr class="m-0 bg-dark-gray-200 opacity-normal">
                <payment-methods class="mt-5 mb-5"/>
                <hr class="m-0 bg-dark-gray-200 opacity-normal">
                <checkout-product-details class="mt-4 mt-md-5"/>
            </div>
            <div class="row mt-4 px-md-4">
                <div class="col-md-6">
                    <tax-receipt-information :rnc="rnc" @change="$emit('changeRnc')" v-if="rnc" style="max-width: 265px;" :confirmation="true"/>
                </div>
                <div class="col-md-6">
                    <ul class="list-unstyled p-0 ms-auto" style="max-width: 220px;">
                        <li class="d-flex justify-content-between subtitle-14 mt-2 color-dark-gray-300">{{$t("account.subtotal")}} <span>{{orderConfirm.total_order_without_taxes | moneyFormat}}</span> </li>
                        <li class="d-flex justify-content-between subtitle-14 mt-1 color-dark-gray-300">{{$t("account.tax")}} <span>{{orderConfirm.total_order_taxes | moneyFormat}}</span></li>
                        <li class="d-flex justify-content-between title-15 mt-4 mb-0">{{$t("account.total")}}<span class="color-main-red title-14 mb-0">{{orderConfirm.total_order | moneyFormat}}</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import ToDelivery from './confirmation/toDelivery'
import PaymentMethods from './confirmation/PaymentMethods.vue'
import CheckoutProductDetails from './confirmation/CheckoutProductDetails.vue'
import { mapGetters } from "vuex"
import TaxReceiptInformation from './TaxReceiptInformation.vue'
import ToDelivery from './confirmation/toDelivery.vue'

export default {
    components: { PaymentMethods, CheckoutProductDetails, TaxReceiptInformation, ToDelivery },
    name: "CheckoutConfirmation",
    computed:{
        ...mapGetters({
            orderConfirm: "checkout/orderConfirm",
            addressSelectedAvailable: "account/addressSelectedAvailable",
            rnc: 'checkout/rnc',
            checkoutSteps: "checkout/checkoutSteps",
            cart: "cart/cart"

        })
    }
}
</script>
