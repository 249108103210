<template>
    <ul class="list-card p-0 m-0 mb-4 mt-4 col-12" :class="[cards.length > 4 ? 'pe-md-2 pe-0' : 'pe-0']">
        <li :key="card.id" v-for="card in cards" :class="{ selectOption: newCardSelect ? card.id === newCardSelect.id : false}">
            <div class="row">
                <div class="col-md-7 d-flex align-items-center" @click="setCard(card)">
                    <radio-button class="me-3" :checked="newCardSelect ? card.id === newCardSelect.id : false"/>
                    <icon-visa :size="70" v-if="card.card_brand.toLowerCase() == 'visa'" class="card-logo-img"/>
                    <icon-mastercard v-if="card.card_brand.toLowerCase() == 'mastercard'" :size="70" class="card-logo-img"/>
                    <icon-diners-club v-if="card.card_brand.toLowerCase() == 'dinersclub'" :size="70" class="card-logo-img"/>
                    <icon-troy v-else-if="card.card_brand.toLowerCase() == 'troy'" :size="70" class="card-logo-img"/>
                    <icon-union-pay v-else-if="card.card_brand.toLowerCase() == 'unionpay'" :size="70" class="card-logo-img"/> 
                    <icon-j-c-b v-else-if="card.card_brand.toLowerCase() == 'jcb'" :size="70" class="card-logo-img"/>
                    <icon-american-express v-else-if="card.card_brand.toLowerCase() == 'amex'" :size="100" class="card-logo-img"/>
                    <icon-discover v-else-if="card.card_brand.toLowerCase() == 'discover'" :size="70" class="card-logo-img mt-3"/>
                    <div class="d-flex flex-column ms-3">
                        <div class="subtitle-14">
                            <span class="color-dark-gray-400">{{card.card_brand | textFormat}}</span>
                            <span> **** {{card.card_last_four_digits}}</span>
                            <span class="ml-2 mr-2 font-weight-light"> | </span>
                            <span class="weight-500">{{$t("checkout.expiration_date")}} {{fMonth(card.card_expiry_month)}} / {{card.card_expiry_year}}</span>
                        </div>
                        <span v-if="card.last_used != null" class="subtitle-14 color-dark-gray-200 weight-300">{{$t("checkout.last_time_used")}}: <b>{{fDate(card.last_used)}}</b></span>
                    </div>
                </div>
                <div class="col-md-5 d-flex flex-column align-items-end justify-content-center">
                    <div class="d-flex align-items-center" v-if="card.is_default">
                        <icon-star :size="15" class="me-2"/>
                        <span class="subtitle-14">{{$t("checkout.default")}}</span>
                    </div>
                    <div class="main-link">
                        <!-- <a href="#" @click.prevent="editCard(card)" class="size-14">{{$t("checkout.edit")}}</a> -->
                        <span class="me-2 ms-2" style="opacity: 0.6; font-weight: 300"> </span>
                        <a href="#" @click.prevent="showDelete = true; deleteId = card.id;" class="size-14">{{$t("checkout.remove")}}</a>
                        <span class="me-2 ms-2" style="opacity: 0.6; font-weight: 300" v-if="!card.is_default"> | </span>
                        <a href="#" @click.prevent="defaultCard(card)" v-if="!card.is_default" class="size-14">{{$t("checkout.default")}}</a>
                    </div>
                </div>
            </div>
        </li>
        <confirm-delete-card :loading="loading" v-if="showDelete" @confirmDelete="deleteCard" @closeModal="showDelete = false;"/>
        <single-payment v-if="showSinglePayment" @closeModal="showSinglePayment = false" @confirm="confirm" :title="'checkout.single_payment'" :description="'checkout.this_payment_method_is_unique'"/>
    </ul>
</template>


<script>
import { mapGetters } from "vuex"
import RadioButton from '../../../../../common/components/radio/RadioButton.vue'
import IconStar from '../../../../../common/svg/iconStar.vue'
import IconMastercard from '../../../assets/svg/iconMastercard.vue'
import IconVisa from '../../../assets/svg/iconVisa.vue'
import SinglePayment from '../../modals/SinglePayment.vue'
import { http } from "@/common/services/web-backend.js";
import ConfirmDeleteCard from '../../modals/ConfirmDeleteCard.vue'
import iconDinersClub from '../../../assets/svg/iconDinersClub.vue'
import iconTroy from "../../../assets/svg/iconTroy.vue"
import iconAmericanExpress from "../../../assets/svg/iconAmericanExpress.vue"
import iconJCB from "../../../assets/svg/iconJCB.vue"
import iconDiscover from "../../../assets/svg/iconDiscover.vue"
import iconUnionPay from "../../../assets/svg/iconUnionPay.vue"

export default {
    components: { RadioButton, IconVisa, IconMastercard, IconStar, SinglePayment, ConfirmDeleteCard , iconDinersClub, iconTroy, iconAmericanExpress, iconJCB, iconDiscover, iconUnionPay},
    name: "ListCards",
    props:{
        cards:{
            type: Array
        }
    },
    computed:{
        ...mapGetters({
            cardSelected: "checkout/cardSelected",
            normalPayments: "checkout/normalPayments",
            singlePaymentActive: "checkout/singlePaymentActive",
            paypalSelected:  "checkout/paypalSelected",
            creditSelected: "checkout/creditSelected",
            orderConfirm: "checkout/orderConfirm",
            configurations: "common/configurations"
        }),
    },
    data() {
        return {
            newCardSelect:null,
            showSinglePayment: false,
            card: {},
            showDelete: false,
            deleteId: null,
            loading: false,
        }
    },
    watch:{
    async cardSelected(val){
        if(val.balance === 0){
            this.newCardSelect = null
        }
    },        
    normalPayments(val){
            if(val){
                this.newCardSelect = null
            }
        }
    },
    methods:{
        fDate(fechaISO) {
            const date = new Date(fechaISO);
            const daysWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

            const dayWeek = daysWeek[date.getUTCDay()];
            const month = months[date.getUTCMonth()];
            const year = date.getUTCFullYear();

            return `${dayWeek}, ${date.getUTCDate()} ${month} ${year}`;
        },
        fMonth(month) {
            return month < 10 ? '0' + month.toString() : month; 
        },
        setCard(card){
            if(this.normalPayments || this.paypalSelected.balance > 0 || this.creditSelected.balance > 0){
                this.card = card
                this.showSinglePayment = true
                return
            }
            this.$store.commit("checkout/RESET_PAYMENTS")
            if(this.cardSelected.card != null && card.id === this.cardSelected.card.id){
                this.$store.commit('checkout/SET_CARD_SELECTED', {id: this.cardSelected.card.id})
                this.$store.commit("checkout/SET_CARD_BALANCE", 0)
                this.newCardSelect = null
            }else{
                this.newCardSelect =  card
                this.$store.commit('checkout/SET_CARD_SELECTED', {id: card.id})
                this.$store.commit("checkout/SET_CARD_BALANCE", this.orderConfirm.total_order)
            }
        },
        async deleteCard(){
            try {
                this.loading = true
                if(this.configurations.payment_methods.stripe_backend_implementation.active){
                    await http.delete(`stripe/delete-payment-method?card_token_id=`+this.deleteId);
                }else { 
                    await http.delete(`azul/process-data-vault-delete?card_token_id=`+this.deleteId);
                }
                this.showDelete = false
                this.$store.dispatch('account/getCards')
            } catch (error) {
                console.log(error);
            }finally {
                this.loading = false
            }
        },
        async defaultCard(code){
            try {
                const id = {card_token_id: code.id};
                if(this.configurations.payment_methods.stripe_backend_implementation.active){
                    await http.post(`stripe/set-default-payment-method`, id);
                } else {
                    await http.post(`azul/set-default-token`, id);
                }
                this.showDelete = false
                this.$store.dispatch('account/getCards')
            } catch (error) {
                console.log(error);
            }
        },
        editCard(card){
            this.$emit("editCard", card)
        },
        confirm(){
            this.$store.commit("checkout/RESET_PAYMENTS")
            this.setCard(this.card)
            this.showSinglePayment = false
        },
    },
}
</script>

<style scoped>
    .list-card{
        list-style: none;
        overflow: auto;
        max-height: 400px;
    }
    .list-card li{
        border-top: 1px solid var(--light-gray-501);
        padding: 3%;
        cursor: pointer;
    }
    .card-logo-img{
        border: 1px solid #dee5e7;
        border-radius: 5px;
        padding: 0px; 

        width: 91px; 
        height: 59px;
    }
    .selectOption {
        background: var(--light-gray-50);
    }
</style>
