<template>
    <main-modal :title="'checkout.tax_receipt'" @closeModal="$emit('closeModalTaxReceipt')" class="main-confirm">
        <div class="d-flex mb-2 mt-2">
            <icon-search class="me-2" :size="15"/>
            <h4 class="title-16 mb-0">{{$t("checkout.taxpayer_search")}}</h4>
        </div>
        <input-search :placeholder="'checkout.enter_your_rnc_or_id'" :mask="'###-#######-#'" @keyupEnter="validateRnc" @search="validateRnc"
        @changeValue="(data)=>{rnc = data}" class="taxpayer-input" :loading="loading"/>
        <alert-warning class="mt-4 p-0" :size="20" :show="showAlert" :showCounter="false" title="checkout.no_recorded_data_found"/>
        <div class="mt-4 animation-slide-down" v-if="dataCompany">
            <h6 class="title-15 weight-500 mb-3">{{$t("checkout.search_result")}}</h6>
            <table class="table m-0">
            <thead style="border-bottom: 2px solid #dee2e6;border-top: 1px solid #dee2e6;">
                <tr>
                    <th class="title-14 mb-0 p-1 text-center">{{$t("checkout.rnc_or_id")}}</th>
                    <th class="title-14 mb-0 p-1 text-center">{{$t("checkout.name_company_name")}} </th>
                    <th class="title-14 mb-0 p-1 text-center">{{$t("checkout.tradename")}}</th>
                    <th class="title-14 mb-0 p-1 text-center">{{$t("checkout.state")}}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="subtitle-13 color-dark-gray-300">{{dataCompany["Cédula/RNC"]}}</td>
                    <td class="subtitle-13 color-dark-gray-300">{{dataCompany["Nombre/Razón Social"]}}</td>
                    <td class="subtitle-13 color-dark-gray-300">{{dataCompany["Nombre Comercial"]}}</td>
                    <td class="subtitle-13 color-dark-gray-300">{{dataCompany["Estado"] | textFormat}}</td>
                </tr>
            </tbody>
            </table>
        </div>
        <div class="d-flex justify-content-end mt-4" v-if="dataCompany != null">
            <main-button :id="'set-rnc'" :title="'checkout.Apply'" @click="apply"/>
        </div>
    </main-modal>
</template>

<script>
import InputSearch from '../../../../common/components/inputs/InputSearch.vue'
import MainModal from '../../../../common/components/modals/MainModal.vue'
import IconSearch from '../../../../common/svg/iconSearch.vue'
import { http } from "../../../../common/services/web-backend";
import AlertWarning from '../../../../common/components/alerts/AlertWarning.vue';
import MainButton from '../../../../common/components/buttons/MainButton.vue';
export default {
    name: "TaxReceipt",
    components:{MainModal, IconSearch, InputSearch, AlertWarning, MainButton},
    data() {
        return {
            dataCompany: null,
            rnc: '',
            showAlert: false,
            loading: false
        }
    },
    methods:{
        async validateRnc() {
            try {
                this.loading = true
                this.showAlert = false
                const res = await http.post('rnc/validate', {rnc: this.rnc.replace(/[^\d]/g, "")})
                if(res.data.data.length === 0){
                    this.showAlert = true
                    return
                }
                this.dataCompany = res.data.data;
            } catch (error) {
                console.log(error)
            }finally{
                this.loading = false
            }
        },
        apply(){
            this.$store.commit('checkout/SET_RNC', this.dataCompany); 
            this.$emit("closeModalTaxReceipt")
        }
    }
}
</script>

<style scoped>
    @media (min-width: 992px) {
        .taxpayer-input{
            min-width: 500px;
        }
    }
</style>