<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
            <g>
                <defs>
                    <path id="SVGID_23_" d="M201.2,196.2H47.1V72.9h154.1V196.2z M48.2,195.1h151.9V73.9H48.2V195.1z"/>
                </defs>
                <use xlink:href="#SVGID_23_"  style="overflow:visible;fill:#AF3B3B;"/>
                <clipPath>
                    <use xlink:href="#SVGID_23_"  style="overflow:visible;"/>
                </clipPath>
                
                    <rect x="48.2" y="73.9" fill="#fff" width="151.9" height="121.1"/>
                <use xlink:href="#SVGID_23_"  style="overflow:visible;fill:none;stroke:#AF3B3B;stroke-width:2.2;stroke-miterlimit:10;"/>
            </g>
            <g>
                <defs>
                    <polygon id="XMLID_10_" points="61,46.1 35.5,88 214.5,88 192.3,46.1 			"/>
                </defs>
                <use xlink:href="#XMLID_10_"  style="overflow:visible;fill:#D23534;"/>
                <clipPath id="XMLID_21_">
                    <use xlink:href="#XMLID_10_"  style="overflow:visible;"/>
                </clipPath>
                <polyline style="clip-path:url(#XMLID_21_);fill:#FFFFFF;" points="60.3,88 83,46.1 102.8,46.1 86.3,88 60.3,88 		"/>
                <polygon style="clip-path:url(#XMLID_21_);fill:#FFFFFF;" points="163.3,88 153.9,46.1 172.8,46.1 188.8,88 		"/>
                <polygon style="clip-path:url(#XMLID_21_);fill:#FFFFFF;" points="112.4,88 120.3,46.1 136.4,46.1 136.8,88 		"/>
            </g>
            <g>
                <path class="st35" d="M60.3,88c0,7.6-5.6,13.8-12.4,13.8S35.5,95.6,35.5,88H60.3z"/>
                <path class="st36" d="M86.3,88c0,7.6-5.8,13.8-13,13.8c-7.2,0-13-6.2-13-13.8H86.3z"/>
                <path class="st35" d="M112.4,88c0,7.6-5.8,13.8-13.1,13.8S86.3,95.6,86.3,88H112.4z"/>
                <path class="st35" d="M163.3,88c0,7.6-5.9,13.8-13.2,13.8s-13.2-6.2-13.2-13.8H163.3z"/>
                <path class="st35" d="M214.5,88c0,7.6-5.7,13.8-12.8,13.8c-7.1,0-12.8-6.2-12.8-13.8H214.5z"/>
                <path class="st36" d="M136.9,88c0,7.6-5.5,13.8-12.2,13.8c-6.7,0-12.2-6.2-12.2-13.8H136.9z"/>
                <path class="st36" d="M188.8,88c0,7.6-5.7,13.8-12.7,13.8c-7,0-12.7-6.2-12.7-13.8H188.8z"/>
            </g>
            <rect x="53.9" y="152.3" class="st16" width="55.5" height="4.8"/>
            <g>
                <path class="st37" d="M197.1,133.1c-1.5-3-3.8-5.1-7.1-5.8c-3.6-0.8-6.8,0-9.6,2.5c-2.6,2.3-4.2,6.8-2.9,10.4
                    c1.6,4.2,3.9,8,6.5,11.7c1.1,1.6,2.3,3.2,3.5,5c0.3-0.4,0.5-0.6,0.7-0.8c3-4.1,5.8-8.2,8.1-12.7
                    C197.9,140.1,199,136.7,197.1,133.1z M187.6,143.5c-3.3,0-6.1-2.7-6.1-6.1s2.7-6.1,6.1-6.1s6.1,2.7,6.1,6.1
                    S190.9,143.5,187.6,143.5z"/>
                <g>
                    <path class="st18" d="M165.7,160.2h43.7v43.7h-43.7L165.7,160.2z"/>
                    <path class="st38" d="M193.2,174.8l-5.6-4.5l-5.6,4.5v-14.6h11.2V174.8z"/>
                    <path class="st20" d="M201.7,160.2h7.7v43.7h-7.7V160.2z"/>
                </g>
            </g>
            <g>
                <g>
                    <path class="st16" d="M157,154.4c0.3,1.7-1.1,3.2-2.8,2.8c-0.9-0.2-1.7-1-1.9-1.9c-0.3-1.7,1.1-3.1,2.8-2.8
                        C156.1,152.7,156.8,153.4,157,154.4z"/>
                </g>
                <g>
                    <path class="st16" d="M162.8,195.8H117v-79h45.8V195.8z M118.8,194h42.1v-75.2h-42.1V194z"/>
                </g>
            </g>
            <g>
                <g>
                    <path class="st39" d="M106,151.2H56.9v-36.6H106V151.2z"/>
                    <g>
                        <defs>
                            <rect id="XMLID_5_" x="57" y="114.4" width="49.2" height="36.5"/>
                        </defs>
                        <use xlink:href="#XMLID_5_"  style="overflow:visible;fill:#FFFFFF;"/>
                        <clipPath id="XMLID_22_">
                            <use xlink:href="#XMLID_5_"  style="overflow:visible;"/>
                        </clipPath>
                        <g class="st31">
                            
                                <rect x="41.9" y="135.8" transform="matrix(0.6467 -0.7627 0.7627 0.6467 -74.0633 116.5265)" class="st41" width="93.8" height="4.8"/>
                        </g>
                        <g class="st31">
                            
                                <rect x="27.8" y="121.4" transform="matrix(0.6467 -0.7627 0.7627 0.6467 -68.0737 100.728)" class="st41" width="93.8" height="4.8"/>
                        </g>
                    </g>
                </g>
                <g>
                    <path class="st16" d="M107.7,152.4H55.5v-39.5h52.1V152.4z M57,150.9h49.2v-36.5H57V150.9z"/>
                </g>
            </g>
        </g>
    </icon-base>
</template>
<script>

export default {
name: 'iconPickup',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>
	.st1{fill:#FFFFFF;}
	.st2{fill:#F4F7F9;}
	.st3{fill:#212E3D;}
	.st4{fill:#C8D3D6;}
	.st5{fill:#BC2C35;}
	.st6{fill:none;stroke:#DAE5EA;stroke-linecap:round;stroke-miterlimit:10;}
	.st7{fill:none;stroke:#DAE5EA;stroke-width:1.5;stroke-linecap:round;stroke-miterlimit:10;}
	.st8{fill:none;stroke:#DAE5EA;stroke-width:1.5;stroke-linecap:round;stroke-miterlimit:10;stroke-dasharray:11.6667,11.6667;}
	.st10{fill:#EA3838;}
	.st11{fill:#72BF58;}
	.st12{fill:#C32126;}
	.st13{fill:none;stroke:#A5B2B7;stroke-miterlimit:10;}
	.st14{fill:none;stroke:#C8D3D6;stroke-linecap:round;stroke-miterlimit:10;}
	.st15{fill:#DC2E2D;}
	.st16{fill:#C42C2D;}
	.st17{clip-path:url(#SVGID_11_);}
	.st18{fill:#E5AE5E;}
	.st19{fill:#FCEEDE;}
	.st20{fill:#D39542;}
	.st21{clip-path:url(#SVGID_15_);}
	.st22{fill:#E2EBED;}
	.st23{opacity:0.9;}
	.st24{fill:none;stroke:#E2EBED;stroke-miterlimit:10;}
	.st25{fill:#CB3637;}
	.st26{clip-path:url(#SVGID_18_);fill:#CE4148;}
	.st27{clip-path:url(#XMLID_21_);}
	.st28{fill:#EAEAE9;}
	.st29{clip-path:url(#SVGID_20_);fill:#E5AE5E;}
	.st30{clip-path:url(#SVGID_21_);fill:#CE4148;}
	.st31{clip-path:url(#XMLID_22_);}
	.st32{clip-path:url(#SVGID_22_);fill:#E5AE5E;}
	.st33{clip-path:url(#SVGID_24_);fill:#AF3B3B;stroke:#AF3B3B;stroke-width:2;stroke-miterlimit:10;}
	.st34{clip-path:url(#XMLID_23_);fill:#FFFFFF;}
	.st35{fill:#AF3B3B;}
	.st36{fill:#E8ECED;}
	.st37{fill:#263544;}
	.st38{fill:#F9FCF8;}
	.st39{fill:#D23534;}
	.st40{clip-path:url(#XMLID_24_);}
	.st41{fill:#F5F7F7;}
	.st42{clip-path:url(#SVGID_26_);fill:#AF3B3B;stroke:#AF3B3B;stroke-width:2;stroke-miterlimit:10;}
	.st43{clip-path:url(#XMLID_25_);fill:#FFFFFF;}
	.st44{clip-path:url(#XMLID_26_);}
	.st45{clip-path:url(#SVGID_30_);}
	.st46{clip-path:url(#SVGID_37_);}
	.st47{fill:#DFE7E8;}
	.st48{clip-path:url(#SVGID_39_);fill:#CE4148;}
	.st49{clip-path:url(#XMLID_27_);}
	.st50{clip-path:url(#SVGID_40_);fill:#E5AE5E;}
	.st51{clip-path:url(#SVGID_42_);fill:#AF3B3B;stroke:#AF3B3B;stroke-width:2;stroke-miterlimit:10;}
	.st52{clip-path:url(#XMLID_28_);fill:#FFFFFF;}
	.st53{clip-path:url(#XMLID_30_);}
	.st54{fill:#FFF6DC;}
	.st55{fill:none;stroke:#886619;stroke-width:2;stroke-miterlimit:10;}
	.st56{fill:none;stroke:#1F3D5B;stroke-width:2;stroke-miterlimit:10;}
	.st57{fill:none;stroke:#1F3D5B;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}
	.st58{fill:#111820;}
	.st59{display:none;}
	.st60{display:inline;}
	.st61{fill:#5A8CDB;}
	.st62{opacity:0.2;}
	.st63{fill:#C6D4DB;}
	.st64{fill:none;}
	.st65{fill:#FFD971;}
</style>
