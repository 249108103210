<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 16 12.444">
        <path id="Icon_awesome-id-card-2" data-name="Icon awesome-id-card" d="M14.667,2.25H1.333A1.334,1.334,0,0,0,0,3.583v.444H16V3.583A1.334,1.334,0,0,0,14.667,2.25ZM0,13.361a1.334,1.334,0,0,0,1.333,1.333H14.667A1.334,1.334,0,0,0,16,13.361V4.917H0ZM9.778,6.917A.223.223,0,0,1,10,6.694h4a.223.223,0,0,1,.222.222v.444A.223.223,0,0,1,14,7.583H10a.223.223,0,0,1-.222-.222Zm0,1.778A.223.223,0,0,1,10,8.472h4a.223.223,0,0,1,.222.222v.444A.223.223,0,0,1,14,9.361H10a.223.223,0,0,1-.222-.222Zm0,1.778A.223.223,0,0,1,10,10.25h4a.223.223,0,0,1,.222.222v.444a.223.223,0,0,1-.222.222H10a.223.223,0,0,1-.222-.222ZM4.889,6.694A1.778,1.778,0,1,1,3.111,8.472,1.779,1.779,0,0,1,4.889,6.694ZM1.864,12.367a1.78,1.78,0,0,1,1.692-1.228h.228a2.861,2.861,0,0,0,2.211,0h.228a1.78,1.78,0,0,1,1.692,1.228.437.437,0,0,1-.433.55H2.3A.438.438,0,0,1,1.864,12.367Z" transform="translate(0 -2.25)"/>
    </icon-base>
</template>
<script>
import iconBase from '../../../../common/components/iconBase.vue'
export default {
    components: { iconBase },
    name: 'iconLastName',
    props:{
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>