<template>
    <div class="card-container" @click="$emit('open', $event.target)">
        <slot/>
    </div>
</template>

<script>
export default {
    name: "MainCollapse"
}
</script>

<style scoped>
    /* .card-container{
        padding: 3%;
        border: 2px solid #dae5ea;
        border-radius: 20px;
    } */
    .icon-normal{
        transition: var(--transition-1);
        transform: rotate(90deg);
    }
    .icon-active{
        transition: var(--transition-1);
        transform: rotate(-90deg) !important;
    }
</style>