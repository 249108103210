<template>
    <div>
        <h3 class="m-0 color-blue-700 title-24 weight-700">{{$t("checkout.method_of_delivery")}}</h3>
        <span>{{$t("checkout.choose_the_method")}}.</span>
        <div class="mt-3">
            <alert-warning style="padding: 2px 15px" :size="20" :show="!addressSelected" :title="'checkout.you_must_create_and_select_at_least_one_address_to_continue'" 
            :showCounter="false" v-if="!addressSelected && checkoutSteps.addressOptionSelected != 3 && checkoutSteps.addressOptionSelected != 0"/>
        </div>
        <div class="card-container mt-2 mt-md-4">
            <h6 class="mb-0">{{$t("checkout.select_option_preferred")}}</h6>
            <div class="row mt-4 m-0">
                <div v-for="(option) in options" :key="option.value" class="option-btn col-md-4 text-center p-2 btn m-0" @click="validateSelection(option)"
                :class="[option.value == checkoutSteps.addressOptionSelected ? 'active-option': '',  checkoutSteps.addressOptionSelected != 0 ? 'options':'']"> 
                    <div class="px-0 px-md-3">
                        <HalfCircleSpinner v-if="option.value == 2 && loading" :animation-duration="1000" :size="20" class="d-none d-md-flex" color="var(--main-red)"></HalfCircleSpinner>
                        <radio-button v-else :checked="option.value == checkoutSteps.addressOptionSelected" class="d-none d-md-flex"/>
                        <div class="icon-address justify-content-center align-items-start d-none d-md-flex">
                            <component :is="option.icon" :size="130"></component>
                        </div>
                        <div>
                            <div class="d-flex align-items-center justify-content-start justify-content-md-center">
                                <HalfCircleSpinner v-if="option.value == 2 && loading" :animation-duration="1000" :size="20" class="d-block d-md-none me-2" color="var(--main-red)"></HalfCircleSpinner>
                                <radio-button v-else :checked="option.value == checkoutSteps.addressOptionSelected" class="d-block d-md-none me-2"/>
                                <h6 class="title-14 mb-0 mb-md-2">{{$t(option.title)}} </h6>
                            </div>
                            <p class="subtitle-13 color-dark-gray-200 mx-auto mb-0 description" style="line-height: 16px;">{{$t(option.description)}}.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <checkout-delivery v-if="checkoutSteps.addressOptionSelected == 1 || checkoutSteps.addressOptionSelected == 2" :addressAvailable="addressAvailable" class="animation-slide-down"/>
        <checkout-pick-up  v-if="checkoutSteps.addressOptionSelected == 3" class="animation-slide-down"/>
    </div>
</template>

<script>
import MainCollapse from '../../../common/components/collapse/MainCollapse.vue'
import RadioButton from '../../../common/components/radio/RadioButton.vue'
import IconCheckOrder from '../assets/svg/iconCheckOrder.vue'
import IconDelivery from '../assets/svg/iconDelivery.vue'
import IconNational from '../assets/svg/iconNational.vue'
import IconPickup from '../assets/svg/iconPickup.vue'
import IconArrow from '../../../common/svg/iconArrow.vue'
import ListAddress from '../../account/components/addresses/ListAddress.vue'
import AddressSelected from '../../account/components/addresses/AddressSelected.vue'
import IconAdd from '../../../common/svg/iconAdd.vue'
import CreateAddress from '../../account/components/addresses/modals/CreateAddress.vue'
import CheckoutDelivery from './address/CheckoutDelivery.vue'
import { mapGetters } from "vuex"
import CheckoutPickUp from './address/CheckoutPickUp.vue'
import AlertWarning from '../../../common/components/alerts/AlertWarning.vue'
import { http } from '../../../common/services/web-backend'
import { HalfCircleSpinner } from 'epic-spinners'

export default {
    components: {
        RadioButton, 
        IconDelivery, 
        IconPickup, 
        IconNational, 
        MainCollapse, 
        IconCheckOrder, 
        IconArrow, 
        ListAddress, 
        AddressSelected, 
        IconAdd, 
        CreateAddress,
        CheckoutDelivery,
        CheckoutPickUp,
        AlertWarning,
        HalfCircleSpinner  
    },
    name: "CheckoutAddress",
    data() {
        return {
            options:[
                {
                    value: 1,
                    title: 'checkout.local_messaging',
                    description: 'checkout.select_address_to_send',
                    icon: 'IconDelivery'
                },
                {
                    value: 3,
                    title: 'checkout.store_pickup',
                    description: 'checkout.pick_up_your_purchase',
                    icon: 'IconPickup'
                },
                {
                    value: 2,
                    title: 'checkout.national_shipping',
                    description: 'checkout.select_to_receive',
                    icon: 'IconNational'
                },
            ],
            loading: false
        }
    },
    props:{
        addressAvailable:{
            type: Number
        },
    }, 
    computed:{
        ...mapGetters({
            checkoutSteps: "checkout/checkoutSteps",
            deliveryQuotationID: "checkout/deliveryQuotationID",
            addressSelectedAvailable: "account/addressSelectedAvailable",
            addressSelected: "account/addressSelected",
            configurations: "common/configurations",
            branchSelected: "branches/branchSelected",
            carrierSelected: "account/carrierSelected"
        })
    },
    watch:{
        'checkoutSteps.addressOptionSelected'(){
            if(this.checkoutSteps.addressOptionSelected != 2){
                this.$store.commit("checkout/RESET_PAYMENTS")
            }
        },
        async addressSelected(){
            this.updateDeliveryPrice()
        },
        async carrierSelected(){
            this.updateDeliveryPrice()
        }
    },
    methods:{
        optionUnavailable(){
            this.$emit("optionNotAvailable")
            this.$store.commit('checkout/SET_ADDRESS_OPTION', 0)
        },
        updateDeliveryPrice(){
            if (this.deliveryQuotationID) return;
            const orderWithDelivery = JSON.parse(localStorage.getItem('orderDetail'));
            const carrierPrice = parseFloat(this.carrierSelected.amount || 0);

            const deliveryPrice = this.checkoutSteps.addressOptionSelected == 2 ? carrierPrice : this.addressSelectedAvailable?.price;

            orderWithDelivery.total_order += deliveryPrice ?? 0;
            orderWithDelivery.total_order_without_taxes += deliveryPrice ?? 0;
            
            this.$store.commit('checkout/SET_ORDER_CONRFIRM', orderWithDelivery);
        },
        validateSelection(option){
            if(this.deliveryQuotationID){
                const notify = {
                    text: 'La cotización ya tiene un método de envio.',
                    type: 'warning',
                    show: true,
                }
                this.$store.commit('common/SET_SHORT_NOTIFY', notify, { root: true });
            }else{
                this.selectAddressOption(option);
            }
        },
        async selectAddressOption({value}){
            this.$store.commit('account/RESET_CARRIER')
            this.$store.commit("checkout/RESET_PAYMENTS")
            const {local_delivery, nacional_delivery, pick_up} = this.configurations.delivery_options
            
            switch (value) {
                case 1:
                    if(!local_delivery.active) this.optionUnavailable()
                    else{
                        this.updateDeliveryPrice()
                        this.$store.commit('checkout/SET_ADDRESS_OPTION', value)
                    }
                break;

                case 2:
                    if(!nacional_delivery.active){
                        this.optionUnavailable()
                    }else {
                        try {
                            this.loading = true
                            let products = this.$store.getters['cart/getProducts'];
                            let productByPlaces = {};
                            productByPlaces[this.branchSelected.codplace] = []
                            for (let product of products){
                                productByPlaces[this.branchSelected.codplace].push(
                                    {
                                    codarticulo: product.product.codarticulo,
                                    quantity: product.quantity,
                                    note: product.note
                                })
                            }
                            const response = await http.post(`shippo/validate-products`, productByPlaces);
                            if(response.data.message == 'success'){
                                this.$store.commit('checkout/SET_ADDRESS_OPTION', value)
                            }
                        } catch (error) {
                            this.loading = false
                            this.optionUnavailable()
                        }
                        this.loading = false
                    } 
                break;

                case 3:
                    if(!pick_up.active) this.optionUnavailable()
                    else{
                        this.$store.commit('checkout/SET_ORDER_CONRFIRM', JSON.parse(localStorage.getItem('orderDetail')));
                        this.$store.commit('checkout/SET_ADDRESS_OPTION', value)
                    }
                break;

                default:
                    this.optionUnavailable()
                break;
            }
        },
    },
    mounted(){
        if(this.deliveryQuotationID){
            setTimeout(() => {
                this.selectAddressOption({value: 1});
            }, 1000);
        }
    }
}
</script>

<style scoped>
    .card-container{
        padding: 5%;
        border: 2px solid #dae5ea;
        border-radius: 20px;
    }
    .active-option{
        background: #b7babc17;
        opacity: 1 !important;
    }
    .option-btn:not(:last-child){
        border-right: 1px solid var(--gray-100);
    }
    .options{
        opacity: 0.7;
    }
    .icon-normal{
        transition: var(--transition-1);
        transform: rotate(90deg);
    }
    .option-disabled{
        cursor: no-drop;
        opacity: 0.5;
    }
    .btn {
        transition: var(--transition-1);
    }
    .option-btn:hover > div > .icon-address > svg{
        transform: scale(1.1);
    }
    .option-btn:hover {
        opacity: 1;
    }
    .option-btn > div > .icon-address > svg{
        transition: var(--transition-1);
    }
    .row > .btn:nth-child(3) > div > svg{
        transition: var(--transition-1);
        transform: scale(1) !important;
    }
    @media (max-width: 767px) {
        .description {
            padding-left: 26px;
            text-align: start;
        }
        .option-btn {
            border-right: none !important;
        }
        .card-container{
            border-radius: 10px;
            border: 1px solid #dae5ea;
        }      
    }
</style>
