<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
            <g>
                <g>
                    <g>
                        <path class="st15" d="M70.1,46.5L92,46.6l-0.2,49.1l-21.9-0.1L70.1,46.5z"/>
                        <path class="st16" d="M82.7,46.6l9.4,0l-0.2,49.1l-9.4,0L82.7,46.6z"/>
                        <g>
                            <defs>
                                <path id="SVGID_9_" d="M41.3,103.8l4.9,0l11.2,0L57,205.6l135.3,0.5l0.4-101.7l11.2,0l4.9,0l-81.9-62.1L41.3,103.8z"/>
                            </defs>
                            <defs>
                                <path id="SVGID_10_" d="M193.6,207.4l-137.9-0.5l0.4-101.7l-18.8-0.1l89.6-64.3l85.8,65l-18.7-0.1L193.6,207.4z M58.3,204.3
                                    l132.7,0.5l0.4-101.7l13.5,0.1l-78-59.1l-81.5,58.5l13.4,0.1L58.3,204.3z"/>
                            </defs>
                            <use xlink:href="#SVGID_9_"  style="overflow:visible;fill:#FFFFFF;"/>
                            <use xlink:href="#SVGID_10_"  style="overflow:visible;fill:#C42C2D;"/>
                            <clipPath id="SVGID_4_">
                                <use xlink:href="#SVGID_9_"  style="overflow:visible;"/>
                            </clipPath>
                            <clipPath id="SVGID_6_" class="st9">
                                <use xlink:href="#SVGID_10_"  style="overflow:visible;"/>
                            </clipPath>
                        </g>
                    </g>
                </g>
                <path class="st3" d="M169,133.9c-1.6-3.1-4-5.4-7.5-6.2c-3.8-0.9-7.2,0-10.2,2.6c-2.8,2.5-4.5,7.2-3.1,11
                    c1.7,4.5,4.1,8.5,6.8,12.5c1.2,1.7,2.4,3.4,3.7,5.3c0.3-0.4,0.5-0.6,0.7-0.9c3.2-4.3,6.2-8.7,8.6-13.5
                    C169.9,141.4,171,137.8,169,133.9z M158.9,145c-3.6,0-6.4-2.9-6.4-6.5c0-3.6,2.9-6.4,6.5-6.4s6.4,2.9,6.4,6.5
                    C165.3,142.1,162.4,145,158.9,145z"/>
                <g>
                    <path class="st18" d="M135.6,162.6l46.4,0.2l-0.2,46.4l-46.4-0.2L135.6,162.6z"/>
                    <path class="st19" d="M164.7,178.2l-5.9-4.8l-6,4.7l0.1-15.5l11.9,0L164.7,178.2z"/>
                    <path class="st20" d="M173.8,162.8l8.2,0l-0.2,46.4l-8.2,0L173.8,162.8z"/>
                </g>
                <polygon class="st16" points="58.6,103.9 125.9,60.6 191.5,104.4 208.8,104.4 126.9,42.4 41.3,103.8 		"/>
            </g>
            <g>
                <path class="st16" d="M117.9,162.7c0.4,1.8-1.2,3.4-3,3c-1-0.2-1.8-1-2-2c-0.3-1.8,1.2-3.3,3-3
                    C116.9,160.9,117.7,161.7,117.9,162.7z"/>
            </g>
            <g>
                <path class="st16" d="M124,206.7H75.4v-83.8H124V206.7z M77.4,204.7h44.7v-79.9H77.4V204.7z"/>
            </g>
        </g>
    </icon-base>
</template>
<script>

export default {
name: 'iconDelivery',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>
	.st1{fill:#FFFFFF;}
	.st2{fill:#F4F7F9;}
	.st3{fill:#212E3D;}
	.st4{fill:#C8D3D6;}
	.st5{fill:#BC2C35;}
	.st6{fill:none;stroke:#DAE5EA;stroke-linecap:round;stroke-miterlimit:10;}
	.st7{fill:none;stroke:#DAE5EA;stroke-width:1.5;stroke-linecap:round;stroke-miterlimit:10;}
	.st8{fill:none;stroke:#DAE5EA;stroke-width:1.5;stroke-linecap:round;stroke-miterlimit:10;stroke-dasharray:11.6667,11.6667;}
	.st9{clip-path:url(#SVGID_4_);}
	.st10{fill:#EA3838;}
	.st11{fill:#72BF58;}
	.st12{fill:#C32126;}
	.st13{fill:none;stroke:#A5B2B7;stroke-miterlimit:10;}
	.st14{fill:none;stroke:#C8D3D6;stroke-linecap:round;stroke-miterlimit:10;}
	.st15{fill:#DC2E2D;}
	.st16{fill:#C42C2D;}
	.st17{clip-path:url(#SVGID_11_);}
	.st18{fill:#E5AE5E;}
	.st19{fill:#FCEEDE;}
	.st20{fill:#D39542;}
	.st21{clip-path:url(#SVGID_15_);}
	.st22{fill:#E2EBED;}
	.st23{opacity:0.9;}
	.st24{fill:none;stroke:#E2EBED;stroke-miterlimit:10;}
	.st25{fill:#CB3637;}
	.st26{clip-path:url(#SVGID_18_);fill:#CE4148;}
	.st27{clip-path:url(#XMLID_21_);}
	.st28{fill:#EAEAE9;}
	.st29{clip-path:url(#SVGID_20_);fill:#E5AE5E;}
	.st30{clip-path:url(#SVGID_21_);fill:#CE4148;}
	.st31{clip-path:url(#XMLID_22_);}
	.st32{clip-path:url(#SVGID_22_);fill:#E5AE5E;}
	.st33{clip-path:url(#SVGID_24_);fill:#AF3B3B;stroke:#AF3B3B;stroke-width:2;stroke-miterlimit:10;}
	.st34{clip-path:url(#XMLID_23_);fill:#FFFFFF;}
	.st35{fill:#AF3B3B;}
	.st36{fill:#E8ECED;}
	.st37{fill:#263544;}
	.st38{fill:#F9FCF8;}
	.st39{fill:#D23534;}
	.st40{clip-path:url(#XMLID_24_);}
	.st41{fill:#F5F7F7;}
	.st42{clip-path:url(#SVGID_26_);fill:#AF3B3B;stroke:#AF3B3B;stroke-width:2;stroke-miterlimit:10;}
	.st43{clip-path:url(#XMLID_25_);fill:#FFFFFF;}
	.st44{clip-path:url(#XMLID_26_);}
	.st45{clip-path:url(#SVGID_30_);}
	.st46{clip-path:url(#SVGID_37_);}
	.st47{fill:#DFE7E8;}
	.st48{clip-path:url(#SVGID_39_);fill:#CE4148;}
	.st49{clip-path:url(#XMLID_27_);}
	.st50{clip-path:url(#SVGID_40_);fill:#E5AE5E;}
	.st51{clip-path:url(#SVGID_42_);fill:#AF3B3B;stroke:#AF3B3B;stroke-width:2;stroke-miterlimit:10;}
	.st52{clip-path:url(#XMLID_28_);fill:#FFFFFF;}
	.st53{clip-path:url(#XMLID_30_);}
	.st54{fill:#FFF6DC;}
	.st55{fill:none;stroke:#886619;stroke-width:2;stroke-miterlimit:10;}
	.st56{fill:none;stroke:#1F3D5B;stroke-width:2;stroke-miterlimit:10;}
	.st57{fill:none;stroke:#1F3D5B;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}
	.st58{fill:#111820;}
	.st59{display:none;}
	.st60{display:inline;}
	.st61{fill:#5A8CDB;}
	.st62{opacity:0.2;}
	.st63{fill:#C6D4DB;}
	.st64{fill:none;}
	.st65{fill:#FFD971;}
</style>
