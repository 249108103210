<template>
    <main-confirm @confirm="products.length > 0 ?$emit('reset') :$emit('confirm')" @cancel="$emit('closeModal')" confirm-title="checkout.transaction_not_completed" :oneOption="products.length > 0"
    :description="description ? description : 'checkout.description_transaction_not_completed'" :buttonConfirmTitle="products.length > 0 ? 'Ir a carrito' :'checkout.retry'" :isQuestion="products.length == 0">
        <icon-warning :size="60"/>
        <template #map v-if="products.length > 0">
            <table class="table mt-2">
                <thead>
                    <tr style="border-bottom: 2px solid var(--gray-50);">
                        <th class="size-14 weight-500 color-dark-gray-300">Codigo</th>
                        <th class="size-14 weight-500 color-dark-gray-300 text-center">Descripcion</th>
                        <th class="size-14 weight-500 color-dark-gray-300 text-center pe-5">Cantidad</th>
                        <th class="size-14 weight-500 color-dark-gray-300 text-center pe-5">Existencia</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="product in products" :key="product.product_id">
                        <td class="subtitle-14 align-middle text-center weight-600 pe-5">{{ product.product_id }}</td>
                        <td class="subtitle-14 align-middle text-center pe-5">{{ product.product_desc | textFormat }}</td>
                        <td class="subtitle-14 align-middle text-center pe-5">{{ product.quantity }}</td>
                        <td class="subtitle-14 color-main-red align-middle text-center pe-5">{{ product.existence }}</td>
                    </tr>
                </tbody>
            </table>
        </template>
    </main-confirm>
</template>
<script>
import MainConfirm from '../../../../common/components/modals/MainConfirm.vue'
import IconWarning from '../../../../common/svg/iconWarning.vue'
export default {
    components: { MainConfirm, IconWarning},
    name: "MakeOrderTryAgain",
    props:{
        description:{
            type: String
        },
        products:{
            type: Array
        }
    }
}
</script>

<style scoped>
    .main-confirm::v-deep> .modal-main div p{
        font-size: 15px;
        width: 400px;
    }   
</style>