<template>
    <main-modal :title="'checkout.bank_accounts'" @closeModal="$emit('closeModal')">
        <h6 class="title-16 weight-500">{{$t("checkout.to_make_your_purchase_you_can_transfer_to_any_of_the_following_accounts")}}</h6>
        <h6 class="subtitle-16 color-dark-gray-300">{{$t("checkout.all_in_the_name")}} <b>{{ this.configurations.general.web_page.rnc }}</b></h6>
        <div class="mt-3">
            <div v-for="(bank, index) in banks" :key="index" :class="[index < 3 ? 'border-bottom' : '']" class="py-2 d-flex align-items-center justify-content-between" style="padding: 0% 7%;">
                <div class="d-flex align-items-center">
                    <img :src="bank.img" alt="" width="60" height="60">
                    <div class="ms-3">
                        <h5 class="title-16 mb-1">{{bank.name}}</h5>
                        <h5 class="subtitle-16 color-dark-gray-300 mb-0">{{$t("checkout.current")}} {{bank.account}}</h5>
                    </div>
                </div>
                <div @click="copy(bank.account)" class="position-relative" @mouseenter="showCopy(bank.id, true)" @mouseleave="showCopy(bank.id, false)">
                    <div class="copy-tooltip" :class="[copyData ? 'copied' : '']" v-if="select === index + 1">{{copyData ? $t("product.copied") : $t("checkout.copy")}}</div>
                    <icon-copy :size="25" :color="'var(--gray-1001)'" class="hover-main-red"/>
                </div>
            </div>
        </div>
    </main-modal>
</template>

<script>
import MainModal from '../../../../common/components/modals/MainModal.vue'
import IconCopy from '../../../../common/svg/iconCopy.vue'
import { mapGetters } from 'vuex'

export default {
    name: "BankAccounts",
    components:{
        MainModal,
        IconCopy,
    },
    computed: {
        ...mapGetters({
            configurations: "common/configurations", 
        }),
    },
    data() {
        return {
            banks:[],
            select: null,
            copyData: false
        }
    },
    methods:{
        copy(account){
            navigator.clipboard.writeText(account);
            this.copyData = true
        },
        showCopy(id, state){
            if (state) {
                this.select = id 
            }else{
                this.copyData = false
                this.select = null 
            }
        }
    },
    created(){
        this.banks = this.configurations.general.web_page.bank_accounts
    }
}
</script>
<style scoped>
    .copy-tooltip{
        position: absolute;
        left: -17px;
        top: -28px;
        font-size: 11px;
        color: #fff;
        background: #414146eb;
        border-radius: 4px;
        padding: 1px 15px;
    }
    .copy-tooltip::after{
        content: '';
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #414146eb;
        position: absolute;
        left: 23px;
        bottom: -7px;
    }
    .copied{
        padding: 1px 10px;
    }
</style>
