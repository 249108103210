import { render, staticRenderFns } from "./OrderConfirmed.vue?vue&type=template&id=642b7574&scoped=true"
import script from "./OrderConfirmed.vue?vue&type=script&lang=js"
export * from "./OrderConfirmed.vue?vue&type=script&lang=js"
import style0 from "./OrderConfirmed.vue?vue&type=style&index=0&id=642b7574&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "642b7574",
  null
  
)

export default component.exports