<template>
    <main-confirm @confirm="$emit('confirm')" @cancel="$emit('closeModal')" :confirm-title="'El precio de los productos ha cambiado.'" description="¿ Estás seguro que deseas continuar" 
    customDescription>
        <icon-cancel-order :size="100"/>
        <template #customDescription>
            <div>
                <p class="color-dark-gray-200 mb-0">
                    La diferencia de total es <b class="color-main-navy">{{totalDifference | moneyFormat}}</b>
                </p>
            </div>
        </template>
    </main-confirm>
</template>

<script>
import MainConfirm from '../../../../common/components/modals/MainConfirm.vue';
import IconCancelOrder from '../../../account/assets/svg/iconCancelOrder.vue';

export default {
    name: "InvoicedDifferrence",
    components: {
        MainConfirm,
        IconCancelOrder
    },
    props:{
        titleButton:{
            type:String,
            default: 'auth.confirm'
        },
        totalDifference:{
            type: Number
        }
    }
}
</script>
