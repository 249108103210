<template>
    <main-confirm :loading="loading" @confirm="$emit('confirmDelete')" @cancel="$emit('closeModal')" :confirm-title="'checkout.delete_card'" description="checkout.are_you_sure_you_want_to_delete_this_card">
        <icon-delete-card :size="100"/>
    </main-confirm>
</template>

<script>
import MainConfirm from '../../../../common/components/modals/MainConfirm.vue';
import IconDeleteCard from '../../assets/svg/iconDeleteCard.vue';

export default {
    name: "ConfirmDeleteCard",
    components: {
    MainConfirm,
    IconDeleteCard
},
    props:{
        titleButton:{
            type:String,
            default: 'auth.confirm'
        },
        loading:{
            type: Boolean
        }
    }
}
</script>
