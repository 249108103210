<template>
    <main-confirm @confirm="$emit('confirm')" @cancel="$emit('closeModal')" confirm-title="checkout.everything_is_paid" description="checkout.description_everything_is_paid"
    :isQuestion="false">
        <icon-warning :size="50"/>
    </main-confirm>
</template>
<script>
import MainConfirm from '../../../../common/components/modals/MainConfirm.vue'
import IconWarning from '../../../../common/svg/iconWarning.vue'
export default {
    components: { MainConfirm, IconWarning},
    name: "AllPaid"
}
</script>

<style scoped>
    .main-confirm::v-deep > .modal-main > .content > .mt-4 > div > .main-button{
        min-width: 93px;
    }
</style>
