<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g id="Layer_10">
            <g>
                <path class="st18" d="M206.3,137.3V62.6V23.8c0-4.1-3.3-7.5-7.5-7.5h-70v66.3l-25.4-17.2L78,82.7V16.4H8c-4.1,0-7.5,3.4-7.5,7.5
                    v190.8c0,4.1,3.4,7.5,7.5,7.5h155.8c-6.6-8.9-10.7-19.8-10.7-31.7C153.1,161.1,176.9,137.3,206.3,137.3z"/>
                <path class="st18" d="M206.3,147.2c-23.9,0-43.2,19.4-43.2,43.2c0,23.9,19.4,43.2,43.2,43.2s43.2-19.4,43.2-43.2
                    C249.5,166.5,230.1,147.2,206.3,147.2z M229.5,180.3l-26.5,26.6c-0.9,0.9-2,1.3-3.2,1.3c0,0,0,0,0,0c-1.2,0-2.4-0.5-3.2-1.4
                    L183,193c-1.8-1.8-1.7-4.7,0.1-6.5c1.8-1.8,4.7-1.7,6.5,0.1l10.4,10.6l23.2-23.3c1.8-1.8,4.7-1.8,6.5,0
                    C231.3,175.7,231.3,178.5,229.5,180.3z"/>
            </g>
        </g>
    </icon-base>
</template>
<script>
// used inside the main-confirm modal we need the id whe the user clicks on the path of the svg icon we activate the action, we need to assign that only to the main-modal
export default {
name: 'iconCheckOrder',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>

</style>
