<template>
        <main-confirm :confirmTitle="title" :description="description" @cancel="$emit('closeModal')" @confirm="$emit('confirm')" :isQuestion="false">
            <icon-single-payment :size="80"/>
        </main-confirm>
</template>

<script>
import MainConfirm from '../../../../common/components/modals/MainConfirm.vue'
import IconSinglePayment from '../../assets/svg/iconSinglePayment.vue'
export default {
    components: { MainConfirm, IconSinglePayment },
    name: "SinglePayment",
    props:{
        title:{
            type:String
        },
        description:{
            type: String
        }
    }
}
</script>
