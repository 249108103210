<template>
    <div class="row mt-4">
        <div class="justify-content-center col-md-5 cursor-pointer" @click="payCash">
            <div class="text-center">
                <div>
                    <radio-button :checked="cashSelected.select"/>
                    <icon-cash :size="120" class="mb-3"/>
                </div>
                <h6 class="m-0 weight-600">{{$t("A contado")}}</h6>
                <span class="m-0 weight-300">{{$t("checkout.buy_and_pay_on_receipt")}}</span>
                <h6 class="mt-4">{{$t('checkout.total')}}:<span class="color-main-red ms-2">{{orderConfirm.total_order | moneyFormat}}</span> </h6>
            </div>
        </div>
        <div class="divider-cash col-xl-2 d-flex justify-content-center">
            <div style="border: 0.2px solid var(--dark-gray-200);opacity:0.3"></div>
        </div>
        <div class="justify-content-center col-md-5 cursor-pointer" :class="{'opacity-3' : parseFloat(this.user.customer.credit_limit) <= orderConfirm.total_order}" @click="payCredit">
            <div class="text-center">
                <div>
                    <radio-button :checked="creditSelected.select"/>
                    <icon-credit :size="120" class="mb-3"/>
                </div>
                <h6 class="m-0 weight-600">{{$t("checkout.credit_payment")}} {{this.user.customer.credit_limit | moneyFormat}}</h6>
                <span class="m-0 weight-300">{{$t("checkout.buy_now_pay_later")}}!</span>
                <h6 class="mt-4">{{$t('checkout.total')}}:<span class="color-main-red ms-2">{{orderConfirm.total_order | moneyFormat}}</span> </h6>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
    import RadioButton from '../../../../common/components/radio/RadioButton.vue'
    import iconCash from '../../assets/svg/iconCash.vue'
    import IconCredit from '../../assets/svg/iconCredit.vue'
    export default {
        components: { iconCash, IconCredit, RadioButton },
        name: 'CashCredit',
        computed:{
            ...mapGetters({
                cashSelected: "checkout/cashSelected",
                creditSelected: "checkout/creditSelected",
                orderConfirm: "checkout/orderConfirm",
                user: "auth/userData",
            })
        },
        methods: {
            payCash(){
                this.creditSelected.balance = 0
                this.creditSelected.select = false

                this.cashSelected.balance = this.orderConfirm.total_order
                this.cashSelected.select = true
            },
            payCredit(){
                this.cashSelected.balance = 0
                this.cashSelected.select = false

                if(this.user.customer.credit_limit <= this.orderConfirm.total_order){
                    let error = {
                        show: true,
                        title: `Credito no disponible`,
                        contentHTML: `
                            <div class="text-center" style="line-height: 19px;max-width: 400px;">
                                <span class="color-dark-gray-200">
                                    Lo sentimos credito no disponible. 
                                </span>
                                <br>
                                <span class="size-14">
                                    <span class="text-capitalize">
                                        ${this.$options.filters.textFormat(this.user.entity.first_name)} 
                                        ${this.$options.filters.textFormat(this.user.entity.last_name)},
                                    </span>
                                    cuenta con <b class="color-main-red">${this.$options.filters.moneyFormat(this.user.customer.credit_limit, 0)}</b> 
                                    de credito disponible.
                                </span>
                            </div>
                        `
                    }
                    this.$store.commit('common/SHOW_MODAL_ERROR', error)
                    return
                }
                this.creditSelected.balance = this.orderConfirm.total_order
                this.creditSelected.select = true
            }
        }
    }
</script>

<style scoped>

</style>