<template>
    <div>
        <form autocomplete="off" id="partner" @submit.prevent="submitInformation">
            <div class="row">
                <main-input :id="'name'" title="checkout.name_person" ref="name" :value="form.full_name" @changeValue="(data)=>{form.full_name = data}" 
                :placeholder="'checkout.name_person'" class="col-md-4 mt-2 mt-sm-0" :min="5">
                    <icon-person :size="15" color="var(--dark-gray-200)"/>
                </main-input>
                <main-input :id="'identification'" title="checkout.identification_card" ref="identification" :value="form.identification" @changeValue="(data)=>{form.identification = data}" 
                :mask="'###-#######-#'" :placeholder="'000-0000000-0'" class="col-md-4 mt-2 mt-sm-0" :customValidate="'identification'">
                    <icon-last-name color="var(--dark-gray-200)" />
                </main-input>
                <main-input :id="'phone'" :value="form.whatsapp" @changeValue="(data)=>{form.whatsapp = data}" ref="phone" type="phone" :mask="'+1 ###-###-####'" 
                :placeholder="'+1 (809)-000-0000'" title="checkout.cell_phone_number" class="col-md-4 mt-2 mt-sm-0">
                    <icon-phone :size="15" color="var(--dark-gray-200)"/>
                </main-input>
            </div>
            <div class="d-flex justify-content-end mt-4">
                <second-button title="checkout.save" id="save" />
                <!-- <second-button :title="'account.cancel'" :id="'cancel'" class="ms-3" @click="cancel" v-if="editData"/> -->
            </div>
        </form>
    </div>
</template>
<script>
import MainInput from '../../../../common/components/inputs/MainInput.vue'
import IconPerson from '../../../../common/svg/iconPerson.vue'
import IconPhone from '../../../../common/svg/iconPhone.vue'
import { http } from "@/common/services/web-backend.js";
import SecondButton from '../../../../common/components/buttons/SecondButton.vue'
import IconLastName from '../../../account/assets/svg/iconLastName.vue';

export default {
    components: { MainInput, IconPerson, IconPhone, SecondButton, IconLastName},
    name: "CreatePickUp",
    data(){
        return{
            form: {
                full_name: "",
                identification: "",
                whatsapp: "",
                default: false
            },
        }
    },
    props:{
        editData: {
            type: Object,
            default: null
        },
        defaultPartner:{
            type: Boolean
        }
    },
    methods:{
        submitInformation(){
            if(this.editData){
                this.updatePartner()
            }else{
                this.addPartner()
            }
        },
        async addPartner() {
            try {
                this.$refs.name.$v.$touch();
                this.$refs.phone.$v.$touch();
                this.$refs.identification.$v.$touch();
                if (this.$refs.name.$v.$invalid || this.$refs.phone.$v.$invalid || 
                    this.$refs.identification.$v.$invalid){
                    return
                }
                await http.post('users/partners', this.form);
                // this.$emit("getPartners", response.data.data)
                await this.$store.dispatch('checkout/getPartners')
                let notify = { text: 'checkout.partner_created_successfully', type: 'success', show: true,}
                this.$store.commit("common/SET_SHORT_NOTIFY", notify)
                this.reset() 
            } catch (e) {
                let notify = { text: e.response.data.message, type: 'danger', show: true,}
                this.$store.commit("common/SET_SHORT_NOTIFY", notify)
            }
        },
        async updatePartner(){
            try {
                this.$refs.name.$v.$touch();
                this.$refs.phone.$v.$touch();
                this.$refs.identification.$v.$touch();
                if (this.$refs.name.$v.$invalid || this.$refs.phone.$v.$invalid || 
                    this.$refs.identification.$v.$invalid){
                    return
                }
                this.$store.dispatch('checkout/updatePartner', {id: this.editData.id, form: this.form})
                let notify = {
                    title: 'checkout.partner_upgraded_title',
                    text: 'checkout.partner_upgraded_text',
                    type: 'success',
                    show: true,
                }
                this.$store.commit("common/SET_SHORT_NOTIFY", notify)
                this.$emit('clearData')
                this.reset() 
            } catch (e) {
                let notify = {
                    text: 'common.notification.unexpected_error',
                    type: 'danger',
                    show: true,
                }
                this.$store.commit("common/SET_SHORT_NOTIFY", notify)
                console.log(e);
            }
        },
        reset(){
            this.form.full_name = "";
            this.form.identification = "";
            this.form.whatsapp = "";
            setTimeout(() => {
                this.$refs.name.$v.$reset();
                this.$refs.phone.$v.$reset();
                this.$refs.identification.$v.$reset();
            }, 0);
        },
        cancel(){
            this.$emit('clearData') 
        }
    },
    watch:{
        editData(){
            if(this.editData){
                this.form.full_name = this.editData.full_name;
                this.form.identification = this.editData.identification;
                this.form.whatsapp = this.editData.whatsapp;
                this.form.default = this.editData.default 
            }
        },
        defaultPartner(val){
            this.form.default = val
        }
    },
    created(){
        if(this.editData){
            this.form.full_name = this.editData.full_name;
            this.form.identification = this.editData.identification;
            this.form.whatsapp = this.editData.whatsapp;
            this.form.default = this.editData.default
        }
    }
}
</script>
