<template>
    <div class="loading d-flex">
        <div class="loading-wrapper d-flex flex-column">
            <div class="content p-4">
                <a href="#" class="d-flex justify-content-end" @click="$router.replace({path: '/'})">
                    <icon-close :color="'var(--dark-gray-200)'"/>
                </a>
                <lottie :options="defaultOptions" :height="150" :width="500" v-on:animCreated="handleAnimation"
                style="margin-top: -40px;margin-bottom: -40px;"/>
                <div class="text-center">
                    <h2 class="title-22 weight-700 mb-4 mt-4">{{$t("checkout.your_order_was_confirmed")}}!</h2>
                    <h5 class="text-capitalize size-18">{{$t('layout.hi')}}, {{user.entity.first_name | textFormat}} {{user.entity.last_name | textFormat}}</h5>
                    <h4 class="subtitle-18 color-dark-gray-300 pb-1" style="max-width: 480px;margin: 0 auto;">
                        {{$t('checkout.thank_you_for_trusting_us_your_order')}}
                        <router-link :to="employeeActiveCode ? `/account/quotes?state=facturada&q=${orderDocument}` : `/account/orders/${orderId}`">#{{orderDocument}}</router-link>
                        {{$t('checkout.will_arrive_soon')}}.
                    </h4>
                    <div class="d-flex justify-content-center mb-3 pt-4">
                        <router-link :to="employeeActiveCode ? `/account/quotes?state=facturada&q=${orderDocument}` : '/account/orders'" class="d-flex" tag="div">
                            <second-button :title="'account.track_order'" class="me-3" :icon="true" @click="$emit('closeOrderConfirmed')">
                                <icon-order :color="'var(--main-navy)'" :size="14" class="me-2"/>
                            </second-button>
                        </router-link>
                        <router-link to="/shop" class="d-flex">
                            <main-button class="main-btn" :title="'account.go_shipping'" :icon="true" @click="$emit('closeOrderConfirmed')">
                                <icon-search :color="'#fff'" :size="15" class="me-2"/>
                            </main-button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import Lottie from "vue-lottie";
import animationData from '../assets/animations/check-4';
// import ButtonConfirmed from './ButtonConfirmed.vue';
import SecondButton from '../../../common/components/buttons/SecondButton.vue';
import MainButton from '../../../common/components/buttons/MainButton.vue';
import IconSearch from '../../../common/svg/iconSearch.vue';
import IconOrder from '../../../common/svg/iconOrder.vue';
import IconClose from "../../../common/svg/iconClose.vue";
export default {
    name: "OrderConfirmed",
    components:{
        Lottie,
        SecondButton,
        MainButton,
        IconSearch,
        IconOrder,
        IconClose
    },
    data() {
        return {
            defaultOptions: { animationData: animationData, loop: true },
            loading: true,
            loadingCode: false,
            customerCode: null,
        }
    },
    computed:{
        ...mapGetters({
            user:"auth/userData",
            employeeActiveCode: 'auth/employeeActiveCode',
            employee: 'auth/employee',
        }),
    },
    props:{
        orderDocument:{
            type: String
        },
        orderId:{
            type: Number
        }
    },
    methods:{
        handleAnimation: function (anim) {
            this.anim = anim;
        },
    },
}
</script>
<style scoped>
    .loading{
        background: #f9faff;
        z-index: 100;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        position: fixed;
        margin: 0;
        align-items: center;
        justify-content: center;
    }
    .content{
        background: #fff;
        padding: 30px;
        border-radius: 7px;
        width: 600px;
    }
    .loading-wrapper {
        height: 60vh;
        width: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.main-btn:hover > span > div > svg{
        fill: white;
    }
</style>
