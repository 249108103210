<template>
    <div class="row justify-content-between mt-4" style="padding: 0% 4%;"
        v-if="(parseFloat(user.customer.credit_limit) > 0 && creditAvailable) && cashAvailable">
        <div class="justify-content-center btn-container col-md-6 col-xl-5" :class="[creditSelected.select ? 'container-disabled' : '']">
            <div class="text-center">
                <div @click="cashSelected.select = !cashSelected.select">
                    <radio-button :checked="cashSelected.select"/>
                    <icon-cash :size="120" class="mb-3"/>
                </div>
                <h6 class="m-0 weight-600">{{$t("checkout.cash")}}</h6>
                <span class="m-0 weight-300">{{$t("checkout.buy_and_pay_on_receipt")}}</span>
                <div class="mt-4 text-start">
                    <label class="mb-2 subtitle-15" id="cash">{{$t("checkout.enter_amount_to_pay")}}</label>
                    <div class="input-group">
                        <span class="input-group-text" id="cash"><icon-dollar :size="15" class="me-2" :color="'var(--dark-gray-200)'"/></span>
                        <input type="number" class="form-control" placeholder="RD$ 0.00" v-model="cashSelected.balance" aria-label="RD$ 0.00" aria-describedby="cash">
                    </div>
                    <div @click="fullPayCash">
                        <main-switch class="mt-3" :description="'checkout.pay_difference'" :id="'pay-full'" :value="cashSelected.fullPay" />
                    </div>
                </div>
            </div>
        </div>
        <div class="divider-cash col-xl-2 d-flex justify-content-center">
            <div style="border: 0.2px solid var(--dark-gray-200);opacity:0.3"></div>
        </div>
        <div class="justify-content-center btn-container col-md-6 col-xl-5" :class="[
            cashSelected.select || parseFloat(user.customer.credit_limit) <= orderConfirm.total_order? 'container-disabled':'', 
            {'cursor-nodrop':parseFloat(user.customer.credit_limit) <= orderConfirm.total_order}
        ]" >
            <div class="text-center" :class="{'pe-none':parseFloat(user.customer.credit_limit) <= orderConfirm.total_order}" 
            @click="creditSelected.select = !creditSelected.select">
                <div>
                    <radio-button :checked="creditSelected.select"/>
                    <icon-credit :size="120" class="mb-3"/>
                </div>
                <h6 class="m-0 weight-600">{{$t("checkout.credit_payment")}}</h6>
                <span class="m-0 weight-300">{{$t("checkout.buy_now_pay_later")}}!</span>
                    <div class="mt-4 text-start">
                        <label class="mb-2 subtitle-15" id="credit">{{$t("checkout.your_credit_limit_is")}} {{user.customer.credit_limit | moneyFormat }}</label>
                        <div class="input-group">
                            <span class="input-group-text" id="credit"><icon-dollar :size="15" class="me-2" :color="'var(--dark-gray-200)'"/></span>
                            <input type="number" class="form-control" placeholder="RD$ 0.00" v-model="creditSelected.balance" disabled aria-label="RD$ 0.00" aria-describedby="credit">
                        </div>
                    <div>
                </div>
                </div>
                <div class="d-flex justify-content-end">
                    <label class="mb-2 subtitle-15"  id="credit">{{$t("checkout.your_credit_limit_balance")}}
                        <span class="text-danger weight-600">{{user.customer.balance | moneyFormat }}</span>
                    </label>
                </div>
            </div>
        </div>
        <single-payment v-if="showNormalPayment" @closeModal="closeCreditAlert" @confirm="confirmCredit" :title="'checkout.single_payment'" :description="'checkout.msg_have_a_single_payment_method_selected'"/>
        <single-payment v-if="showSinglePayment" @closeModal="showSinglePayment = false" @confirm="confirmCash" :title="'checkout.single_payment'" :description="'checkout.msg_have_a_single_payment_method_selected'"/>
    </div>
    <div class="row mt-4"  v-else-if="cashAvailable">
        <div class="col-md-6 d-flex align-items-center">
            <radio-button class="me-3" :checked="cashSelected.select" @click.native="cashSelected.select = !cashSelected.select"/>
            <div class="d-flex cash-card">
                    <div class="d-flex align-items-center" @click="cashSelected.select = !cashSelected.select">
                    <icon-cash :size="80" class="me-3"/>
                        <div class="d-flex flex-column">
                            <h6 class="m-0 weight-600">{{$t("checkout.cash")}}</h6>
                            <span class="m-0 weight-300">{{$t("checkout.buy_and_pay_on_receipt")}}</span>
                        </div>
                    </div>
            </div>
        </div>
        <div class="col-md-6 justify-content-center d-flex flex-column animation-slide-left mt-3 mt-md-0">
            <div>
                <label class="mb-2 subtitle-15" id="cash-2">{{$t("checkout.enter_amount_to_pay")}}</label>
                <div class="input-group" @click="(cashSelected.select = true)">
                    <span class="input-group-text" id="cash-2"><icon-dollar :size="15" class="me-2" :color="'var(--dark-gray-200)'"/></span>
                    <input type="number" class="form-control" placeholder="RD$ 0.00" v-model="cashSelected.balance" aria-label="RD$ 0.00" aria-describedby="cash-2">
                </div>
            </div>
            <main-switch class="mt-3" :description="'checkout.pay_difference'" :id="'pay-full'" :value="cashSelected.fullPay" @click.native="fullPayCash"/>
        </div>
        <!-- <all-paid v-if="showAllPaid && cashSelected.balance == 0" @closeModal="(showAllPaid = false)" @confirm="confirmCash"/> -->
        <single-payment v-if="showSinglePayment" @closeModal="showSinglePayment = false" @confirm="confirmCash" :title="'checkout.single_payment'" :description="'checkout.msg_have_a_single_payment_method_selected'"/>
    </div>
    <div class="row mt-4" v-else>
        <div class="col-md-6 d-flex align-items-center">
            <radio-button class="me-3" :checked="creditSelected.select"/>
            <div class="d-flex align-items-center cash-card" @click="creditSelected.select = !creditSelected.select">
                <icon-credit :size="80" class="me-3"/>
                <div class="d-flex flex-column">
                    <h6 class="m-0 weight-600">{{$t("checkout.credit")}}</h6>
                    <span class="m-0 weight-300 size-14">{{$t("checkout.buy_now_pay_later")}}</span>
                </div>
            </div>
        </div>
        <div class="col-md-6 justify-content-center d-flex flex-column animation-slide-left mt-3 mt-md-0">
            <div>
                <label class="mb-2 subtitle-15" id="cash-2">{{$t("checkout.enter_amount_to_pay")}}</label>
                <div class="input-group" @click="(creditSelected.select = true)">
                    <span class="input-group-text" id="cash-2"><icon-dollar :size="15" class="me-2" :color="'var(--dark-gray-200)'"/></span>
                    <input type="number" class="form-control" placeholder="RD$ 0.00" v-model="creditSelected.balance" disabled>
                </div>
            </div>
            <main-switch class="mt-3" :description="'checkout.pay_difference'" :id="'pay-full'" :value="creditSelected.select" @click.native="creditSelected.select = !creditSelected.select"/>
        </div>
    </div>
</template>

<script>
import RadioButton from '../../../../common/components/radio/RadioButton.vue'
import MainSwitch from '../../../../common/components/switch/MainSwitch.vue'
import IconCash from '../../assets/svg/iconCash.vue'
import { mapGetters } from "vuex"
import IconDollar from '../../assets/svg/iconDollar.vue'
import SinglePayment from '../modals/SinglePayment.vue'
import IconCredit from '../../assets/svg/iconCredit.vue'
// import AllPaid from '../modals/AllPaid.vue'

export default {
    name:"Cash",
    components:{RadioButton, IconCash, MainSwitch, IconDollar, SinglePayment,IconCredit, 
    // AllPaid
    },
    data(){
            return {
                amount: null,
                fullPay: false,
                fullPayCredit: false,
                showSinglePayment: false,
                showNormalPayment: false,
                showAllPaid: false 
        }
    },
    computed:{
        ...mapGetters({
                cashSelected: "checkout/cashSelected",
                difference: "checkout/difference",
                user: "auth/userData",
                creditSelected: "checkout/creditSelected",
                singlePaymentActive: "checkout/singlePaymentActive",
                stripeSelected: 'checkout/stripeSelected',
                normalPayments: "checkout/normalPayments",
                amountSelected: "checkout/amountSelected",
                paymentsSelected:"checkout/paymentsSelected",
                configurations:"common/configurations",
                orderConfirm: "checkout/orderConfirm",

        }),
        cashAvailable(){
            const cash = this.configurations.payment_methods.cash;
            const userFinal = this.user.customer.price_level_id == 1;

            if(cash.active){
                if(userFinal) return cash.final;
                else return cash.tienda;
            }else return false;
        },
        creditAvailable(){
            const credit = this.configurations.payment_methods.credit;
            const userFinal = this.user.customer.price_level_id == 1;

            if(credit.active){
                if(userFinal) return credit.final;
                else return credit.tienda;
            }else return false;
        }
    },
    watch:{
        'cashSelected.balance'(val){
            this.cashSelected.balance = parseFloat(val)
            if(parseFloat(this.difference) <= 0){
                this.cashSelected.balance = 0
                this.cashSelected.balance = parseFloat(this.difference)
            }
            
            localStorage.setItem('paymentsSelected', JSON.stringify(this.paymentsSelected));   
        },
        'cashSelected.select'(val){
            // this.$store.commit("checkout/RESET_PAYMENTS")
            this.stripeSelected.select = false
            this.stripeSelected.balance = 0
            if(val){
                if(this.singlePaymentActive || this.creditSelected.select){
                    this.cashSelected.select = false
                    this.showSinglePayment = true
                    return
                }
                if(this.difference == 0 && this.cashSelected.balance > 0){
                    this.showAllPaid = true
                    return
                }
            }
            else{
                this.amount = null
                this.$store.commit("checkout/RESET_CASH")
            }
        },
        'creditSelected.select'(val){
            if(val){
                this.alertCredit()
                if(this.singlePaymentActive && !this.creditSelected.select || this.normalPayments){
                    this.showNormalPayment = true
                    return
                }
                this.cashSelected.fullPay = false
                this.$store.commit("checkout/RESET_PAYMENTS")
                this.$store.commit("checkout/SET_CREDIT", this.orderConfirm.total_order)
            }else{
                this.creditSelected.balance = 0
            }
        }
    },
    methods:{
        alertCredit(){
            const value1  = this.user.customer.balance
            const value2 =  this.orderConfirm.total_order
            const resutValue = +value1 + +value2
            const value3 = this.user.customer.credit_limit
            const finalResult = value3 > resutValue
            if(finalResult == false){
                this.creditSelected.select = false
                this.$store.commit('common/SHOW_MODAL_ERROR', {
                    show: true,
                    title: `checkout.notice`,
                    description: 'checkout.exceeded_limit'
                })
                this.$store.commit("checkout/RESET_PAYMENTS")
            }
        },
        confirmCash(){
            this.$store.commit("checkout/RESET_PAYMENTS")
            this.cashSelected.select = true
            this.showSinglePayment = false
            this.showAllPaid = false
            this.cashSelected.balance = this.orderConfirm.total_order;
        },
        confirmCredit(){
            this.$store.commit("checkout/RESET_PAYMENTS")
            this.creditSelected.select = true
            this.alertCredit()
            this.$store.commit("checkout/SET_CREDIT",this.orderConfirm.total_order)
            this.fullPayCredit = true
            this.showNormalPayment = false
        },
        fullPayCash(){
            this.stripeSelected.select = false
            this.stripeSelected.balance = 0
            this.cashSelected.fullPay = !this.cashSelected.fullPay
            if(this.cashSelected.fullPay){
                if(this.singlePaymentActive){
                    this.cashSelected.select = false
                    this.showSinglePayment = true
                    return
                }
                if(this.difference === 0){
                    console.log("todo p")
                }else{
                    this.cashSelected.balance = 0
                    this.cashSelected.balance = parseFloat(this.difference)
                    this.cashSelected.select = true
                }
            }else{
                this.cashSelected.balance = 0
            }
        },
        closeCreditAlert(){
            this.showSinglePayment = false
            this.creditSelected.fullPay = false
            this.creditSelected.select = false
            this.showNormalPayment = false
        },
    },
    created(){
        if(this.cashSelected.balance > 0){
            this.amount = this.cashSelected.balance
        }
    }
}
</script>

<style scoped>
    label{
        font-size: 15px;
        color: var(--main-navy); 
        font-weight: 600;
    }
    .cash-card{
        padding: 15px 30px;
        border: 1px solid rgba(92, 124, 136, 0.2);
        border-radius: 10px;
    }
    @media (max-width: 1200px) {
        .divider-cash{
            display: none !important;
        } 
    }
    .container-disabled{
        opacity: 0.5;
    }
    .btn-container{
        cursor: pointer;
    }
    .form-control:disabled{
        background: transparent;
    }
    .form-control{
        padding: 0.1rem 3px;
    }
    .input-group-text{
        background: transparent;
        border-color: var(--gray-100) !important;
        border-radius: 7px;
        padding-right: 0px;
        border-right: 0;
    }
    input::placeholder{
        font-size: 14px;
    }
    input:focus{
        box-shadow: none;
    }
    input{
        height: 38px !important;
        border-color: var(--gray-100) !important;
        border-radius: 7px;
        border: 1px solid;
        width: 100%;
        font-size: 14px;
        border-left: 0;
        background-color: none  !important;
    }
</style>
