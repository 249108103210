<template>
    <main-confirm @confirm="$emit('closeModal')" @cancel="$emit('closeModal')" confirm-title="checkout.option_not_available" description="checkout.description_option_not_available" :oneOption="true">
        <icon-warning :size="60"/>
    </main-confirm>
</template>
<script>
import MainConfirm from '../../../../common/components/modals/MainConfirm.vue'
import IconWarning from '../../../../common/svg/iconWarning.vue'
export default {
    components: { MainConfirm, IconWarning},
    name: "DirectionNotAllowed"
}
</script>