<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
            <path class="st0" d="M108.2,94.2c6.7,0,12.1-5.4,12.1-12.1v-2.9L73.8,32.7H37.7c-20.6,0-37.2,16.7-37.2,37.2v24.3H108.2z"/>
            <path class="st0" d="M126.2,79.2v2.9c0,9.9-8.1,18-18,18H95.1v50.3h60v-38.2c0-9.9,8.1-18,18-18h76.4V69.9   c0-20.6-16.7-37.2-37.2-37.2h-39.6L126.2,79.2z"/>
            <path class="st0" d="M161,112.2v38.2h88.5v-50.3h-76.4C166.4,100.1,161,105.5,161,112.2z"/>
            <polygon class="st0" points="82.1,32.7 123.3,73.8 164.4,32.7  "/>
            <polygon class="st0" points="159.9,217.3 125.1,182.4 90.2,217.3  "/>
            <path class="st0" d="M128,156.2V177l40.3,40.3h44c20.6,0,37.2-16.7,37.2-37.2v-23.8H128V156.2z"/>
            <path class="st0" d="M122.1,177v-20.8h-27h-2.9H0.5V180c0,20.6,16.7,37.2,37.2,37.2h44.2L122.1,177z"/>
            <rect x="0.5" y="100.1" class="st0" width="88.8" height="50.3"/>
        </g>
    </icon-base>
</template>
<script>

export default {
name: 'iconChip',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>
