<template>
    <div>
        <div class="d-flex justify-content-between mb-4 px-md-4">
            <div class="d-flex align-items-start">
                <icon-cart :size="25" class="me-2"/>
                <div>
                    <h3 class="m-0 title-20 weight-400 d-flex align-items-center">  {{$t("checkout.my_cart")}}</h3>
                    <span class="m-0 mt-3 subtitle-16 color-dark-gray-300">
                        {{branchSelected.description | textFormat}}
                        <span class="ms-4 color-main-navy">({{products.length}})</span>
                        {{$t("checkout.articles")}}
                    </span>
                </div>
            </div>
            <div class="main-link edit-btn d-flex align-items-center ms-1">
                <a href="/account/invoice" v-print="'print-invoice'" @click="print(print)" target="_blank" class="me-2" id="btn-print">
                    <iconPrint :color="'var(--blue-600)'" class="me-2" :size="15"/>
                    {{$t("checkout.print")}}
                </a>
            </div>
            <div v-if="this.user.entity.activated_employee == false" class="d-flex align-items-center position-relative">
                <a @click.prevent="addNoteCheckout = true" class="add-note-btn d-flex align-items-center main-link" style="height: 22px;">
                    <icon-edit :size="15" class="me-2" color="var(--blue-600)" />
                    <span class="size-14">{{$t("product.add_note")}}</span>
                    <half-circle-spinner v-if="loadingNote" :animation-duration="1000" :size="20" color="var(--blue-600)" class="d-inline-block ms-2" />
                </a>
                <popup v-if="addNoteCheckout" id="add-note-modal" :style="{top: `35px`, left: `-8px`, width: `330px`, padding: `20px`}">
                    <h5 class="title-16 mb-0 weight-700 d-flex justify-content-between">
                        {{$t('product.add_note')}}
                    </h5>
                    <div class="d-flex justify-content-between size-14">
                        <span class="color-dark-gray-300">{{charactersLeft > 0 ? $t('cart.press_enter_to_save') : $t('cart.let_us_know_any_information')}}</span>
                        <div>
                            <span class="color-dark-gray-200 weight-100 me-2">{{charactersLeft}} / 150</span>
                            <a href="#" v-if="charactersLeft > 0" @click.prevent.stop="note = ''" class="weight-500 size-14">{{$t('cart.clear')}}</a>
                        </div>
                    </div>
                    <textarea v-model="note" id="review" @keypress.enter="addNoteCheckout = false" class="w-100 mt-2 px-3 py-1"
                    :placeholder="$t('product.report_textarea_placeholder')" name="review" rows="3" cols="50" maxlength="150"/>
                </popup>
            </div>
            <a href="#" class="main-link edit-btn d-flex align-items-center" @click.prevent="goToCart">
                <icon-edit :color="'var(--blue-600)'" class="me-2" :size="15"/>
                <span>{{$t("checkout.edit")}}</span>
            </a>
        </div>
        <div class="table-responsive d-none d-md-block d-lg-block" style="max-height: 587px" :class="{'pe-3': products.length > 3}">
        <hr class="my-0">
            <table class="table table-hover mb-0">
                <thead style="border-top: 2px solid trasnparent;">
                <tr>
                    <th scope="col">{{$t("checkout.product_details")}}</th>
                    <th scope="col" class="text-center">{{$t("checkout.quantity")}}</th>
                    <th scope="col" class="text-center">{{$t("checkout.total")}}</th>
                </tr>
                </thead>
            <tbody>
                <tr v-for="{product, total_amount} in products" :key="product.codarticulo">
                    <td class="subtitle-14" style="width: 0;">
                        <product-short-details :product="product" class="p-2" :confirmation="true" @showQuickView="showQuickView" v-if="product.codarticulo != configurations.delivery_options.local_delivery.code && product.codarticulo != configurations.delivery_options.nacional_delivery.code"/>
                        <div class="d-flex align-items-center p-2" v-else>
                            <div class="icon-shipping d-flex overflow-hidden me-3">
                                <img src="https://img.freepik.com/premium-photo/photo-portrait-black-skinned-girl-motorcycle-holding-big-red-heart-postcard-two-hands-isolated-vivid-yellow-colored-background_274222-38771.jpg?w=1380" alt="delivery-icon"
                                class="w-100" style="object-fit: cover;"
                                v-if="checkoutSteps.addressOptionSelected === 1">
                                <icon-national :size="120" v-else/>
                            </div>
                            <div class="text-start">
                                <h6 class="text-limited title-16 product-description cursor-pointer">
                                    {{checkoutSteps.addressOptionSelected === 1? $t('checkout.local_messaging') : $t('checkout.national_shipping')}}
                                </h6>
                                <star-rating :starSize="17" :rating="5" :showRating="false" style="margin-top: -3px;" :readOnly="true"/>
                                <span class="text-start subtitle-15 mt-0 mt-md-2">
                                    {{$t("product.price")}}
                                    <b class="weight-600">{{total_amount | moneyFormat}}</b>  
                                </span>
                            </div>
                        </div>
                    </td>
                    <td class="subtitle-16 weight-600">{{product.quantity}}</td>
                    <td class="subtitle-16">
                        <span class="text-start subtitle-16 mt-0 mt-md-2">
                            <b class="color-main-navy ms-2">{{showValueMoney()}}$</b>
                            <b class="color-light-main-red weight-600 "> {{total_amount | moneyFormat(0, false)}}</b>  
                        </span>
                    </td>
                </tr>
            </tbody>
            </table>
        </div>
        <div v-for="{product, total_amount} in products" :key="product.codarticulo" class="d-block d-md-none">
            <hr>
            <product-short-details :product="product" @showQuickView="showQuickView" :confirmation="true" v-if="product.codarticulo != configurations.delivery_options.local_delivery.code && product.codarticulo != configurations.delivery_options.nacional_delivery.code"/>
            <div class="d-flex" v-else>
                <div class="icon-shipping me-3">
                    <icon-delivery :size="120" v-if="checkoutSteps.addressOptionSelected === 1"/>
                    <icon-national :size="120" v-else/>
                </div>
                <div class="d-flex flex-column justify-content-between w-100">
                    <h6 class="text-limited title-16 product-description cursor-pointer">
                        {{checkoutSteps.addressOptionSelected === 1? $t('checkout.local_messaging') : $t('checkout.national_shipping')}}
                    </h6>
                    <div class="mt-auto d-flex">
                        <span class="subtitle-14">{{$t("product.quantity")}} 1</span>
                        <span class="text-start subtitle-14 mt-0 mt-md-2">
                            <b class="color-main-navy ms-2">{{showValueMoney()}}$</b>
                            <b class="color-light-main-red weight-600 "> {{parseFloat(total_amount).toFixed(2)}}</b>  
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <quick-view :product="quickView" :checkout="true" v-if="show" @closeModal="show = false"/>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
// import { HalfCircleSpinner } from 'epic-spinners'
import iconPrint from "../../../cart/assets/svg/iconPrint.vue";
import Popup from '../../../../common/components/popup/Popup.vue'
import ProductShortDetails from '../../../cart/components/ProductShortDetails.vue'
// import { orderService } from "../../account/services/order-service";
import IconEdit from '../../assets/svg/iconEdit.vue'
import { productMixin } from "../../../products/helpers/mixin/product-mixin";
import { cartMixin } from "../../../cart/helpers/mixins/cart-mixin";
import { quotesMixin } from "../../../account/helpers/mixins/quotes-mixin";
import IconCart from '../../../../common/svg/iconCart.vue';
import IconDelivery from '../../../checkout/assets/svg/iconDelivery.vue'
import IconNational from '../../../checkout/assets/svg/iconNational.vue'
import QuickView from '../../../products/components/quick-view/QuickView.vue';
import StarRating from '../../../products/components/rating/StarRating.vue';
export default {
    name: "CheckoutProductDetails",
    components:{
        Popup,
        iconPrint,
        IconEdit,
        ProductShortDetails,
        IconCart,
        IconDelivery,
        IconNational,
        QuickView,
        StarRating,
        // HalfCircleSpinner

    },
    computed:{
        ...mapGetters({
            userData: "auth/userData",
            branchSelected: "branches/branchSelected",
            products: "cart/getProducts",
            amount: "cart/amount",
            checkoutSteps: "checkout/checkoutSteps",
            addressSelectedAvailable: "account/addressSelectedAvailable",
            configurations: "common/configurations",
            selectedQuotation: "account/selectedQuotation",
            changedQuotes: "account/changedQuotes",
            quotes: "account/quotes",
            orderConfirm: 'checkout/orderConfirm',
            amountSelected: "checkout/amountSelected",
            partnerSelected: 'checkout/partnerSelected',
        }),
        charactersLeft() {
            var char = this.note.length,
                limit = 0;
            return (limit + char)
        }
    },
    mixins: [productMixin, quotesMixin, cartMixin],
    data() {
        return {
            addNoteCheckout: false,
            quickView: null,
            show: false,
            noteModalCounter: null,
            note: '',
            oldNote: '',
        }
    },
    watch: {
        note(val) {
            this.$store.commit("checkout/SET_NOTE", val);
        },
        addNoteCheckout(val){
            if(val) { //setter
                this.oldNote = this.note;
                this.noteModalCounter = setTimeout(() => {
                    document.body.addEventListener('click', this.handleOutsideNoteClick);
                }, 100);
            }else {
                document.body.removeEventListener('click', this.handleOutsideNoteClick);
            }
        }
    },
    methods:{
        goToCart(){
            this.$store.commit("checkout/RESET_STATE")
            this.$store.commit("checkout/RESET_STRIPE_ELEMENTS")
            this.$store.commit("checkout/SET_DELIVERY_QUOTATION", null)
            this.$router.push("/cart")
            this.$store.dispatch('cart/getCartByBranch', {codplace: this.branchSelected.codplace}); 
        },
        handleOutsideNoteClick(evt){
            let el = document.getElementById('add-note-modal');
            if (!(el == evt.target || el.contains(evt.target))) {
                this.addNoteCheckout = false;
            }
        },
        getDate() {
            var d = new Date(),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-');
        },
        showShare(product){
            this.share = true
            this.shareProduct = product;
        },
        async print() {
                let products = []
                let d = new Date();
                let hour = d.getHours()+":"+d.getMinutes()+":"+d.getSeconds();
                // console.log(products, 'aqui productos');
                for (let i = 0; i < this.products.length; i ++) {
                    const product = this.products[i];
                    products.push({amount: product.total_amount,codarticulo: product.product.codarticulo,product: product.product, price_sold: product.product.price,
                    quantity: product.quantity})
                }

                let order = {
                    codplace : this.branchSelected.codplace,
                    order_primary: {
                        order_document_id: "XXXXXXXXXX",
                        amount: this.orderConfirm.total_order,
                        payments: [
                            {type: "No pagado"}
                        ],
                        delivery_amount: 0,
                    },
                    option_id: 3,
                    amount_sub: this.orderConfirm.total_order_without_taxes,
                    amount: this.orderConfirm.total_order,
                    order_details: products,
                    order_document_id: "XXXXXXXXXX",
                    created_at: this.getDate()+'T'+hour,
                    amount_tax: this.orderConfirm.total_order_taxes,
                    customer_name: this.partnerSelected.full_name,
                    customer_phone: this.partnerSelected.whatsapp
                }
                this.$store.commit('account/SET_INVOICE', order)
                // this.$router.push({path: '/account/invoice'})
        },
        showQuickView(product){
            this.quickView = product
            this.show = true
        }
    },
    destroyed() {
        clearTimeout(this.noteModalCounter);
        document.body.removeEventListener('click', this.handleOutsideNoteClick);
    },
}
</script>

<style scoped>
    td {
        vertical-align: middle;
        text-align: center;
    }
    .popup::v-deep::before {
        top: 25px;
        left: 50%;
        transform: translate(-50%, -50%) rotate(134deg);
        width: 12px;
        height: 12px;
    }

    textarea:focus-visible{
        outline: none;
    }
    textarea {
        border-color: #cedde9 !important;
        border-radius: 7px !important;
        font-size: 14px;
    }
    textarea::placeholder{
        color: var(--dark-gray-200);
        font-weight: 100;
        font-size: 14px;
    }
    .popup-add-note.popup::v-deep::before{
        right: 28px;
        top: 0px;
        transform: translate(50%, -50%) rotate(316deg);
        width: 20px;
        height: 20px;
    }


.add-note-btn > svg {
    transition: all .05s ease-in-out 
}

.add-note-btn > span {
    color: var(--blue--600);
    transition: var(--transition-1);
}
.add-note-btn:hover > span {
    color: var(--main-red)
}
    .icon-shipping{
        border: 1px solid var(--light-gray-100);
        border-radius: 7px;
        width: 125px;
        height: 125px;
    }
    .edit-btn > svg {
        transition: all .05s ease-in-out
    }
    .edit-btn:hover > svg {
        fill: var(--main-red) !important;
    }
    .edit-btn:hover span {
        color: var(--main-red) !important;
    }
    .edit-btn span{
        transition: var(--transition-1);
        color: var(--blue-600);
    }
    .table::v-deep > :not(:first-child) {
        border-top: 0;
    }
</style>
