<template>
    <div>
        <div class="col-sm-12 position-relative user-select-none" id="container-payment-collapse" @click.prevent="openModule($event)">
            <div class="payment-collapse" :class="!collapse ? 'PaymentHover':''" id="payment-collapse" :style="pointerCollapseConditions ? 'border-color: #4ecb14' : ''">
                <div class="row" style="cursor:pointer" id="collapse" @click="openModule($event, 'noEvent')">
                    <div class="col-12 col-lg-12 d-flex flex-column justify-content-center">
                            <div class="d-flex flex-column">
                                <div class="d-flex justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <!-- <div v-if="value == 4 && paymentsSelected.cardSelected.card != null" class="d-flex align-items-center me-2"> -->
                                            <!-- <icon-circle :size="6" class="me-1"/> -->
                                            <!-- <icon-check-circle v-if="value == 4 && paymentsSelected.cardSelected.card != null"  :size="25"  :color="'#4ecb14'" class="d-flex align-items-center me-2" /> -->
                                            <!-- {{ 'Método seleccionado' }} -->
                                        <!-- </div> -->
                                        <!-- <radio-button v-if="paypal && collapse" class="d-flex align-items-center me-2" :checked="paypalSelected.balance > 0"/> -->
                                        <!-- <radio-button class="d-flex align-items-center me-2" :checked="stripeSelected.select" v-if="value == 7 && stripeSelected.select"/>
                                        <radio-button class="d-flex align-items-center me-2" :checked="cardSelected.detailts !== null" v-if="value == 4 && cardSelected.detailts !== null"/> -->
                                        <div>
                                            <icon-check-circle v-if="pointerCollapseConditions "  :size="25"  :color="'#4ecb14'" class="d-flex align-items-center me-2 mt-2"/>
                                        </div>
                                        <h5 class="m-0 title-18">
                                            {{ $t(title) }}
                                            <span v-if="paypalSelected.balance > 0 && paypal" class="color-main-red ms-2">
                                                US$ 
                                                <b class="color-main-navy" v-if="orderConfirm">{{(orderConfirm.total_order / currency.taxe_amount) | moneyFormat(0, false)}}</b>
                                            </span>
                                        </h5>
                                    </div>
                                    <div>
                                        <icon-arrow class="iconArrow" :class="{'': !collapse, 'iconRotate' :collapse }"/>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between">
                                    <span :style="pointerCollapseConditions ? 'margin-left: 34px; margin-top: -8px;' : ''" class="subtitle-15 color-dark-gray-300 d-flex" :class="{'d-flex': paypal}">{{$t(subtitle)}}.</span>
                                    <div v-if="deposits && collapse" class="d-flex">
                                        <div class="d-flex align-items-center me-3">
                                            <icon-circle :size="14" :color="'var(--green-600)'" class="me-2"/>
                                            <h6 class="m-0 subtitle-15 text-nowrap">{{$t("checkout.available_deposits")}}</h6>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <icon-circle :size="14" :color="'var(--blue-600)'" class="me-2"/>
                                            <h6 class="m-0 subtitle-15 text-nowrap">{{$t("checkout.credit_note")}}</h6>
                                        </div>
                                    </div>
                                    <icon-paypal :size="200" v-if="paypal && collapse" class="col-md-3 position-absolute d-none d-sm-block" style="right: 7%;top: 26%;"/>
                                </div>
                            </div>
                    </div>
                </div>
                <div v-show="collapse" class="col-sm-12 divAnimation" v-if="!paypal">
                        <slot></slot>
                </div>
            </div>
        </div>
        <single-payment v-if="showSinglePayment" @closeModal="showSinglePayment = false" @confirm="confirm" :title="'checkout.single_payment'" :description="'checkout.this_payment_method_is_unique'"/>
    </div>
</template>
<script>
// import RadioButton from '../../../../common/components/radio/RadioButton.vue'
import iconArrow from '../../../../common/svg/iconArrow.vue'
import IconPaypal from '../../assets/svg/iconPaypal.vue'
import { mapGetters } from "vuex"
import SinglePayment from '../modals/SinglePayment.vue'
import IconCircle from '../../../../common/svg/iconCircle.vue'
import iconCheckCircle from '../../../account/assets/svg/iconCheckCircle.vue'

export default {
    components: { 
        iconArrow, 
        // RadioButton, 
        IconPaypal, 
        SinglePayment, 
        IconCircle, 
        iconCheckCircle 
    },
    name: "PaymentCollapse",
    data() {
        return {
            collapse: false,
            showWarning:false,
            paypalModel: false,
            openPaypal: false,
            showSinglePayment: false
        }
    },
    props: {
        title:{
            type: String,
            require: true
        },
        value:{
            type: Number,
        },
        isDeposits:{
            type: Boolean,
        },
        subtitle:{
            type: String,
            require: true
        },
        disabledModule:{
            type: Boolean
        },
        amount:{
            type: Number,
            require: true
        },
        paypal:{
            type: Boolean,
            default: false
        },
        deposits:{
            type: Boolean,
            default: false
        }
    },
    computed:{
        ...mapGetters({
            cashSelected: "checkout/cashSelected",
            creditSelected: "checkout/creditSelected",
            cardSelected: "checkout/cardSelected",
            depositExpressSelected: "checkout/depositExpressSelected",
            paymentsSelected: "checkout/paymentsSelected",
            paypalSelected:"checkout/paypalSelected",
            singlePaymentActive: "checkout/singlePaymentActive",
            normalPayments: "checkout/normalPayments",
            orderConfirm: "checkout/orderConfirm",
            employeeActiveCode: 'auth/employeeActiveCode',
            difference: "checkout/difference",
            user: "auth/userData",
            checkoutSteps: "checkout/checkoutSteps",
            currency: "checkout/currency",
            stripeSelected: 'checkout/stripeSelected',
            cardSelectedDetails: 'checkout/paymentsSelected'
        }),
        pointerCollapseConditions(){
            return this.value == 4 && this.paymentsSelected.cardSelected.card != null || this.paypal && this.collapse || this.value == 1 && this.cashSelected.select || this.value == 1 && this.creditSelected.select || this.value == 3  && this.depositExpressSelected.images.length > 0 
        }
    },
    watch:{
        'cardSelectedDetails.cardSelected'(){
            if(this.cardSelectedDetails.cardSelected.detailts != null){
                this.collapse = false
            }
        },
        collapse(val){
            this.$emit("changeState", val)
            if(this.paypal){
                this.paypalModel = val
            }
        },
        paypalSelected(val){
            if(val.balance === 0 && this.paypal)
            this.collapse = false
        },
        paypalModel(val){            
            if(val){
                if(this.cashSelected.select || this.cardSelected.balance > 0){
                    this.paypalModel = false
                    this.showSinglePayment = true
                    this.$emit("paypal")
                    return
                }else{                    
                    this.$store.commit("checkout/RESET_PAYMENTS")
                    const data = {
                        data: null,
                        balance: this.orderConfirm.total_order
                    }
                    this.$store.commit("checkout/SET_PAYPAL_SELECTED", data);
                }
            }else{
                const data = {
                    data: null,
                    balance: 0
                }
                this.$store.commit("checkout/SET_PAYPAL_SELECTED", data);
            }
        },
    },
    methods:{
        openModule(event, condition) {
            if(this.disabledModule){
                this.showWarning = true
            }else if(this.paypal && (this.paypalSelected === 0 && this.singlePaymentActive || this.normalPayments)){
                this.showSinglePayment = true
            }else{
                if(condition === "noEvent"){
                    this.collapse = !this.collapse
                    return
                }
                if(event.target.id === "container-payment-collapse" || event.target.id === "payment-collapse" || event.target.id === "collapse")
                this.collapse = !this.collapse
                if (this.value == 7) {
                    this.$store.commit("checkout/RESET_PAYMENTS")
                    this.stripeSelected.select = this.collapse;
                    this.stripeSelected.balance = this.stripeSelected.select ? this.orderConfirm.total_order : 0;
                }
            }
        },
        confirm(){
            this.$store.commit("checkout/RESET_PAYMENTS")
            this.paypalModel = true
            this.collapse = true
            let data = {
                data: null,
                balance: this.orderConfirm.total_order
            }
            this.$store.commit("checkout/SET_PAYPAL_SELECTED", data);
            this.showSinglePayment = false
        },
    },
}
</script>

<style scoped>
    .PaymentHover{
        transition: var(--transition-1);
    }
    .PaymentHover:hover{
        border-color: #ffffff00 !important;
        cursor: pointer;
        -webkit-animation: 1s ease-out 0.4s;
        animation: 1s ease-out 0.4s;
        box-shadow: 0px 0px 6px 0px rgb(144 157 164 / 13%);
    }
    .payment-collapse:hover > #collapse > div > div > div > div {
        fill: var(--main-red);
    }
    .PaymentHover:hover > .col1 > .col-lg-11 > div > div > .title {
        color: var(--main-red);
    }
    .PaymentHover:hover > .col1 > .col-lg-11 > div > div > span{
        color: var(--main-red) !important;
    }
    .iconRotate {
        transform: rotate(270deg) !important;
    }
    .iconArrow{
        transform: rotate(90deg);
        transition: var(--transition-1);
    }
    .payment-collapse{
        border: 2px solid rgba(92, 124, 136, 0.199); 
        border-radius: 20px;
        padding: 4%;
    }
    .status-bg{
        width: fit-content;
        white-space: nowrap; 
        border-radius: 20px;
        font-size: 14px;
        background: #cffae2;
        color: #4ecb14;
        /* background: #fad8cf;
        color: #db3535; */
    }
</style>
