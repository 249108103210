<template>
<div class="d-flex flex-column justify-content-center  py-2" :class="[confirmation? 'align-items-start' : 'align-items-center']">
    <span class="title-14 mb-0">{{$t("checkout.purchase_with_tax_value")}}</span>
    <div class="d-flex justify-content-between w-100">
        <span class="me-2 subtitle-14 weight-300 text-limited">{{rnc['Nombre Comercial']}}</span>
        <span class="ms-2 subtitle-14 weight-300 text-limited">{{'RNC ' + rnc['Cédula/RNC']}}</span>
    </div>
    <div class="mt-2 mb-1">
        <a href="#" @click.prevent="$emit('change')" class="option">{{$t("checkout.change")}}</a>
            <span class="me-2 ms-2 color-dark-gray-200">|</span>
        <a href="#" @click.prevent="removeRnc" class="option">{{$t("checkout.remove")}}</a>
    </div>
</div>
</template>

<script>
export default {
    name: "TaxReceiptInformation",
    props:{
        rnc:{
            type: Object
        },
        confirmation:{
            type: Boolean,
            default: false
        }
    },
    methods:{
        removeRnc(){
            this.$store.commit('checkout/SET_RNC', null)
        },
    }
}
</script>

<style>
    .option{
        font-size: 13px;
    }
</style>