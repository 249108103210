<template>
  <div>
    <div v-if="!this.configurations.payment_methods.stripe_backend_implementation.active" class="card-form row">
        <div class="card-container d-md-none col-sm-12 col-md-6 mt-3 mt-md-1">
          <card :key="0" :isCardFlipped="isCardFlipped" :fields="fields" :labels="form" :isCardNumberMasked="isCardNumberMasked" :randomBackgrounds="randomBackgrounds" 
          @typeCard="typeCard" class="mt-0 mt-md-5 mx-auto" background="white" :backgroundImage="backgroundImage" isForm />
        </div>
        <div class="col-md-6">
          <form class="row mx-0" action="" @submit.prevent="">
              <main-input :maxCharacterLength="60" :max="60" :title="'checkout.name_of_owner'" id="name" :placeholder="'account.name_on_card'" 
                ref="name" :value="form.cardName" @changeValue="(data)=>{form.cardName = data}" class="col-sm-12 mt-3" :class="{'px-0 pe-sm-2 pe-md-4': isAccount}">
                <icon-person :size="15" :color="'var(--dark-gray-200)'"/>
              </main-input>
              <main-input :title="'checkout.card_number'" id="number" :placeholder="'0000 0000 0000 0000'" ref="number" :mask="'#### #### #### ####'" :value="form.cardNumber" :customValidate="'card-number'"
              @changeValue="(data)=>{form.cardNumber = data}" @focus="focusCardNumber" @blur="blurCardNumber" :min="19" class="col-sm-12 mt-3" :class="{'px-0 pe-sm-2 pe-md-4': isAccount}" 
              :cardBrand="form.cardbrand">
                <icon-card :size="18" :color="'var(--dark-gray-200)'"/>
              </main-input>
              <main-input title="checkout.expiration_date" id="expiration-date" :placeholder="'MM/YYYY'" class="date-input col-7 col-md-8 mt-3" ref="date" :mask="'##/####'"  :class="{'pe-0 pe-sm-2 pe-md-4 position-content': isAccount}" 
              :customValidate="'expiration-date'" :value="form.cardDate" @changeValue="(data)=>{form.cardDate = data}">
                <icon-calendar :size="15" :color="'var(--dark-gray-200)'"/>
              </main-input>
              <main-input :title="'CVC / CVV'" id="cvc" placeholder="***" class="cvc-input col-5 col-sm-4 mt-3" :class="[{'active' : !isCardFlipped && form.cardCvv}, {'ps-0': isAccount}]" :type="'number'" :mask="'NNN'" @input="changeCvv" ref="cvc" @focus="focusCardCvc" @blur="blurCardCvc" :value="form.cardCvv" @changeValue="(data)=>{form.cardCvv = data}">
                <icon-password :size="13" :color="'var(--dark-gray-200)'"/>
              </main-input>
            <slot />
            <div class="button-container col-sm-8 mt-3 d-flex justify-content-start" :class="{'px-0': isAccount}">
              <second-button v-if="updateData && !isAccount" :id="'cancel'" :title="'auth.cancel'" class="me-2" @click="cancelEdit" style="min-width: 100px;"/>
              <main-button class="position-relative overflow-hidden" v-if="!updateData" :id="'add'" :disabled="loading" :title="!isAccount ? 'checkout.use_card' : 'checkout.add_new_card'" icon @click="createCard">
                <div v-if="loading" class="confirm-loading position-absolute w-100 h-100 d-flex justify-content-center align-items-center bg-main-red">
                    <half-circle-spinner :animation-duration="1000" :size="20" color="#fff"/>
                </div>
              </main-button>
              <main-button v-else :id="'save'" title="checkout.save" @click="createCard" style="min-width: 100px;"/>
              <!-- <main-button v-if="!isAccount" :id="'payment_not_save_card'" :title="'Usar tarjeta'" style="min-width: 100px;" :class="'space_content'" @click="uniquePayment"/> -->
            </div>
          </form>
        </div>
        <div class="card-container d-none d-md-block col-sm-6 mt-3 mt-md-1">
          <card isForm :key="1" :isCardFlipped="isCardFlipped" :fields="fields" :labels="form" :isCardNumberMasked="isCardNumberMasked" :randomBackgrounds="randomBackgrounds" 
          @typeCard="typeCard" class="mt-0" background="white" :backgroundImage="backgroundImage" />
        </div>
    </div>
      <div style="margin-top: 25px" v-else>
          <div>
              <main-input :maxCharacterLength="60" :max="60" :title="'checkout.name_of_owner'" id="name" :placeholder="'account.name_on_card'" 
                ref="name" :value="form.cardName" @changeValue="(data)=>{form.cardName = data}" class="col-sm-12 mt-3" :class="{'px-0 pe-sm-2 pe-md-4': isAccount}">
                <icon-person :size="15" :color="'var(--dark-gray-200)'"/>
              </main-input>
              <br>
              <div style="margin-top: -6px;" id="payment-element">
                      <!-- Elements will create form elements here -->
              </div>
              <div class="button-container col-sm-8 mt-3 d-flex justify-content-start" :class="{'px-0': isAccount}">
                <main-button class="position-relative overflow-hidden" :title="!isAccount ? 'Use card' : 'Add card'" :id="'add'" :disabled="loading" icon @click="submit" style="max-width: 100px;">
                  <div v-if="loading" class="confirm-loading position-absolute w-100 h-100 d-flex justify-content-center align-items-center bg-main-red">
                      <half-circle-spinner :animation-duration="1000" :size="20" color="#fff"/>
                  </div>
                </main-button>
                <!-- <main-button v-if="!isAccount" :id="'payment_not_save_card'" :title="'Use card'" style="min-width: 100px;" :class="'space_content'" @click="uniquePaymentStripe"/> -->
              </div>
              <div id="error-message">
                  <!-- Display error message to your customers here -->
              </div>
          </div>
      </div>
  </div>
</template>


<script>
import Card from '../cards/Card.vue'
import { http } from "../../../../../common/services/web-backend";
import MainInput from '../../../../../common/components/inputs/MainInput.vue';
import IconPerson from '../../../../../common/svg/iconPerson.vue';
import MainButton from '../../../../../common/components/buttons/MainButton.vue';
import IconPassword from '../../../../auth/assets/svg/iconPassword.vue';
import IconCalendar from '../../../../../common/svg/iconCalendar.vue';
import IconCard from '../../../../../common/svg/iconCard.vue';
import { HalfCircleSpinner } from 'epic-spinners';
import SecondButton from '../../../../../common/components/buttons/SecondButton.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'CardForm', 
  props: {
    backgroundImage: [String, Object],
    randomBackgrounds: {
      type: Boolean,
      default: true
    },
    updateData: {
      type: Object,
      default: null
    },
    isAccount: {
      type: Boolean,
      default: false
    },
    default: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Card,
    MainInput,
    IconPerson,
    MainButton,
    IconPassword,
    IconCalendar,
    IconCard,
    SecondButton,
    HalfCircleSpinner
    // TestinCard
  },
  data () {
    return {
      fields: {
        cardNumber: 'v-card-number',
        cardName: 'v-card-name',
        // cardMonth: 'v-card-month',
        // cardYear: 'v-card-year',
        cardYear: 'v-card-data',
        cardCvv: 'v-card-cvv'
      },
      elements: null,
      minCardYear: new Date().getFullYear(),
      isCardNumberMasked: true,
      loading: false,
      isCardCvcMasked: true,
      mainCardNumber: this.cardNumber,
      mainCardCvc: this.cardCvv,
      cardNumberMaxLength: 19,
      isCardFlipped: false,
      DateToday: new Date(),
      form:{
        cardName: '',
        cardNumber: '',
        cardNumberNotMask: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: '',
        cardCvcNotMask: '',
        cardbrand: '',
        cardDate: '',
        defaultCard: false
      }
    }
  },
  computed: {
    ...mapGetters({
        userData: "auth/userData",
        getLocalCards: "account/cards",
        cardSelected: "checkout/cardSelected",
        orderConfirm: "checkout/orderConfirm",
        configurations: "common/configurations"
    }),
    minCardMonth () {
      if (this.form.cardYear === this.minCardYear) return new Date().getMonth() + 1
      return 1
    }
  },
  watch: {
    cardYear() {
      if (this.form.cardMonth < this.minCardMonth) {
        this.form.cardMonth = ''
      }
    },
    updateData(val){
      if(val){
        let month = 0
        if(val.expiry_month > 10){
          month = val.expiry_month
        }else{
          month = '0' + val.expiry_month
        }
          this.form.cardName = val.full_name
          this.form.cardNumber = val.card_number
          this.mainCardNumber = val.card_number
          this.form.cardDate = month + val.expiry_year.toString()
          this.form.cardMonth = val.expiry_month
          this.form.cardCvv = val.cvc
          this.mainCardCvc = val.cvc
        
        if(val.default){
          this.$emit('setDefault')
        }
        setTimeout(() => {
          this.maskCardNumber()
          this.maskCardCvc()
        }, 100);
      }
    },
    default(val){
      this.form.defaultCard = val
    }
  },
  mounted () {
    if(this.configurations.payment_methods.stripe_backend_implementation.active){
          // this allows us render a payment element form without a secret key or creating a paymentIntent on the backend
          const options = {
              mode: "setup",
              currency: "usd",
              paymentMethodCreation: "manual",
              appearance: {},
          };

          // create the stripe elements using Vue Stripe Plugin
          this.elements = this.$stripe.elements(options);

          // create and mount the payment Element
          const paymentElement = this.elements.create("payment");
          paymentElement.mount("#payment-element");
    }

    if(this.updateData){
      let month = 0
      if(this.updateData.expiry_month > 10){
        month = this.updateData.expiry_month
      }else{
        month = '0' + this.updateData.expiry_month
      }
        this.form.cardName = this.updateData.full_name
        this.form.cardNumber = this.updateData.card_number
        this.mainCardNumber = this.updateData.card_number
        this.form.cardDate = month + this.updateData.expiry_year.toString()
        this.form.cardMonth = this.updateData.expiry_month
        this.form.cardCvv = this.updateData.cvc
        this.mainCardCvc = this.updateData.cvc
      if(this.updateData.default){
        this.$emit('setDefault')
      }
    }
    setTimeout(() => {
      this.maskCardNumber()
      this.maskCardCvc()
    }, 100);
  },
  methods: {
  
    async uniquePaymentStripe(){ 
      this.$store.commit("checkout/RESET_PAYMENTS")
        try {
          if (this.loading) {
              return;
          }
          // this.loading = true;

          const {error: submitError} = await this.elements.submit();

          if (submitError) {
              console.log(submitError);
              // this.loading = false;
              return;
          }

          const { error, paymentMethod } = await this.$stripe.createPaymentMethod({
              elements: this.elements
          });
          console.log(error);

          let params = {
            card_brand: paymentMethod.card.brand,
            cardholder_name:  this.form.cardName,
            card_number  : paymentMethod.card.last4,
            cvc  : this.form.cardCvcNotMask,
            card_expiry_year: parseInt(paymentMethod.card.exp_year),
            card_expiry_month: parseInt(paymentMethod.card.exp_month),
            saveToDataVault: 0,
            balance: this.orderConfirm.total_order,
            uniquePayment: true
          }
          if(this.cardSelected.card != null && params.card_number.slice(-4) === this.cardSelected.card.id){
              this.$store.commit('checkout/SET_CARD_SELECTED', {id: this.cardSelected.card.id})
              this.$store.commit("checkout/SET_CARD_BALANCE", 0)
          }else{
              this.$store.commit('checkout/SET_CARD_SELECTED', {id: paymentMethod.id})
              this.$store.commit("checkout/SET_CARD_BALANCE", this.orderConfirm.total_order)
              this.$store.commit("checkout/SET_UNIQUE_CARD", params)
          }
          let notify = {
            text: 'Tarjeta selecionada',
            type: 'success',
            show: true,
          }
          this.$store.commit("common/SET_SHORT_NOTIFY", notify)
        } catch (error) {
          console.log(error);
        } finally {
          // this.loading = false
        }
    },
    async submit() {
        // Create the PaymentMethod using the details collected by the Payment Element
        try {
          // prevent multiple form submissions
          if (this.loading) {
              return;
          }

          // disable form submission while loading
          this.loading = true;

          // Trigger form validation and wallet collection
          const {error: submitError} = await this.elements.submit();

          if (submitError) {
              console.log(submitError);
              this.loading = false;
              return;
          }
          const { error, paymentMethod } = await this.$stripe.createPaymentMethod({
              elements: this.elements
          });

          if(error){
                const text = error.message
                let notify = { text, type: 'danger', show: true }
                this.$store.commit("common/SET_SHORT_NOTIFY", notify)
                this.$emit('errorInCard', error.code);
              return;
          }
            await http.post("stripe/create-payment-method", {payment_method_id: paymentMethod.id, is_default: this.form.defaultCard, cardholder_name: this.form.cardName})
            this.$store.dispatch('account/getCards')
            this.$emit('closeModal')
          let notify = {
            title: this.updateData ? 'checkout.card_updated_title':'',
            text: this.updateData ? 'checkout.card_updated_text' : 'checkout.card_created_successfully',
            type: 'success',
            show: true,
        }
          this.$store.commit("common/SET_SHORT_NOTIFY", notify)
        } catch (error) {
              console.log(error);
              const text = error.response.data.message
              let notify = { text, type: 'danger', show: true }
              this.$store.commit("common/SET_SHORT_NOTIFY", notify)
              this.$emit('errorInCard', error.response.data.code);
            return;
        } finally{
          this.loading = false;
        }
        this.$emit('getCards')
    },
    generateMonthValue (n) {
      return n < 10 ? `0${n}` : n
    },
    typeCard(data){
      this.form.cardbrand = data.type
    },
    changeName (e) {
      this.form.cardName = e.target.value
      this.$emit('input-card-name', this.form.cardName)
    },
    changeNumber (e) {
      this.form.cardNumber = e.target.value
      let value = this.form.cardNumber.replace(/\D/g, '')
      // american express, 15 digits
      if ((/^3[47]\d{0,13}$/).test(value)) {
        this.form.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
        this.cardNumberMaxLength = 17
      } else if ((/^3(?:0[0-5]|[68]\d)\d{0,11}$/).test(value)) { // diner's club, 14 digits
        this.form.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
        this.cardNumberMaxLength = 16
      } else if ((/^\d{0,16}$/).test(value)) { // regular cc number, 16 digits
        this.form.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{4})/, '$1 $2 ').replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ')
        this.cardNumberMaxLength = 19
      }
      // eslint-disable-next-line eqeqeq
      if (e.inputType == 'deleteContentBackward') {
        let lastChar = this.form.cardNumber.substring(this.form.cardNumber.length, this.form.cardNumber.length - 1)
        // eslint-disable-next-line eqeqeq
        if (lastChar == ' ') { this.form.cardNumber = this.form.cardNumber.substring(0, this.form.cardNumber.length - 1) }
      }
    },
    changeCvv (e) {
      this.form.cardCvv = e
    },
    invaildCard () {
      let number = this.form.cardNumberNotMask.replace(/ /g, '')
      var sum = 0
      for (var i = 0; i < number.length; i++) {
        var intVal = parseInt(number.substr(i, 1))
        if (i % 2 === 0) {
          intVal *= 2
          if (intVal > 9) {
            intVal = 1 + (intVal % 10)
          }
        }
        sum += intVal
      }
      if (sum % 10 !== 0) {
        alert(this.$t('cardForm.invalidCardNumber'))
      }
    },
    blurCardNumber () {
      if (this.isCardNumberMasked) {
        this.maskCardNumber()
      }
    },
    blurCardCvc () {
      this.isCardFlipped = false
      if (this.isCardCvcMasked) {
        this.maskCardCvc()
      }
    },
    maskCardNumber () {
      this.form.cardNumberNotMask = this.form.cardNumber
      this.mainCardNumber = this.form.cardNumber
      let arr = this.form.cardNumber.split('')
      arr.forEach((element, index) => {
        if (index > 4 && index < 14 && element.trim() !== '') {
          arr[index] = '*'
        }
      })
      this.form.cardNumber = arr.join('')
    },
    unMaskCardNumber() {
      this.form.cardNumber = this.mainCardNumber
    },
    maskCardCvc() {
      this.form.cardCvcNotMask = this.form.cardCvv
      this.mainCardCvc = this.form.cardCvv
      let arr = this.form.cardCvv.split('')
      arr.forEach((element, index) => {
        if (index < 4 && element.trim() !== '') {
          arr[index] = '*'
        }
      })
      this.form.cardCvv = arr.join('')
    },
    unmaskCardCvc () {
      this.form.cardCvv = this.mainCardCvc
    },

    focusCardNumber () {
      this.unMaskCardNumber()
    },
    focusCardCvc () {
      // this.isCardFlipped = element.id === this.fields.cardCvv
      this.isCardFlipped = true
      this.unmaskCardCvc()
    },
    toggleMask () {
      this.isCardNumberMasked = !this.isCardNumberMasked
      if (this.isCardNumberMasked) {
        this.maskCardNumber()
      } else {
        this.unMaskCardNumber()
      }
    },
    toggleMaskCvc () {
      this.isCardCvcMasked = !this.isCardCvcMasked
      if (this.isCardCvcMasked) {
        this.maskCardCvc()
      } else {
        this.unmaskCardCvc()
      }
    },

    async uniquePayment() {
      String.prototype.isNumber = function(){return /^\d+$/.test(this);}
      let params = {
        cardholder_name:  this.form.cardName,
        card_number  : this.form.cardNumberNotMask.replace(/\s+/g, ''),
        cvc  : this.form.cardCvcNotMask,
        card_expiry_year: parseInt(this.form.cardDate.slice(3,7)),
        card_expiry_month: parseInt(this.form.cardDate.slice(0,2)),
        saveToDataVault: 0,
        tax_amount: this.orderConfirm.total_order_taxes,
        balance: this.orderConfirm.total_order
      }
        this.$refs.name.$v.$touch();
        this.$refs.number.$v.$touch();
        this.$refs.cvc.$v.$touch();
        this.$refs.date.$v.$touch();
        if (this.$refs.name.$v.$invalid || this.$refs.number.$v.$invalid || this.$refs.cvc.$v.$invalid || this.$refs.date.$v.$invalid) return
      try {
        // const mergedArray = [params]
        // const mergedData = [...this.getLocalCards, ...mergedArray]
        // console.log(mergedData);
        this.$store.commit("checkout/RESET_PAYMENTS")
          if(this.cardSelected.card != null && params.card_number.slice(-4) === this.cardSelected.card.id){
              this.$store.commit('checkout/SET_CARD_SELECTED', {id: this.cardSelected.card.id})
              this.$store.commit("checkout/SET_CARD_BALANCE", 0)
          }else{
              this.$store.commit('checkout/SET_CARD_SELECTED', {id: params.card_number.slice(-4)})
              this.$store.commit("checkout/SET_CARD_BALANCE", this.orderConfirm.total_order)
              this.$store.commit("checkout/SET_UNIQUE_CARD", params)
          }
        // await http.post("azul/process-data-vault-create", params);
        // this.$emit('closeModal')
        // this.$emit("clearSelected")
        // this.form = {
        //   cardName: '',
        //   cardNumber: '',
        //   cardNumberNotMask: '',
        //   cardMonth: '',
        //   cardYear: '',
        //   cardCvv: '',
        //   cardCvcNotMask: '',
        //   cardbrand: '',
        //   cardDate: '',
        //   defaultCard: false
        // }
        let notify = {
          text: 'Tarjeta selecionada',
          type: 'success',
          show: true,
        }
        this.$store.commit("common/SET_SHORT_NOTIFY", notify)
      } catch (error) {
        console.log(error);
      }finally{
        this.loading = false
      }
    },
    
    async createCard() {
      String.prototype.isNumber = function(){return /^\d+$/.test(this);}
      let params = {
        cardholder_name:  this.form.cardName,
        // brand : this.form.cardbrand,
        // type: 'debit',
        // bank_name  : 'banco',
        card_number  : this.form.cardNumberNotMask.replace(/\s+/g, ''),
        cvc  : this.form.cardCvcNotMask,
        // card_number:
        // cvc: 
        card_expiry_year: parseInt(this.form.cardDate.slice(3,7)),
        card_expiry_month: parseInt(this.form.cardDate.slice(0,2)),
        is_default: this.form.defaultCard
        // expiry_year  : parseInt(this.form.cardDate.slice(3,7)),
        // expiry_month  : parseInt(this.form.cardDate.slice(0,2)),
        // default: this.form.defaultCard,
        // entity_id: this.userData.entity.code,
        // token_provider: 'AZUL',
        // token_expiry_year: parseInt(this.form.cardDate.slice(3,7)),
        // token_expiry_month: parseInt(this.form.cardDate.slice(0,2)),
        // is_default: true,
        // card_brand: this.form.cardbrand,
        // card_last_four_digits: this.form.cardNumberNotMask.slice(-4),
        // card_expiry_month: 12,
        // card_expiry_year: 2024,
        // updated_at:  this.DateToday.toISOString(),
        // created_at:  this.DateToday.toISOString(),
      }
        this.$refs.name.$v.$touch();
        this.$refs.number.$v.$touch();
        this.$refs.cvc.$v.$touch();
        this.$refs.date.$v.$touch();
        if (this.$refs.name.$v.$invalid || this.$refs.number.$v.$invalid || this.$refs.cvc.$v.$invalid || this.$refs.date.$v.$invalid) return
      try {
        this.loading = true
        console.log(this.loading);
        this.updateData ? await http.put(`users/cards/${this.updateData.id}`, params) : await http.post("azul/process-data-vault-create", params);
        this.$emit('closeModal')
        this.$emit("clearSelected")
        this.$store.dispatch('account/getCards')
        this.form = {
          cardName: '',
          cardNumber: '',
          cardNumberNotMask: '',
          cardMonth: '',
          cardYear: '',
          cardCvv: '',
          cardCvcNotMask: '',
          cardbrand: '',
          cardDate: '',
          defaultCard: false
        }
        let notify = {
          title: this.updateData ? 'checkout.card_updated_title':'',
          text: this.updateData ? 'checkout.card_updated_text' : 'checkout.card_created_successfully',
          type: 'success',
          show: true,
        }
        this.$store.commit("common/SET_SHORT_NOTIFY", notify)
      } catch (error) {
        // console.log(error.response.data.code);
        if(error.response.data.code == 85442022){
          const text = 'Ya tienes esta tarjeta agregada'
          let notify = { text, type: 'danger', show: true }
          this.$store.commit("common/SET_SHORT_NOTIFY", notify)
          this.$emit('errorInCard', error.response.data.code);
        } else if(error.response.data.code == 85442020){
          const text = 'error validando los parametros'
          let notify = { text, type: 'danger', show: true }
          this.$store.commit("common/SET_SHORT_NOTIFY", notify)
          this.$emit('errorInCard', error.response.data.code);
        }else {
          let text = error.response.data.code == 8547 ? this.$t('checkout.there_is_already_a_card_with_the_same_number'): error.response.data.message;
          let notify = { text, type: 'danger', show: true }
          this.$store.commit("common/SET_SHORT_NOTIFY", notify)
          this.$emit('errorInCard', error.response.data.code);
        }
      } finally {
        this.loading = false
        // console.log(this.loading);
      }
      this.$emit('getCards')
    },
    cancelEdit(){
      this.$emit('clear')
      this.form.cardName = ''
      this.form.cardNumber = ''
      this.form.cardYear = ''
      this.form.cardMonth = ''
      this.form.cardCvv = ''
      this.form.defaultCard = false
      this.form.cardNumberNotMask = ''
      this.form.cardCvcNotMask = ''
      this.form.cardDate = ''
    }
  }
}
</script>
<style scoped>
  .iconInput {
    width: 20px;
    margin-bottom: -5px;
    margin-right: 10px;
  }
  .confirm-loading {
        left: 0;
        top: 0;
        z-index: 1;
    }
  input.input-padding {
    padding-left: 10px !important;
  }
  .main-input::v-deep.active > .input-group > input::placeholder {
    color: var(--main-navy);
  }
  .credit-card-container {
    box-shadow: none;
    border: 1px solid var(--gray-50);
    max-width: 370px;
  }
  .cvc-input::v-deep .error-pop, .date-input::v-deep .error-pop {
    width: max-content;
  }
  .card-form::v-deep .name-date {
    width: auto !important;
  }
  .credit-card-container::v-deep .credit-card {
    min-height: 200px;
  }
  @media (max-width : 575px) {
    .main-button {
      border-radius: 30px !important;
      width: 84%;
      padding-block: 3px;
    }
    .credit-card-container::v-deep .credit-card {
      margin-bottom: 10px;
    }
    .name-date {
      margin-top: 10px !important;
    }
    .credit-card-container::v-deep .card-cvv-input {
      height: 34px ;
      margin-top: 8px !important;
    }
    .main-input::v-deep .input-group {
      margin-bottom: 0 !important;
    }
  }
  @media (min-width: 576px) {
    .credit-card-container::v-deep .credit-card {
      min-height: 230px;
    } 
    .credit-card-container {
      width: 370px;
    }
  }
  @media (min-width : 768px) {
    .credit-card-container {
      width: unset;
      margin-top: 2.6rem !important;
    }
  }
  .space_content{
    margin-left: 4px
  }

  .position-content{
    margin-left: -12px
  }

  /* .payment-form {
        width: 50%;
        margin: 0 auto
    } */

    #submit {
        border: none;
        padding: 10px;
        border-radius: 15%;
        background-color: #2c3e50;
        color: white;
        cursor: pointer;
    }
</style>
