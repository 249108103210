<template>
        <main-collapse class="mt-3" id="collapse" @open="open">
            <div class="d-flex justify-content-between" @click="collapse = !collapse">
                <div>
                    <h4 class="title-18 mb-2">{{$t("checkout.pick_order")}}</h4>
                    <h6 class="subtitle-14 mb-0 d-flex align-items-center color-dark-gray-300">
                        {{$t("checkout.pick_up_your_order")}} <b class="ms-1">{{branchSelected.description | textFormat}}</b>
                    </h6>
                </div>
                <div class="d-flex flex-column align-items-end">
                    <icon-arrow class="mb-2 icon-normal" :class="{'icon-active': collapse}" color="var(--main-navy)"/>
                    <h6 class="d-flex align-items-center subtitle-14 mb-0">
                        <icon-clock :size="18" class="me-1"/>
                        {{$t("checkout.schedules")}}: 
                        <span class="subtitle-14 mb-0 ms-2 color-dark-gray-300 weight-100">{{$t("checkout.monday_to_friday")}} 8:00 AM - 5:00 PM</span> 
                    </h6>
                    <span class="subtitle-14 ms-2 color-dark-gray-300 weight-100">{{$t("checkout.saturday")}} 8:00 AM - 3:00 PM</span> 
                </div>
            </div>
            <hr>
            <div @click="collapse = !collapse">
                <h6 class="title-16" v-if="!collapse">{{$t("checkout.selected_partner")}} 
                    <span class="subtitle-16 color-dark-gray-300">({{$t("checkout.click_to_change")}})</span>
                </h6>
                <h6 class="title-16" v-else>{{$t("checkout.select_or_add_a_partner")}} 
                    <span class="subtitle-16 color-dark-gray-300">({{$t("checkout.click_to_change")}})</span>
                </h6>
            </div>
                <selected-partner v-if="!collapse && partnerSelected" class="mt-4" />
                <hr v-if="!collapse">
                <div class="row" v-if="collapse">
                    <div class="col-md-12 mt-3">
                        <main-select :selectedOption="selectedOption" :options="filterOptions()" :summaryRef="'select-partner'" @clickHandler="(data) => {selectedOption = data}" style="max-width: 414px;"/>
                    </div>
                    <div class="col-md-12 mt-4">
                        <h5 class="title-18">Person details</h5>
                    </div>
                    <div class="col-md-7 ps-4 size-15 text-limited" v-if="partnerSelected">
                        <icon-person :size="17" :color="'var(--dark-gray-200)'"/>
                        <span class="mx-2">{{$t('checkout.selected_partner')}}</span>
                        <span class="color-dark-gray-300 text-capitalize">{{partnerSelected.full_name | textFormat}}</span>
                        <div class="d-inline" v-if="partnerSelected.default">
                            <span class="mx-2 color-dark-gray-300">|</span>
                            <icon-star :size="15" style="margin-top: -2px;"/>
                            <span class="ms-2">{{$t('checkout.default')}}</span>
                        </div>
                    </div>
                    <div class="col-md-5 ps-4 size-15 d-flex justify-content-end" v-if="partnerSelected">
                        <a href="#" @click.prevent="editPartner" v-if="partnerSelected.id != 0">{{$t("checkout.edit")}}</a>
                        <span class="ms-4 me-4 color-dark-gray-300" v-if="partnerSelected.id != 0">|</span>
                        <a href="#" @click.prevent="deletePartner" v-if="partnerSelected.id != 0">{{$t("checkout.remove")}}</a>
                        <span class="ms-4 me-4 color-dark-gray-300" v-if="partnerSelected.id != 0 && partnerSelected.default != true">|</span>
                        <a href="#" @click.prevent="defaultPartner" v-if="partnerSelected.default != true">{{$t("checkout.default")}}</a>
                    </div>
                    <div class="col-md-8 ps-4 size-15">
                        <div class="mt-1">
                            <icon-pin :size="17" :color="'var(--dark-gray-200)'"/>
                            <span class="mx-2">{{$t('checkout.will_pick_up_at')}}</span>
                            <span class="color-dark-gray-300">{{$t('checkout.branch')}} {{branchSelected.description | textFormat}}</span>
                        </div>
                        <div class="mt-1">
                            <icon-phone :size="17" :color="'var(--dark-gray-200)'"/>
                            <span class="mx-2">{{$t('checkout.phone_number')}}</span>
                            <span class="color-dark-gray-300">{{partnerSelected.whatsapp | phoneFormat}}</span>
                        </div>
                        <div class="mt-1">
                            <icon-last-name :size="17" :color="'var(--dark-gray-200)'"/>
                            <span class="mx-2">{{$t('checkout.identification_card')}}</span>
                            <span class="color-dark-gray-300">{{partnerSelected.identification}}</span>
                        </div>
                    </div>
                    <div class="mt-3">
                        <hr>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                    <h6 class="title-16 d-flex align-items-center" :class="[showCreatePartner ? 'mt-3 mb-4' : 'mb-0 mt-1']" style="cursor:pointer" @click="showCreatePartner = !showCreatePartner" >
                        <icon-add :size="25" class="me-2" :class="{'open-add-partner' : showCreatePartner}"/>
                        {{$t("checkout.add_new_partner")}}
                    </h6>
                    <main-switch :description="'checkout.default'" :id="`default`" :value="defaultP" @changeValue="(data)=>{ defaultP = data}" v-if="showCreatePartner"/>
                </div>
                <create-pick-up v-if="showCreatePartner" :editData="partner" :defaultPartner="defaultP" @clearData="partner = null; defaultP = false"/>
        </main-collapse>
</template>

<script>
import MainCollapse from '../../../../common/components/collapse/MainCollapse.vue'
import MainSelect from '../../../../common/components/selects/MainSelect.vue'
import IconArrow from '../../../../common/svg/iconArrow.vue'
import { mapGetters } from "vuex"
import IconAdd from '../../../../common/svg/iconAdd.vue'
import CreatePickUp from './CreatePickUp.vue'
import SelectedPartner from './SelectedPartner.vue'
import IconPerson from '../../../../common/svg/iconPerson.vue'
import IconLastName from '../../../account/assets/svg/iconLastName.vue'
import IconPhone from '../../../../common/svg/iconPhone.vue'
import IconClock from '../../../../common/svg/iconClock.vue'
import IconStar from '../../../../common/svg/iconStar.vue'
import IconPin from '../../../../common/svg/iconPin.vue'
import MainSwitch from '../../../../common/components/switch/MainSwitch.vue';

export default {
    name: "CheckoutPickUp",
    components:{
    MainCollapse,
    IconArrow,
    MainSelect,
    IconAdd,
    CreatePickUp,
    SelectedPartner,
    IconPerson,
    IconLastName,
    IconPhone,
    IconClock,
    IconStar,
    IconPin,
    MainSwitch
},
    computed:{
        ...mapGetters({
            userData: "auth/userData",
            partnerSelected: "checkout/partnerSelected",
            partners: 'checkout/partners',
            branchSelected: 'branches/branchSelected'
        })
    },
    data(){
        return {
            showCreatePartner: false,
            collapse: true,
            selectedOption: {
                value: 0, //the default setted on created.
                title: ''
            },
            partner: null,
            defaultP: false
        }
    },
    watch:{
        selectedOption(val){
            this.$store.commit("checkout/SET_PARTNER_SELECTED", {id: val.value, user: this.userData})
        },
        showCreatePartner(val){
            if (!val) {
                this.partner = null
            }
        }
    },
    methods: {
        editPartner(){
            this.defaultP = this.partnerSelected.default
            const partnerEdit = this.partners.filter((partner)=>{
                return partner.id === this.selectedOption.value
            })
            this.partner = partnerEdit[0]
            this.showCreatePartner = true
        },
        open(data) {
            if(data.id === "collapse")
            this.collapse = !this.collapse
        },
        async deletePartner(){
            try {
                this.$store.dispatch('checkout/deletePartner', this.partnerSelected.id)
                let notify = {
                    text: 'checkout.partner_removed_successfully',
                    type: 'success',
                    show: true,
                }
                this.$store.commit("common/SET_SHORT_NOTIFY", notify)
            } catch (error) {
                console.log(error);
            }
        },
        async defaultPartner(){
            try {
                let filterDefault = this.partners.filter((partner)=> {return partner.default === true})
                if(this.partnerSelected.id == 0){
                    if(filterDefault.length > 0)
                    this.$store.dispatch('checkout/defaultPartner', {id: filterDefault[0].id, form: {default: false}},)
                }else{
                    this.$store.dispatch('checkout/defaultPartner', {id: this.partnerSelected.id, form: {default: true}},)
                }
                let notify = {
                    text: 'account.address_created_successfully',
                    type: 'success',
                    show: true,
                }
                this.$store.commit("common/SET_SHORT_NOTIFY", notify)
            } catch (error) {
                console.log(error);
            }
        },
        filterOptions(){
            const newObject = [];
            const options = this.partners;
            for(let option of options) {
                newObject.push( {
                    value: option.id,
                    title: this.$options.filters.textFormat(option.full_name)
                });
            }
            newObject.push( { value: 0, title: `(${this.$t('account.me')})  ${this.$options.filters.textFormat(`${this.userData.entity.first_name} ${this.userData.entity.last_name}`)}`});
            if(this.selectedOption.value){
                let findIndex = newObject.findIndex(el => el.value == this.selectedOption.value)
                if(findIndex == -1){
                    this.selectedOption.value = 0
                }
            }
            return newObject
        },
    },
    async created(){
        this.selectedOption.value = this.partnerSelected.id
    }
}
</script>

<style scoped>
    #collapse{
        user-select: none;
        transition: var(--transition-1);

    }
    #collapse:hover{
        border-color: #ffffff00 !important;
        cursor: pointer;
        box-shadow: 0px 0px 6px 0px rgb(144 157 164 / 13%);
    }
    .options{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-size: 14px;
    }
    #collapse:hover > div > div:nth-child(2) > svg{
        fill: var(--main-red);
    }
    .open-add-partner.icon-add::v-deep > g > circle{
    fill: var(--main-red);
    }
    .open-add-partner{
        transform: rotate(45deg);
    }
    .icon-add::v-deep{
        transition: var(--transition-1);
    }
    .select-container::v-deep > div > span{
        text-transform: capitalize;
    }
    .select-container::v-deep > ul > li{
        text-transform: capitalize;
        
    }
</style>
