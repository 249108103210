<template>
        <svg :width="size + 'px'" :fill="color" viewBox="0 0 747.795 646.371">
            <g id="Group_7032" data-name="Group 7032">
                <path id="Path_2849" data-name="Path 2849" d="M578.238,119.842a112.545,112.545,0,1,1-225.09,0C353.148,57.685,403.536,15,465.693,15S578.238,57.685,578.238,119.842Z" fill="none" stroke="#efefef" stroke-width="30" opacity="0.39"/>
                <path id="Path_2850" data-name="Path 2850" d="M693.822,296.021c6.507,65.6-41.4,116.351-107,122.858a119.362,119.362,0,0,1-130.562-107" fill="none" stroke="#efefef" stroke-linecap="round" stroke-width="27" opacity="0.39"/>
                <path id="Path_2851" data-name="Path 2851" d="M168.771,51.8c36.246,31.706,39.925,79.088,8.219,115.333a87.2,87.2,0,0,1-123.037,8.219" fill="#efefef" opacity="0.39"/>
                <circle id="Ellipse_431" data-name="Ellipse 431" cx="86.523" cy="86.523" r="86.523" transform="translate(46.971 289.479)" fill="none" stroke="#efefef" stroke-linecap="round" stroke-width="22" opacity="0.39"/>
                <path id="Path_2852" data-name="Path 2852" d="M744.795,393.829c0,92.273-74.8,249.542-167.075,249.542a167.076,167.076,0,0,1,0-334.152C669.993,309.219,744.795,301.555,744.795,393.829Z" fill="none" stroke="#efefef" stroke-linecap="round" stroke-width="6" opacity="0.39"/>
                <circle id="Ellipse_432" data-name="Ellipse 432" cx="105.86" cy="105.86" r="105.86" transform="translate(1 368.272)" fill="none" stroke="#efefef" stroke-linecap="round" stroke-width="2" opacity="0.39"/>
                <circle id="Ellipse_433" data-name="Ellipse 433" cx="86.523" cy="86.523" r="86.523" transform="translate(46.971 289.479)" fill="none" stroke="#efefef" stroke-linecap="round" stroke-width="22" opacity="0.39"/>
            </g>
        </svg>
</template>
<script>

export default {
name: 'iconFrontCard',
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>
	.st1{fill:#EFEFEF;}
	.cardWhite{opacity:0.39;clip-path:url(#SVGID_6_);fill:#EFEFEF;enable-background:new    ;}
	.st4{opacity:0.39;clip-path:url(#SVGID_8_);fill:none;stroke:#EFEFEF;stroke-width:22;stroke-linecap:round;stroke-miterlimit:10;enable-background:new    ;}
	.st5-card{opacity:0.39;clip-path:url(#SVGID_10_);}
	.st6{opacity:0.39;clip-path:url(#SVGID_12_);}
	.st7{opacity:0.39;clip-path:url(#SVGID_14_);}
	.st8-card-card{fill:#DAE5EA;}
</style>
