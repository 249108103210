<template>
    <div>
        <h3 class="m-0 color-blue-700 title-24 weight-700">{{$t("checkout.payment_methods")}}</h3>
        <span>{{$t("checkout.select_one_or_more_payment")}}.</span>
        <payment-collapse v-show="showPayments(payment.value)" :amount="0" :title="payment.title" v-for="payment in payments" :key="payment.value" 
        :subtitle="payment.subtitle" :paypal="payment.value === 5" :deposits="payment.value === 2" :ref="payment.component" class="mt-4"
        :value="payment.value">
            <component :is="payment.component" class="animation-slide-down"></component>
        </payment-collapse>

        <!-- <stripe-view v-show="showPayments(payment.value)" :amount="0" :title="payment.title" v-for="payment in payments" :key="payment.value"
        :subtitle="payment.subtitle" :stripe="payment.value === 7" :ref="payment.component" class="mt-4"
        :value="payment.value">
            <component :is="payment.component" class="animation-slide-down"></component>
        </stripe-view> -->
    </div>
</template>

<script>
import Cash from './payments/Cash.vue'
import CreditCard from './payments/CreditCard.vue'
import DepositsAvailable from './payments/DepositsAvailable.vue'
// import StripeView from '../view/StripeVue.vue'
import GiftCard from './payments/GiftCard.vue'
import PaymentCollapse from './payments/PaymentCollapse.vue'
import UploadDeposit from './payments/UploadDeposit.vue'
import CashCredit from './payments/CashCredit.vue'
import { mapGetters } from "vuex"

export default {
    name: "CheckoutPayment",
    components: { PaymentCollapse, Cash, DepositsAvailable, UploadDeposit, CreditCard, GiftCard, CashCredit, 
        // StripeView
    },
    computed:{
        ...mapGetters({
            user: "auth/userData",
            deposits: "checkout/deposits",
            configurations: "common/configurations",
            creditsNote: "checkout/creditsNote",
            employeeActiveCode: 'auth/employeeActiveCode',
        }),
    },
    data() {
        return {
            payments: [
                {
                    title: '',
                    subtitle: 'checkout.description_cash_and_credit',
                    value: 1,
                    component: 'Cash',
                },
                {
                    title: 'checkout.deposits_and_credits_available',
                    subtitle: 'checkout.description_deposits_and_credits_available',
                    value: 2,
                    component: 'DepositsAvailable',
                },
                {
                    title: 'checkout.upload_deposit_image',
                    subtitle: 'checkout.description_upload_deposit_image',
                    value: 3,
                    component: 'UploadDeposit',
                },
                {
                    title: 'checkout.credit_debit_card',
                    subtitle: 'checkout.description_credit_debit_card',
                    value: 4,
                    component: 'CreditCard',
                },
                {
                    title: 'checkout.paypal',
                    subtitle: 'checkout.description_paypal',
                    value: 5,
                    component: 'Paypal',
                },
                {
                    title: 'checkout.gift_card',
                    subtitle: 'checkout.description_gift_card',
                    value: 6,
                    component: 'GiftCard',
                },
                // {
                //     title: 'checkout.credit_debit_card_stripe',
                //     subtitle: 'checkout.stripe_payment_info',
                //     value: 7,
                //     component: 'StripeView'
                // }
            ]
        }
    },
    methods:{
        showPayments(value){
            const {cash, credit, deposit, notas_de_credito:notes, express_deposit:express, 
            credit_card: card, paypal, gift_card: gift} = this.configurations.payment_methods;
            const isFinal = this.user.customer.price_level_id == 1;
            
            switch (value) {
                case 1:
                    if(cash.active || credit.active){
                        if(isFinal){
                            return (cash.final && cash.active) || (credit.final && parseFloat(this.user.customer.credit_limit) > 0) && credit.active;
                        }else{
                            return (cash.tienda && cash.active) || (credit.tienda && parseFloat(this.user.customer.credit_limit) > 0 && credit.active);
                        }
                    }
                    break;
                case 2:
                    if((deposit.active && this.deposits.length) || (notes.active && this.creditsNote.length)){
                        if(isFinal){
                            return deposit.final || notes.final;
                        }else{
                            return deposit.tienda || notes.tienda;
                        }
                    }
                    break;
                case 3:
                    if(express.active){
                        if(isFinal){
                            return express.final;
                        }else{
                            return express.tienda;
                        }
                    }
                    break;
                case 4:
                    if(card.active || this.configurations.payment_methods.stripe_backend_implementation.active){
                        if(isFinal){
                            return card.final || this.configurations.payment_methods.stripe_backend_implementation.final;
                        }else{
                            return card.tienda || this.configurations.payment_methods.stripe_backend_implementation.tienda;
                        }
                    }
                    break;
                case 5:
                    if(paypal.active){
                        if(isFinal){
                            return paypal.final;
                        }else{
                            return paypal.tienda;
                        }
                    }
                    break;
                case 6:
                    if(gift.active){
                        if(isFinal){
                            return gift.final;
                        }else{
                            return gift.tienda;
                        }
                    }
                    break;
                // case 7:
                // if(stripe.active){
                //     if(isFinal){
                //         return stripe.final;
                //     }else{
                //         return stripe.tienda;
                //     }
                // }
                // break;
            
                default: false
                    break;
            }
        }
    },
    mounted(){
        const {credit, cash} = this.configurations.payment_methods
        const isFinal = this.user.customer.price_level_id == 1

        if(isFinal){
            this.payments[0].title = (credit.active && credit.final && parseFloat(this.user.customer.credit_limit) > 0) && cash.final
            ? 'checkout.cash_and_credit' : cash.final ? 'checkout.cash' : 'checkout.credit'
        }else{
            this.payments[0].title = (credit.active && credit.tienda && parseFloat(this.user.customer.credit_limit) > 0) && cash.tienda
            ? 'checkout.cash_and_credit' : cash.tienda ? 'checkout.cash' : 'checkout.credit'
        }
    }
}
</script>
<style>
    .input-payment{
        height: 38px !important;
        border-color: #cedde9 !important;
        border-radius: 7px !important;
        border: 1px solid;
        width: 100%;
        font-size: 14px;
    }
    .form-control:disabled{
        background: transparent;
    }
</style>
