<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
            <g>
                <g>
                    <path class="st3" d="M40.8,105c2.1,0.8,4.6,0.9,7.1,1c5.1,0.2,10.6-0.1,17.7-0.9c1.3-0.1,2.6-0.3,3.9-0.5c-1.5-2.9-3.1-6-4.7-8.9     c-3-5.4-4.2-7.5-8.3-12.4c-2-2.3-3.8-3.7-5.8-4.4c-1.5-0.5-3-0.8-4.6-0.8c-4.1,0-8,1.8-10.8,5c-3.7,4.3-4.4,10.5-1.7,15.5     C35.5,101.9,37.8,104,40.8,105z"/>
                    <path class="st3" d="M114.9,105.9c3.1-0.3,5.2-1.1,6.9-2.4c4.6-3.5,6.6-9.4,5.1-15.1c-1.5-5.5-6.3-9.5-11.9-10.1     c-3.6-0.4-6.6,0.4-9.2,2.3c-1.8,1.4-3.3,3.3-4.8,5.3c-3,4.1-5.9,8.8-9.2,15.2c-0.6,1.2-1.2,2.3-1.8,3.5c3.3,0.4,6.7,0.8,10,1.1     C105.5,106.3,110.4,106.3,114.9,105.9z"/>
                </g>
            </g>
            <g>
                <g>
                    <path class="st3" d="M40.8,105c2.1,0.8,4.6,0.9,7.1,1c5.1,0.2,10.6-0.1,17.7-0.9c1.3-0.1,2.6-0.3,3.9-0.5c-1.5-2.9-3.1-6-4.7-8.9     c-3-5.4-4.2-7.5-8.3-12.4c-2-2.3-3.8-3.7-5.8-4.4c-1.5-0.5-3-0.8-4.6-0.8c-4.1,0-8,1.8-10.8,5c-3.7,4.3-4.4,10.5-1.7,15.5     C35.5,101.9,37.8,104,40.8,105z"/>
                    <path class="st3" d="M114.9,105.9c3.1-0.3,5.2-1.1,6.9-2.4c4.6-3.5,6.6-9.4,5.1-15.1c-1.5-5.5-6.3-9.5-11.9-10.1     c-3.6-0.4-6.6,0.4-9.2,2.3c-1.8,1.4-3.3,3.3-4.8,5.3c-3,4.1-5.9,8.8-9.2,15.2c-0.6,1.2-1.2,2.3-1.8,3.5c3.3,0.4,6.7,0.8,10,1.1     C105.5,106.3,110.4,106.3,114.9,105.9z"/>
                </g>
            </g>
            <path class="st3" d="M18.7,94c-0.5-6.9,1.6-13.9,6.2-19.3c7.3-8.5,18.8-11.6,29.3-8.1c4.3,1.5,8,4.1,11.5,8.4   c1.5,1.8,2.7,3.3,3.8,4.8V37.4H10.2c-5.4,0-9.7,4.4-9.7,9.7V94H18.7z"/>
            <path class="st3" d="M91.5,78.2c2.1-2.8,4.4-5.7,7.5-8c5.2-3.8,11.3-5.4,18.1-4.7c10.9,1.1,20.2,9,23,19.6c0.8,3,1,6,0.8,9h108.6   V47.2c0-5.4-4.4-9.7-9.7-9.7H91.5V78.2z"/>
            <path class="st3" d="M33.7,155.4c-1.2,0.9-2.7,1.4-4.1,1.4c-2.1,0-4.2-0.9-5.5-2.8c-2.3-3-1.7-7.4,1.4-9.6l34.3-25.7   c-3.1,0.2-6.1,0.3-8.8,0.3c-1.3,0-2.5,0-3.9-0.1c-3.6-0.1-7.5-0.4-11.4-1.8c-3-1-5.5-2.7-7.8-4.8H0.5v90.4c0,5.4,4.4,9.7,9.7,9.7   h59.3v-84L33.7,155.4z"/>
            <path class="st3" d="M131.9,112.4c-0.5,0.5-1,1-1.5,1.4c-3.6,2.7-7.8,4.3-13.4,4.8c-5.7,0.6-11.8,0.5-18.5-0.1l32.4,31.8   c2.7,2.7,2.7,7,0.1,9.7c-1.3,1.4-3.1,2.1-4.9,2.1c-1.7,0-3.5-0.7-4.8-2L91.5,131v81.5h148.3c5.4,0,9.7-4.4,9.7-9.7v-90.4H131.9z    M189.2,181.9h-39.8c-3.4,0-6.1-2.7-6.1-6.1c0-3.4,2.7-6.1,6.1-6.1h39.8c3.4,0,6.1,2.7,6.1,6.1   C195.4,179.2,192.6,181.9,189.2,181.9z M211.1,153.4h-61.6c-3.4,0-6.1-2.7-6.1-6.1c0-3.4,2.7-6.1,6.1-6.1h61.6   c3.4,0,6.1,2.7,6.1,6.1C217.2,150.6,214.5,153.4,211.1,153.4z"/>
        </g>
    </icon-base>
</template>
<script>

export default {
name: 'iconGiftCard',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>
    .st0{fill:#DCE4EA;}
    .st1{fill:#111820;}
</style>
