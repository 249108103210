<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g>
            <g>
                <rect x="171.3" y="65.3" class="st22" width="0.9" height="6.9"/>
            </g>
            <g class="st23">
                <polygon class="st22" points="6.9,129.3 13.3,129.3 13.3,112.9 19.7,106.5 19.7,136.6 24.7,136.6 24.7,97.8 31,97.8 31,59 
                    52.5,80.5 52.5,128.6 61.1,128.6 61.1,88.5 67.6,88.5 67.6,70.1 74.3,70.1 74.3,50.5 79.3,50.5 79.3,42.9 87.1,42.9 87.1,63.5 
                    91.8,63.5 91.8,74.3 95.7,74.3 95.7,91.1 102.2,91.1 102.2,119.1 110.9,119.1 110.9,65.8 117.2,65.8 117.2,59.2 122.4,59.2 
                    122.4,65.8 139.3,65.8 139.3,128.6 146,128.6 146,114.8 153.9,114.8 153.9,92.5 158.8,92.5 158.8,81.1 166.1,81.1 166.1,73.9 
                    171.8,68.5 177,73.9 177,81.1 182.2,81.1 182.2,92.5 188.4,92.5 188.4,116.1 192.7,111.8 192.7,92.5 206.3,92.5 214.7,84.2 
                    221.5,84.2 221.5,115.1 228.4,115.1 228.4,105 235.2,105 235.2,122.2 243.1,122.2 243.1,161.9 6.9,161.9 		"/>
                <g>
                    <g>
                        <line class="st24" x1="249.5" y1="207.1" x2="0.5" y2="207.1"/>
                        <line class="st24" x1="0.5" y1="162.2" x2="249.5" y2="162.2"/>
                    </g>
                </g>
                <g>
                    <path class="st22" d="M32.2,187.6H1.1c-0.3,0-0.6-0.3-0.6-0.6v-4.7c0-0.3,0.3-0.6,0.6-0.6h31.1c0.3,0,0.6,0.3,0.6,0.6v4.7
                        C32.7,187.3,32.5,187.6,32.2,187.6z"/>
                    <path class="st22" d="M104.4,187.6H73.3c-0.3,0-0.6-0.3-0.6-0.6v-4.7c0-0.3,0.3-0.6,0.6-0.6h31.1c0.3,0,0.6,0.3,0.6,0.6v4.7
                        C105,187.3,104.7,187.6,104.4,187.6z"/>
                    <path class="st22" d="M176.7,187.6h-31.1c-0.3,0-0.6-0.3-0.6-0.6v-4.7c0-0.3,0.3-0.6,0.6-0.6h31.1c0.3,0,0.6,0.3,0.6,0.6v4.7
                        C177.2,187.3,177,187.6,176.7,187.6z"/>
                    <path class="st22" d="M248.9,187.6h-31.1c-0.3,0-0.6-0.3-0.6-0.6v-4.7c0-0.3,0.3-0.6,0.6-0.6h31.1c0.3,0,0.6,0.3,0.6,0.6v4.7
                        C249.5,187.3,249.2,187.6,248.9,187.6z"/>
                </g>
                <g>
                    <polygon class="st25" points="225.8,163.6 225.8,146.7 32.4,146.7 32.4,157.6 			"/>
                    <g>
                        <defs>
                            <path id="SVGID_17_" d="M159.5,96.6h40.3c3.3,0,6.5,1.5,8.6,4.1l17.8,21.9c1.1,1.3,1.6,2.9,1.6,4.6v29.1h-4.1l-2.2-2.2h-22.4
                                c0,0-4.3-5.1-11.2-5.1c-6.9,0-12.4,5.3-12.4,5.3h-16V96.6z"/>
                        </defs>
                        <use xlink:href="#SVGID_17_"  style="overflow:visible;fill:#E44446;"/>
                        <clipPath id="SVGID_4_">
                            <use xlink:href="#SVGID_17_"  style="overflow:visible;"/>
                        </clipPath>
                        <path style="clip-path:url(#SVGID_4_);fill:#CE4148;" d="M229.6,123c-0.3-0.7-13.3-25.2-13.3-25.2l-6,0.9v-5.8h-56.9v8.6h46.9
                            c3.7,0,7.1,1.6,9.6,4.3c6.6,7.3,16.7,18.8,16.7,20.8c0,2.8,0,32.1,0,32.1l1.1,1.3l5.6-15.3C233.4,144.5,229.9,123.7,229.6,123z"
                            />
                    </g>
                    <g>
                        <defs>
                            <path id="XMLID_12_" d="M216.4,133.1h-21c-3.3,0-6.6-0.9-9.5-2.5l-16.5-9.2c-1.2-0.7-1.9-1.9-1.9-3.2V112c0-2,1.7-3.7,3.7-3.7
                                h17.7c7.4,0,14.3,3.8,18.3,10.1L216.4,133.1z"/>
                        </defs>
                        <use xlink:href="#XMLID_12_"  style="overflow:visible;fill:#FFFFFF;"/>
                        <clipPath id="XMLID_21_">
                            <use xlink:href="#XMLID_12_"  style="overflow:visible;"/>
                        </clipPath>
                        <g class="st27">
                            
                                <rect x="182.1" y="89.9" transform="matrix(0.7648 -0.6443 0.6443 0.7648 -36.0257 147.2123)" class="st28" width="3.1" height="66.1"/>
                            
                                <rect x="200.5" y="89.9" transform="matrix(0.7645 -0.6446 0.6446 0.7645 -31.6805 159.1717)" class="st28" width="3.1" height="66.1"/>
                        </g>
                    </g>
                    <g>
                        <defs>
                            <path id="SVGID_19_" d="M232.5,169.7L25.9,170c-2.1,0-3.8-1.7-3.8-3.8l0-6.1c0-2.1,1.7-3.8,3.8-3.8l206.6-0.4
                                c0.7,0,1.3,0.6,1.3,1.3l0,11.1C233.8,169.1,233.2,169.7,232.5,169.7z"/>
                        </defs>
                        <use xlink:href="#SVGID_19_"  style="overflow:visible;fill:#CB3637;"/>
                        <clipPath id="SVGID_6_">
                            <use xlink:href="#SVGID_19_"  style="overflow:visible;"/>
                        </clipPath>
                        <path style="clip-path:url(#SVGID_6_);fill:#E5AE5E;" d="M227.8,153.8c0,0,3.5,7.6-6.2,16.7h17.2v-17.1L227.8,153.8z"/>
                    </g>
                    <g>
                        <circle class="st3" cx="188.4" cy="172" r="17.1"/>
                        <circle class="st1" cx="188.4" cy="172" r="9.8"/>
                    </g>
                    <g>
                        <circle class="st3" cx="72.3" cy="172" r="17.1"/>
                        <circle class="st1" cx="72.3" cy="172" r="9.8"/>
                    </g>
                    <path id="XMLID_8_" class="st18" d="M156.2,70.8H35.7c-1.8,0-3.3,1.5-3.3,3.3v79.7h26.7c3.7-2.7,8.3-4.3,13.3-4.3
                        s9.5,1.6,13.3,4.3h73.9V74.1C159.5,72.2,158,70.8,156.2,70.8z"/>
                    <path class="st25" d="M179.3,136.6h-9.5c-0.4,0-0.7-0.3-0.7-0.7v-1.2c0-0.4,0.3-0.7,0.7-0.7h9.5c0.4,0,0.7,0.3,0.7,0.7v1.2
                        C179.9,136.3,179.6,136.6,179.3,136.6z"/>
                </g>
            </g>
            <path class="st1" d="M146.3,76H45.6c-4.4,0-7.9,3.5-7.9,7.9v62.8h16.7c5.1-3.6,11.2-5.7,17.9-5.7c6.7,0,12.8,2.1,17.9,5.7h64V83.9
                C154.2,79.6,150.7,76,146.3,76z"/>
        </g>
    </icon-base>
</template>
<script>

export default {
name: 'iconNational',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>
	.st1{fill:#FFFFFF;}
	.st2{fill:#F4F7F9;}
	.st3{fill:#212E3D;}
	.st4{fill:#C8D3D6;}
	.st5{fill:#BC2C35;}
	.st6{fill:none;stroke:#DAE5EA;stroke-linecap:round;stroke-miterlimit:10;}
	.st7{fill:none;stroke:#DAE5EA;stroke-width:1.5;stroke-linecap:round;stroke-miterlimit:10;}
	.st8{fill:none;stroke:#DAE5EA;stroke-width:1.5;stroke-linecap:round;stroke-miterlimit:10;stroke-dasharray:11.6667,11.6667;}
	.st9{clip-path:url(#SVGID_4_);}
	.st10{fill:#EA3838;}
	.st11{fill:#72BF58;}
	.st12{fill:#C32126;}
	.st13{fill:none;stroke:#A5B2B7;stroke-miterlimit:10;}
	.st14{fill:none;stroke:#C8D3D6;stroke-linecap:round;stroke-miterlimit:10;}
	.st15{fill:#DC2E2D;}
	.st16{fill:#C42C2D;}
	.st17{clip-path:url(#SVGID_11_);}
	.st18{fill:#E5AE5E;}
	.st19{fill:#FCEEDE;}
	.st20{fill:#D39542;}
	.st21{clip-path:url(#SVGID_15_);}
	.st22{fill:#E2EBED;}
	.st23{opacity:0.9;}
	.st24{fill:none;stroke:#E2EBED;stroke-miterlimit:10;}
	.st25{fill:#CB3637;}
	.st26{clip-path:url(#SVGID_18_);fill:#CE4148;}
	.st27{clip-path:url(#XMLID_21_);}
	.st28{fill:#EAEAE9;}
	.st29{clip-path:url(#SVGID_20_);fill:#E5AE5E;}
	.st30{clip-path:url(#SVGID_21_);fill:#CE4148;}
	.st31{clip-path:url(#XMLID_22_);}
	.st32{clip-path:url(#SVGID_22_);fill:#E5AE5E;}
	.st33{clip-path:url(#SVGID_24_);fill:#AF3B3B;stroke:#AF3B3B;stroke-width:2;stroke-miterlimit:10;}
	.st34{clip-path:url(#XMLID_23_);fill:#FFFFFF;}
	.st35{fill:#AF3B3B;}
	.st36{fill:#E8ECED;}
	.st37{fill:#263544;}
	.st38{fill:#F9FCF8;}
	.st39{fill:#D23534;}
	.st40{clip-path:url(#XMLID_24_);}
	.st41{fill:#F5F7F7;}
	.st42{clip-path:url(#SVGID_26_);fill:#AF3B3B;stroke:#AF3B3B;stroke-width:2;stroke-miterlimit:10;}
	.st43{clip-path:url(#XMLID_25_);fill:#FFFFFF;}
	.st44{clip-path:url(#XMLID_26_);}
	.st45{clip-path:url(#SVGID_30_);}
	.st46{clip-path:url(#SVGID_37_);}
	.st47{fill:#DFE7E8;}
	.st48{clip-path:url(#SVGID_39_);fill:#CE4148;}
	.st49{clip-path:url(#XMLID_27_);}
	.st50{clip-path:url(#SVGID_40_);fill:#E5AE5E;}
	.st51{clip-path:url(#SVGID_42_);fill:#AF3B3B;stroke:#AF3B3B;stroke-width:2;stroke-miterlimit:10;}
	.st52{clip-path:url(#XMLID_28_);fill:#FFFFFF;}
	.st53{clip-path:url(#XMLID_30_);}
	.st54{fill:#FFF6DC;}
	.st55{fill:none;stroke:#886619;stroke-width:2;stroke-miterlimit:10;}
	.st56{fill:none;stroke:#1F3D5B;stroke-width:2;stroke-miterlimit:10;}
	.st57{fill:none;stroke:#1F3D5B;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}
	.st58{fill:#111820;}
	.st59{display:none;}
	.st60{display:inline;}
	.st61{fill:#5A8CDB;}
	.st62{opacity:0.2;}
	.st63{fill:#C6D4DB;}
	.st64{fill:none;}
	.st65{fill:#FFD971;}
</style>
