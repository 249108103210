<template>
    <div class="row" v-if="addressSelected">
        <div class="col-md-4 d-flex align-items-center">
            <radio-button :checked="true" style="margin-top: 2px;"/>
            <icon-star :size="25" class="ms-2 icon-star" v-if="addressSelected.default"/>
            <span class="ms-2 subtitle-14 text-limited mb-0">
                {{ addressSelected.name | textFormat}}
                <span class="me-2 color-dark-gray-200">|</span>
                <span>Tel:</span>
                <span class="color-dark-gray-400 subtitle-14 mr-2 ml-1">
                    {{addressSelected.phone | phoneFormat }}
                </span>
            </span>
        </div>
        <div class="col-md-4 d-flex">
            <span class="text-limited subtitle-14 ps-4 ps-md-0">
                {{ addressSelected.address_line2 }}, {{ addressSelected.city.city }}
            </span>
        </div>
        <div class="col-md-4 d-flex">
            <span class="text-limited subtitle-14 ps-4 ps-md-0 weight-300">
                Ref: <span class="color-dark-gray-400">{{ addressSelected.reference }}</span>
            </span>
        </div>
    </div>
</template>

<script>
import RadioButton from '../../../../common/components/radio/RadioButton.vue'
import IconStar from '../../../../common/svg/iconStar.vue'
import { mapGetters } from "vuex"

export default {
    name: "AddressSelected",
    components:{
        RadioButton,
        IconStar
    },
    computed:{
        ...mapGetters({
            addressSelected: "account/addressSelected",

        }),
    },
    methods:{
    }
}
</script>

<style scoped>
    @media (max-width: 576px) {
        .icon-star{
            width: 18px;
        }
    }
</style>
