<template>
    <div class="credit-card-container position-relative">
        <div class="credit-card position-relative" :class="[isCardFlipped ? 'active' : '', background == 'white' ? 'white-card' : 'text-white']" :style="{background: background}">
            <div class="card-front p-4 pt-3" :class="[isForm ? 'd-flex flex-column justify-content-center' : '']">
                <icon-front-card :size="650" class="background-front" :class="[background == 'white' ? '' : 'lower-opacity']" />
                <div class="d-flex align-items-center justify-content-between">
                    <icon-chip :size="40" :color="background == 'white' ? '#fb9a04' : '#fff'" class="icon-chip mt-2 pt-1"/>
                    <div class="credit-type d-flex align-content-center flex-column">
                        <icon-visa viewBox="0 80 250 100" v-if="cardType.type == 'visa'" :size="70" :white="background !== 'white'" />
                        <icon-mastercard v-else-if="cardType.type == 'mastercard'" :size="70" />
                        <icon-discover v-else-if="cardType.type == 'discover'" :size="70" class="mt-3" />
                        <icon-troy v-else-if="cardType.type == 'troy'" :size="70" />
                        <icon-union-pay v-else-if="cardType.type == 'unionpay'" :size="70" /> 
                        <icon-diners-club v-else-if="cardType.type == 'dinersclub'" :size="58"/>
                        <icon-j-c-b v-else-if="cardType.type == 'jcb'" :size="70" />
                        <icon-american-express v-else-if="cardType.type == 'amex'" :size="100" />
                    </div>
                </div>
                <h6 class="default-text d-flex justify-content-end size-12" :class="[cardType.type == 'visa' ? 'visa':'mc', labels.defaultCard ? '':'invisible', background == 'white' ? '' : 'text-white']" > 
                    <icon-star class="me-1" :size="15"/> {{$t('checkout.default')}}
                </h6>
                <transition name="slide-fade-up">
                    <div v-if="labels.cardNumber.length" key="1" class="position-relative text-center mt-0" :class="[isForm ? 'd-flex flex-column justify-content-center pb-0' : '']" style="padding-bottom: 0.8rem;">
                        <transition-group name="slide-fade-right">
                            <span class="card-number text-center" v-for="(n, $index) in labels.cardNumber.replace(/\s\s+/g,' ')" :class="[background == 'white' ? '' : 'text-white']" :key="$index + 1">{{n}}</span>
                        </transition-group>
                    </div>
                    <h4 v-else key="2" class="card-number text-center mt-4 mb-2">0000 0000 0000 0000</h4>
                </transition>
                <!---->
                <div class="name-date d-flex justify-content-between" style="width: 280px" :class="[{'mt-4': !isAccount }]">
                    <div class="d-flex flex-column">
                        <b class="size-14 weight-600">{{$t('auth.name')}}</b>
                        <transition name="slide-fade-up">
                            <div v-if="short_name.length > 2" key="1" style="line-height: 17px; max-width: 90px;" class="text-limited">
                                <transition-group name="slide-fade-right">
                                    <span class="color-dark-gray-400 size-13" v-for="(n, $index) in short_name" :class="[background == 'white' ? '' : 'text-white']" :key="$index + 1">{{n}}</span>
                                </transition-group>
                            </div>
                            <div v-else key="2" class="color-dark-gray-300 size-13">{{$t('checkout.holder_name')}}</div>
                        </transition>
                    </div>
                    <!---->
                    <div class="d-flex flex-column">
                        <b class="size-14 weight-600">{{$t('checkout.expiration_date')}}</b>
                        <transition name="slide-fade-up">
                            <div v-if="labels.cardDate.length" key="1" style="line-height: 15px;">
                            <transition-group name="slide-fade-right">
                                <span class="color-dark-gray-400 size-13" v-for="(n, $index) in labels.cardDate.replace(/\s\s+/g, ' ')" :class="[background == 'white' ? '' : 'text-white']" :key="$index + 1">{{n}}</span>
                            </transition-group>
                            </div>
                            <div v-else key="2" class="color-dark-gray-300 size-13">MM/YYYY</div>
                        </transition>
                    </div>
                <!---->
                    <div v-if="!isForm" class="d-flex flex-column">
                        <b class="size-14 weight-600">CVC</b>
                        <transition name="slide-fade-up">
                            <div v-if="labels.cardName.length " key="1" style="line-height: 12px;">
                                <transition-group name="slide-fade-right">
                                    <span v-for="(n, $index) of labels.cardCvv" class="color-dark-gray-400 size-13" :class="[background == 'white' ? '' : 'text-white']" :key="$index + 1">*</span>
                                </transition-group>
                            </div>
                            <div v-else key="2" class="color-dark-gray-300 size-13">{{$t('checkout.holder_name')}}</div>
                        </transition>
                    </div>
                <!---->
                </div>
                <div class="d-flex size-14 mt-4" :class="labels.defaultCard ? '':'justify-content-center'" v-if="isAccount">
                    <a href="#" class="card-options" :class="[background == 'white' ? '' : 'text-white deactivate-hover']" @click.prevent="$emit('delete', code)">{{$t('account.remove')}}</a>
                    <!-- <a href="#" class="card-options ms-4 ps-4" :class="[background == 'white' ? '' : 'text-white deactivate-hover']" @click.prevent="$emit('edit', code)">{{$t('account.edit')}}</a> -->
                    <a href="#" v-if="!labels.defaultCard" class="card-options ms-4 ps-4" :class="[background == 'white' ? '' : 'text-white deactivate-hover']" @click.prevent="$emit('default', code)">{{$t('account.default')}}</a>
                </div>
            </div>
            <div class="card-back">
                <div class="card-band" />
                <icon-front-card :size="100" class="background-front"/>
                <div class="p-4">
                    <h6 class="text-end">
                        <b>CVC</b>
                    </h6>
                    <transition name="slide-fade-up">
                        <div v-if="labels.cardCvv.length" key="1" class="card-cvv-input d-flex align-items-center justify-content-end text-center">
                            <transition-group name="slide-fade-right">
                                <span v-for="(n, $index) in labels.cardCvv.replace(/\s\s+/g, ' ')" :key="$index + 1">{{n}}</span>
                            </transition-group>
                        </div>
                        <h4 v-else key="2" class="card-cvv-input d-flex align-items-center justify-content-end mt-0">***</h4>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import IconStar from '../../../../../common/svg/iconStar.vue'
import IconAmericanExpress from '../../../assets/svg/iconAmericanExpress.vue'
import IconChip from '../../../assets/svg/iconChip.vue'
import IconDinersClub from '../../../assets/svg/iconDinersClub.vue'
import IconDiscover from '../../../assets/svg/iconDiscover.vue'
import iconFrontCard from '../../../assets/svg/iconFrontCard.vue'
import IconJCB from '../../../assets/svg/iconJCB.vue'
import IconMastercard from '../../../assets/svg/iconMastercard.vue'
import IconTroy from '../../../assets/svg/iconTroy.vue'
import IconUnionPay from '../../../assets/svg/iconUnionPay.vue'
import IconVisa from '../../../assets/svg/iconVisa.vue'

export default {
    name: 'Card',
    components: {
        iconFrontCard,
        IconChip,
        IconVisa,
        IconStar,
        IconMastercard,
        IconDiscover,
        IconTroy, 
        IconUnionPay,
        IconDinersClub,
        IconJCB,
        IconAmericanExpress
    },
    props: {
        labels: {
            type: Object
        },
        isCardFlipped: {
            type: Boolean,
            default: false
        },
        isAccount: {
            type: Boolean,
            default: false
        },
        background: {
            type: String,
            default: 'white'
        },
        code: {
            type: String,
            default: null
        },
        isForm: {
            type: Boolean,
            default: false
        },
    },
    data(){
        return {
            short_name: '',
        }
    },
    computed: {
        cardType () {
            let number = this.labels.cardNumber;
            let name = this.labels.brand?.toLowerCase()
            let re = { value: new RegExp('^4'), brand: 'visa'}
            if (number.match(re.value) != null || re.brand == name)
            return {
                img: 'https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png',
                type: 'visa'
            }
            re = { value: new RegExp('^(34|37)'), brand: 'amex'}
            if (number.match(re.value) != null || re.brand == name) return {
                img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/American_Express_logo_%282018%29.svg/2052px-American_Express_logo_%282018%29.svg.png',
                type: 'amex'
            };

            re = {value : new RegExp('^5[1-5]'), brand: 'mastercard'}
            if (number.match(re.value) != null || re.brand == name) return {
                img:'https://upload.wikimedia.org/wikipedia/commons/thumb/7/72/MasterCard_early_1990s_logo.png/1200px-MasterCard_early_1990s_logo.png',
                type: 'mastercard'
            }

            re = {value: new RegExp('^6011'), brand: 'discover'}
            if (number.match(re.value) != null || re.brand == name) return {
                img: '',
                type: 'discover'
            }

            re = {value: new RegExp('^62'), brand: 'unionpay'}
            if (number.match(re.value) != null || re.brand == name) return {
                img: '',
                type: 'unionpay' //6250 0000
            }

            re = {value: new RegExp('^9792'), brand: 'troy'}
            if (number.match(re.value) != null || re.brand == name) return {
                img: '',
                type: 'troy'
            }

            re = {value: new RegExp('^3(?:0([0-5]|9)|[689]\\d?)\\d{0,11}'), brand: 'dinersclub'}
            if (number.match(re.value) != null || re.brand == name) return {
                img: '',
                type: 'dinersclub' //3695 0000
            }

            re = {value: new RegExp('^35(2[89]|[3-8])'), brand: 'jcb'}
            if (number.match(re.value) != null || re.brand == name) return {
                img: '',
                type: 'jcb'
            }
            return '' // default type
        },
    },
    watch: {
        cardType (val) {
            this.$emit('typeCard', val)
        },  
        'labels.cardName'(name){
            this.short_name = this.format(name);
        }
    },
    methods: {
        format(name) {
            const result = name.replace(/\s+/g, ' ').trim()
            const format = (arg) => `${arg[0].slice(0,1).toUpperCase()}. ${arg[1] || ''}`;
            return format(result.split(' '));
        }
    },
    created(){
        this.short_name = this.format(this.labels.cardName);
    }
}
</script>

<style scoped>
.lower-opacity {
    opacity: 0.35;
}
.credit-card::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: calc(100%);
    height: calc(100%);
    opacity: 0.85;

    border-radius: 8px;

    transition: var(--transition-1);
}
.credit-card:hover::before {
    background: rgba(255, 255, 255, 0.28);

    backdrop-filter: blur(4px);

    transition: var(--transition-1);
}
.credit-card.white-card:hover::before { /* white card blur*/
    backdrop-filter: blur(2px);
}
.white-card{
    color: var(--dark-main-navy) !important;
}
.credit-card-container {
    overflow: hidden;
    box-shadow: 3px 6px 20px #7C879329;
    border-radius: 10px;
    max-width: 368px;
    width: 100%;
}
.credit-card {
    max-width: 368px;
    min-height: 230px;
    
    transform-style: preserve-3d;
    transition: var(--transition-1);
}
.credit-card:hover {
    box-shadow: none;
}
.credit-card.white-card:hover {
    box-shadow: 0px 0px 0px 1px var(--gray-1001);
}
.card-front, .card-back {
    position: absolute;
    overflow: hidden;
    backface-visibility: hidden;
    width: 100%;
    height: 100%;
}
.background-front {
    position: absolute;
    left: -94px;
    top: -113px;
    z-index: -1;
}
@font-face {
    font-family: 'CreditCard';
    src: url('../../../assets/fonts/Credit Card 400.ttf');
}
.card-number {
    font-family: 'CreditCard' !important;
    font-size: 17px;
}
/* Default text */
.default-text svg{
    transform: translateY(-2px);
}
.default-text.mc {
    transform: translateY(-6px);
    padding-right: 10px;
}
.default-text.visa {
    transform: translateY(-10px);
}
.deactivate-hover:hover {
    color: white !important;
}
a.card-options {
    line-height: 18px;
}
a.card-options:hover {
    text-decoration: underline !important;
}
/* white background */
a.card-options:nth-child(2), a.card-options:nth-child(3) {
    border-left: 1px solid var(--gray-1001);
}
/* colored background */
a.deactivate-hover:nth-child(2), a.deactivate-hover:nth-child(3) {
    border-left: 1px solid white;
    border-left-width: 80%;
}

.card-back {
    transform: rotateY(180deg);
}
.credit-card.active {
    transform: rotateY(180deg);
}
/* Credit card back */
.card-band {
    background: rgba(0, 0, 19, 0.8);
    width: 100%;
    height: 40px;
    margin-top: 60px;
    position: relative;
    z-index: 2;
}
.card-cvv-input {
    background: #fff;
    color: #1a3b5d;

    height: 45px;
    font-size: 18px;

    margin-bottom: 10px;
    padding-right: 10px;

    border-radius: 4px;
    box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);
}
.slide-fade-right-enter-active {
    transition: all 0.25s ease-in-out;
    transition-delay: 0.1s;
}
.slide-fade-right-leave-active {
    transition: all 0.25s ease-in-out;
}
.slide-fade-right-enter {
    opacity: 0;
    pointer-events: none;
}
.slide-fade-right-enter-to {
    opacity: 1;
    pointer-events: none;
}
.slide-fade-right-leave-to {
    opacity: 0;
    transform: translateY(-10px) rotate(45deg);
    pointer-events: none;
}
@media (min-width : 576px) {
    .credit-card {
        min-height: 230px;
    }
    .card-number {
        font-size: 18px;
    }
}
</style>
