<template>
    <icon-base :width="size + 'px'" :fill="color">
        <switch>
            <g i:extraneous="self">
            <g>
                <circle class="st0" cx="125" cy="125" r="124.5"/>
                <g>
                <g>
                    <path class="st1" d="M194.6,77.1H55.4c-5.3,0-9.7,4.3-9.7,9.7v76.5c0,5.3,4.3,9.7,9.7,9.7h139.2c5.3,0,9.7-4.3,9.7-9.7V86.7       C204.1,81.5,199.9,77.1,194.6,77.1z M194.6,82.5c2.4,0,4.3,2,4.3,4.3v10.9c-7.5-1.3-16.5-7.5-16.5-15.2H194.6z M170.7,125       c0,3.6-3,6.5-6.5,6.5c-3.6,0-6.5-3-6.5-6.5c0-3.6,3-6.5,6.5-6.5C167.7,118.5,170.7,121.4,170.7,125z M151.2,125       c0,14.5-11.8,26.3-26.3,26.3S98.6,139.5,98.6,125s11.8-26.3,26.3-26.3C139.4,98.8,151.2,110.5,151.2,125z M85.7,118.5       c3.7,0,6.5,3,6.5,6.5c0,3.6-3,6.5-6.5,6.5c-3.6,0-6.5-3-6.5-6.5C79.1,121.4,82.1,118.5,85.7,118.5z M50.8,86.7       c0.1-2.4,2.1-4.3,4.6-4.3h11.9c0,7.5-9,13.9-16.5,15.2V86.7z M55.4,167.7c-2.4,0-4.3-2-4.3-4.3v-10.9       c7.5,1.3,16.5,7.5,16.5,15.2H55.4z M199.2,163.4c-0.3,2.3-2.3,4.3-4.6,4.3h-11.9c0-7.5,9-13.9,16.5-15.2V163.4z"/>
                    <path class="st1" d="M123.1,112.1v1.4c-1.8,0.4-3.4,1.1-4.6,2.3c-1.3,1.3-2,2.8-2,4.7c0,1.6,0.4,2.8,1.1,3.7       c0.7,0.9,1.7,1.6,2.7,2c1,0.4,2.3,0.9,3.8,1.3c1.1,0.3,2,0.6,2.6,0.7c0.6,0.1,1,0.4,1.3,0.7c0.3,0.3,0.4,0.6,0.4,1       c0,0.9-0.3,1.4-0.9,1.8c-0.7,0.4-1.6,0.7-2.8,0.7c-1,0-1.8-0.1-2.7-0.4c-0.9-0.3-1.7-0.7-2.7-1.3c-0.3-0.1-0.6-0.3-0.7-0.4       c-0.3-0.1-0.6-0.1-0.9-0.1c-0.4,0-0.9,0.1-1.4,0.7c-0.3,0.4-0.6,1-0.6,1.6c0,0.4,0,0.7,0.1,1.1c0.1,0.3,0.4,0.6,0.7,0.9       c0.9,0.7,1.8,1.3,3,1.7c1,0.3,2,0.6,3,0.7v1.3c0,0.6,0.1,1.1,0.6,1.6c0.4,0.4,1,0.6,1.6,0.6c0.6,0,1.1-0.1,1.6-0.6       c0.4-0.4,0.6-1,0.6-1.6v-1.4c1.8-0.4,3.3-1.1,4.4-2.3c1.3-1.3,1.8-2.8,1.8-4.6c0-1.6-0.4-2.7-1.1-3.6c-0.7-0.9-1.6-1.6-2.7-2       c-1-0.4-2.3-0.9-3.7-1.1c-1.1-0.3-2-0.6-2.7-0.7c-0.6-0.1-1-0.4-1.4-0.9c-0.3-0.3-0.4-0.7-0.4-1.3c0-0.9,0.3-1.4,0.9-1.8       c0.7-0.4,1.6-0.7,2.7-0.7c1,0,1.8,0.1,2.4,0.4c0.7,0.3,1.4,0.7,2.3,1.3c0.4,0.3,0.6,0.4,0.9,0.4c0.3,0.1,0.4,0.1,0.7,0.1       c0.4,0,0.9-0.1,1.4-0.7c0.3-0.4,0.6-1,0.6-1.6c0-0.9-0.3-1.4-1-2c-1.4-1.1-3.1-2-5.1-2.3v-1.4c0-0.6-0.1-1.1-0.6-1.6       c0,0,0,0,0,0c-1.1-1.1-3.1-0.1-3.1,1.4C123.1,111.9,123.1,112,123.1,112.1z"/>
                </g>
                </g>
            </g>
            </g>
        </switch>
    </icon-base>
</template>
<script>

export default {
name: 'iconCash',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>
    .st0{fill:#DCE4EA;}
    .st1{fill:#111820;}
</style>
