<template>
    <main-modal :title="'Carrier Rates'" @closeModal="$emit('closeModal')">
        <alert-warning v-if="!loading" style="padding: 2px 10px; background-color: #e9bb0b1f;" :size="50" :show="carrierSelected.object_id != undefined" title="Already has a carrier selected" :showCounter="false" class="mt-3" :vibration="true" id="alert-warning-without-delivery" />
        <ul v-if="!loading" class="list-address p-0 m-0 mb-3" id="list-address">
            <li v-for="(carrier, index) in shippoData" :key="index" class="address position-relative" :class="{ 'selected-carrier': selectedCarrier === carrier }">
                <div class="row mx-0"  @click="selectCarrier(carrier)">
                    <div class="col-sm-12 d-flex">
                        <div class="w-100">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="d-flex text-limited align-items-center">
                                    <input type="radio" :id="'carrier-' + index" v-model="selectedCarrier" :value="carrier" class="carrier-radio">
                                    <label :for="'carrier-' + index" class="provider-image-label d-none d-sm-block">
                                        <img :src="carrier.provider_image_75" class="provider-image">
                                    </label>
                                    <span class="info-separator d-none d-md-inline-block mx-2 font-weight-light color-dark-gray-200">|</span>
                                    <div>
                                        <span class="provider-name">{{ carrier.provider_name }}</span>
                                        <span class="service-level" v-if="carrier.servicelevel.display_name == null">{{ carrier.provider +' '+  carrier.servicelevel.name }}</span>
                                        <span class="service-level" v-else style="display: block; word-wrap: break-word;">{{ carrier.servicelevel.display_name }}</span>
                                    </div>
                                </div>
                                <div class="d-flex flex-column align-items-end">
                                    <span class="amount text-danger weight-600">{{ carrier.amount | moneyFormat }}</span>
                                    <span>{{ carrier.estimated_days }} {{ carrier.estimated_days != 1 ? ' Days' : ' Day'  }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <div v-else style="justify-content: center; align-items: center; display: flex;">
            <HalfCircleSpinner  :animation-duration="1000" :size="40" color="var(--main-red)" />
        </div>
        <div style="justify-content: end; display: flex;">
            <main-button :disabled="selectedCarrier == null" :id="'sabe'" :title="'Save'" @click="saveSelectedCarrier"/>
        </div>
    </main-modal>
</template>

<script>
import MainModal from '../../../../common/components/modals/MainModal.vue'
import AlertWarning from '../../../../common/components/alerts/AlertWarning.vue'
import { mapGetters } from "vuex"
import { http } from '../../../../common/services/web-backend'
import { HalfCircleSpinner } from 'epic-spinners'
import MainButton from '../../../../common/components/buttons/MainButton.vue'
import { parseResponse } from '../../../../common/helpers/responses/parser'

export default {
name: "CheckoutShippo",
props: {
        mutateProducts: {
            type: Object,
            required: true
        },
    },
data() {
    return {
        collapse: true,
        shippoData: [],
        selectedCarrier: null,
        loading: false
    }
},
components:{
    MainModal,
    HalfCircleSpinner,
    MainButton,
    AlertWarning
},
computed: {
    ...mapGetters({
            addressSelected: "account/addressSelected",
            orderConfirm: "checkout/orderConfirm",
            carrierSelected: "account/carrierSelected"
    }),
},
watch: {
},
methods: {
    saveSelectedCarrier(){
        if(this.carrierSelected.object_id){
            if(this.carrierSelected.object_id != this.selectedCarrier.object_id){
                this.$store.commit("checkout/RESET_PAYMENTS")
            }
        }

        this.$store.commit("account/SET_CARRIER_SELECTED", this.selectedCarrier)
        const notify = {
            text: 'Carrier stored correctly',
            type: 'success',
            show: true,
        }
        this.$store.commit("common/SET_SHORT_NOTIFY", notify);
        this.$emit('closeModal')
    },
    selectCarrier(carrier) {
        this.selectedCarrier = carrier;
    },
    async postCreateShippo(){
        try {
            this.loading = true
            const mergedData = {
                address_id: this.addressSelected.id,
                products: this.mutateProducts
            }
            const data = await http.post("/shippo/create-shipment", mergedData);
            this.shippoData = data.data.data.rates
        } catch (e) {
            let error = parseResponse(e.response.data)
            this.loading = false
            this.$emit('closeModal')
            if(error.data[0]?.msg) {
                const warning = {
                    show: true,
                    title: this.$t('checkout.notice'),
                    description: error.data[0]?.msg,
                }
                this.$store.commit('common/SHOW_MODAL_ERROR', warning)
            }else{
                const warning = {
                    show: true,
                    title: this.$t('checkout.notice'),
                    description: 'service not available',
                }
                this.$store.commit('common/SHOW_MODAL_ERROR', warning)
            }
        } finally {
            this.loading = false
        }
    }
},
created(){
    this.postCreateShippo()
}
}
</script>

<style scoped>
    .list-address {
        list-style: none;
        overflow: auto;
        max-height: 400px;
        width: 470px;
    }
    .list-address > li:first-child { 
        /* border-top: 1px solid var(--light-gray-501); */
    }
    .responsive-description {
        max-width: 300px
    }
    .address {
        cursor: pointer;
        border: 2px solid rgba(92, 124, 136, 0.094);
        border-radius: 10px;
        margin: 8px;
        padding: 2%;
        position: relative;
        transition: var(--transition-1);
    }
    .address:hover {
        background: #7ca2bb27;
    }
    .carrier-radio {
        margin-right: 10px;
    }
    .selected-carrier {
        border-color: rgba(78, 203, 20, 0.569);
    }
    @media only screen and (max-width: 768px) {
        .list-address {
            width: 387px;
        }
    }
</style>