<template>
    <div class="table-responsive deposits-available mt-4">
        <h6 class="title-16 mb-3 weight-500" v-if="!isAccount">{{$t("checkout.select_from_the_available_deposits")}}</h6>
        <table  class="table text-black table-striped" >
        <thead style="border-top: 2px solid #a5b2b717; border-bottom: 2px solid #a5b2b717 !important">
            <tr>
                <td scope="col" class="text-center title-15" v-if="!isAccount"></td>
                <th scope="col" class="text-center title-15">{{$t("checkout.type")}}</th>
                <th scope="col" class="text-center title-15">{{$t("checkout.document")}} </th>
                <th scope="col" class="text-center title-15">{{$t("checkout.currency")}}</th>
                <th scope="col" class="text-center title-15">{{$t("checkout.total")}}</th>
                <th scope="col" class="text-center title-15">{{$t("checkout.available")}}</th>
                <th scope="col" class="text-center title-15" v-if="!isAccount">{{$t("checkout.amount_to_use")}}</th>
            </tr>
        </thead>
            <tbody>
            <tr class="deposit" :key="deposit.document_id" v-for="(deposit, index) in deposits" v-show="optionDeposit">
                <td class="align-middle title-14 text-center weight-500" v-if="!isAccount">
                    <div class="d-flex">
                        <main-checkbox :id="deposit.document_id" isList :value="deposit.document_id" :values="selectedDeposits" @changeFromLabel="selectDeposit(deposit)"/>
                    </div>
                </td>
                <td class="align-middle title-14 text-center weight-500">
                    <icon-circle :size="13" :color="'var(--green-600)'"/>
                </td>
                <td class="align-middle title-14 text-center weight-500">{{ deposit.document_id }}</td>
                <td class="align-middle title-14 text-center weight-500">{{ deposit.label_currency === 'PESO' ? 'DOP' : 'USD' }}</td>
                <td class="align-middle title-14 text-center weight-500">{{ deposit.symbol_currency }} {{ parseFloat(deposit.total_amount).toFixed(2) }}</td>
                <td class="align-middle title-14 text-center weight-500">{{deposit.balance | moneyFormat}}</td>
                <td class="align-middle title-14 text-center weight-500" @click="selectDepositFromInput(deposit)" v-if="!isAccount">
                    <input class="input-money" type="number" v-model="deposit.balanceSelected" :max="deposit.balance" min="0" 
                    :class="[!isSelected(deposit.document_id) ? 'opacity-4':'']" :key="index" @keyup="inputDeposit(deposit.document_id)" placeholder="RD$ 0.00">
                </td>
            </tr>
            <tr class="deposit" :key="note.document_id" v-for="(note, index) in creditsNote" v-show="optionCreditNotes">
                <td class="align-middle title-14 text-center weight-500" v-if="!isAccount">
                    <div class="d-flex">
                        <main-checkbox :id="note.document_id" :isList="true" :value="note.document_id" :values="selectedNotes" @changeFromLabel="selectNote(note)"/>
                    </div>
                </td>
                <td class="align-middle title-14 text-center weight-500">
                    <icon-circle :size="13" :color="'var(--blue-600)'"/>
                </td>
                <td class="align-middle title-14 text-center weight-500">{{ note.document_id }}</td>
                <td class="align-middle title-14 text-center weight-500">{{ note.label_currency === 'PESO' ? 'DOP' : 'USD' }}</td>
                <td class="align-middle title-14 text-center weight-500">{{ note.symbol_currency }} {{ parseFloat(note.total_amount).toFixed(2) }}</td>
                <td class="align-middle title-14 text-center weight-500">{{note.balance | moneyFormat}}</td>
                <td class="align-middle title-14 text-center weight-500" @click="selectNoteFromInput(note)" v-if="!isAccount">
                    <input class="input-money" type="number" v-model="note.balanceSelected" :max="note.balance" min="0" :class="[!isSelected(note.document_id) ? 'opacity-4':'']"
                    :key="index" @keyup="inputNote(note.document_id)" placeholder="RD$ 0.00">
                </td>
            </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-end" v-if="!isAccount">
            <h6 class="title-15">{{$t("checkout.total")}} {{amountVailable | moneyFormat(amountVailableCredit)}}</h6>
        </div>
        <all-paid v-if="showAllPaid" @closeModal="showAllPaid = false" @confirm="reset"/>
        <single-payment v-if="showNormalPayment" @closeModal="showNormalPayment = false" @confirm="reset" :title="'checkout.single_payment'" :description="'checkout.msg_have_a_single_payment_method_selected'"/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MainCheckbox from '../../../../common/components/checkbox/MainCheckbox.vue';
import IconCircle from '../../../../common/svg/iconCircle.vue';
import AllPaid from '../modals/AllPaid.vue';
import SinglePayment from '../modals/SinglePayment.vue';

export default {
    components: { MainCheckbox, IconCircle, SinglePayment, AllPaid },
    name: "DepositsAvailable",
    data() {
        return {
            selectedDeposits: [],
            selectedNotes: [],
            showNormalPayment: false,
            showAllPaid: false
        }
    },
    props:{
        isAccount: {
            type: Boolean,
            default: false
        }
    },
    computed:{
        amountVailable(){
            let sum = 0;
            this.depositSelected.forEach(element =>  sum += element.balanceSelected ? parseFloat(element.balanceSelected) : 0);
            return sum
        },
        amountVailableCredit(){
            let sum = 0;
            this.creditNoteSelected.forEach(element =>  sum += element.balanceSelected ? parseFloat(element.balanceSelected) : 0);
            return sum
        },
        ...mapGetters({
            depositSelected: "checkout/depositSelected",
            deposits: "checkout/deposits",
            creditsNote: "checkout/creditsNote",
            paymentsSelected: "checkout/paymentsSelected",
            difference: "checkout/difference",
            creditNoteSelected: "checkout/creditNoteSelected",
            singlePaymentActive: "checkout/singlePaymentActive",
            configurations: "common/configurations",
            userData: "auth/userData",

        }),
        optionDeposit(){
            let payment_methods = this.configurations.payment_methods
            let userFinal = this.userData.customer.price_level_id === 1
            if(payment_methods.deposit.active){
                if(userFinal){
                    return payment_methods.deposit.final
                }else{
                    return payment_methods.deposit.tienda
                }
            }
            return false
        },
        optionCreditNotes(){
            let payment_methods = this.configurations.payment_methods
            let userFinal = this.userData.customer.price_level_id === 1
            if(payment_methods.notas_de_credito.active){
                if(userFinal){
                    return payment_methods.notas_de_credito.final
                }else{
                    return payment_methods.notas_de_credito.tienda
                }
            }
            return false
        }
    },
    watch:{
        async singlePaymentActive(val){
            if(val){
                this.selectedDeposits = []
                this.selectedNotes = []
            }
        },
        async depositSelected(val){
            if(val.length == 0){
                this.selectedDeposits = []
                this.$store.commit('checkout/SET_DEPOSITS', this.deposits)
            }
        },
        async creditNoteSelected(val){
            if(val.length == 0){
                this.selectedNotes = []
                this.$store.commit('checkout/SET_CREDITS_NOTE', this.creditsNote)
            }
        }
    },
    methods:{
        selectDeposit(deposit){
            if(this.singlePaymentActive){
                this.showNormalPayment = true
                return
            }
            if(parseFloat(this.difference) === 0 && (parseFloat(deposit.balanceSelected) == 0 || deposit.balanceSelected == '')){
                this.showAllPaid = true
                return
            }
            const index = this.selectedDeposits.findIndex(product => product === deposit.document_id)
            if(index >= 0){
                this.selectedDeposits.splice(index, 1);
            }else{
                this.selectedDeposits.push(deposit.document_id)
            }
            this.$store.commit('checkout/SET_DEPOSIT', deposit);
        },
        selectDepositFromInput(deposit){
            if(parseFloat(this.difference) === 0){
                this.showAllPaid = true
                return
            }
            const index = this.selectedDeposits.findIndex(product => product === deposit.document_id)
            if(index === -1){
                this.selectedDeposits.push(deposit.document_id)
                this.$store.commit('checkout/SET_DEPOSIT', deposit);
            }
        },
        isSelected(id){
            const index = this.selectedDeposits.findIndex(product => product === id)
            return index >= 0
        },
        inputDeposit(val){
            let selected = this.depositSelected.filter((deposit)=>{
                return deposit.document_id === val
            })
            if(parseFloat(selected[0].balanceSelected) > parseFloat(selected[0].balance)){
                selected[0].balanceSelected = parseFloat(selected[0].balance)
            }else{
                selected[0].balanceSelected = parseFloat(selected[0].balanceSelected)
            }
            if(parseFloat(this.difference) <= 0){
                selected[0].balanceSelected = 0
                selected[0].balanceSelected = parseFloat(this.difference)
            }
            localStorage.setItem('paymentsSelected', JSON.stringify(this.paymentsSelected));
        },
        selectNote(note){
            if(parseFloat(this.difference) === 0 && (parseFloat(note.balanceSelected) == 0 || note.balanceSelected == '')){
                this.showAllPaid = true
                return
            }
            const index = this.selectedNotes.findIndex(product => product === note.document_id)
            if(index >= 0){
                this.selectedNotes.splice(index, 1);
            }else{
                this.selectedNotes.push(note.document_id)
            }
            this.$store.commit('checkout/SET_CREDIT_NOTE', note);
        },
        inputNote(val){
            let selected = this.creditNoteSelected.filter((note)=>{
                return note.document_id === val
            })
            if(parseFloat(selected[0].balanceSelected) > parseFloat(selected[0].balance)){
                selected[0].balanceSelected = parseFloat(selected[0].balance)
            }else{
                selected[0].balanceSelected = parseFloat(selected[0].balanceSelected)
            }
            if(parseFloat(this.difference) <= 0){
                selected[0].balanceSelected = 0
                selected[0].balanceSelected = parseFloat(this.difference)
            }
            localStorage.setItem('paymentsSelected', JSON.stringify(this.paymentsSelected));
        },
        selectNoteFromInput(note){
            if(parseFloat(this.difference) === 0){
                this.showAllPaid = true
                return
            }
            const index = this.selectedNotes.findIndex(element => element === note.document_id)
            if(index === -1){
                this.selectedNotes.push(note.document_id)
                this.$store.commit('checkout/SET_CREDIT_NOTE', note);
            }
        },
        reset(){
            this.$store.commit("checkout/RESET_PAYMENTS")
            this.showNormalPayment = false
            this.showAllPaid = false
        }       
    },
    async created(){
        await this.$store.dispatch("checkout/getDeposits")
        await this.$store.dispatch("checkout/getCreditsNotes")
        let deposits = []
        for (let i = 0; i < this.depositSelected.length; i++) {
            const deposit = this.depositSelected[i];
            deposits.push(deposit.document_id)
        }
        this.selectedDeposits = deposits

        // notes
        let notes = []
        for (let i = 0; i < this.creditNoteSelected.length; i++) {
            const deposit = this.creditNoteSelected[i];
            notes.push(deposit.document_id)
        }
        this.selectedNotes = notes
    } 
}
</script>

<style scoped>
    .table-striped>tbody>tr:nth-of-type(odd)>*{
        --bs-table-striped-bg: var(--light-gray-50);
    }
    .table>:not(caption)>*>*{
        padding: 0.3rem 0.5rem;
    }
    td{
        color: var(--dark-gray-300) !important;
    }
    tr{    
        border-color: transparent;
    }
    .input-money{
        width: 110px;
        border: 1px solid var(--gray-1001);
        border-radius: 3px;
    }
    .input-money:focus-visible{
        outline: transparent;
    }
    input::placeholder{
        text-align: center;
        color: var(--dark-gray-300);
        font-size: 14px;
        padding: 0;
        margin: 0;
        width: 100%;
    }
</style>
