<template>
    <div>
        <list-cards :cards="cards" @editCard="editCard" />
        <div class="mt-3 d-flex aling-items-center justify-content-between">
            <h6 class="title-16 mb-0 d-flex align-items-cente btn p-0" style="cursor:pointer" @click="showCreateCard = !showCreateCard" v-if="cards.length">
                <icon-add :size="25" class="me-2" :class="{open : showCreateCard}"/>
                {{$t("checkout.add_new_card")}}
            </h6>
            <div @click="defaultCard = !defaultCard" class="d-flex" v-if="showCreateCard">
                <main-switch class="d-none d-sm-flex" :description="'checkout.default'" :id="`default-1`" :value="defaultCard"/>
            </div>
        </div>
        <card-form v-if="showCreateCard || !cards.length" :updateData="selectedCard" @clearSelected="selectedCard = null; showCreateCard = false" @clear="selectedCard = null"
        :default="defaultCard" @setDefault="defaultCard = true">
            <main-switch class="mt-3 d-sm-none" :description="'checkout.default'" id="default-1" :value="defaultCard"/>
        </card-form>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import IconAdd from '../../../../common/svg/iconAdd.vue';
import CardForm from './cards/CardForm.vue';
import ListCards from './cards/ListCards.vue'
import MainSwitch from "../../../../common/components/switch/MainSwitch.vue";

export default {
    components: { ListCards, IconAdd, CardForm, MainSwitch },
    name:"CreditCard",
    computed: {
        ...mapGetters({
            cards: "account/cards"
        }),
    },
    data() {
        return {
            showCreateCard: false,
            showSinglePayment: false,
            selectedCard: null,
            defaultCard: false
        }
    },
    async created(){
        await this.$store.dispatch("account/getCards");
    },
    methods: {
        editCard(card){
            this.showCreateCard = true;
            setTimeout(() => {
                this.selectedCard = card;
            }, 100);
        }
    }
}
</script>

<style scoped>
.open.icon-add::v-deep > g > circle{
    fill: var(--main-red);
}
.open {
    transform: rotate(45deg);
}
.icon-add::v-deep {
    transition: var(--transition-1);
}
@media (max-width : 576px) {
    .card-form::v-deep .button-container {
        justify-content: center !important;
    }
}

@media (max-width: 767px) {
    .card-form::v-deep .card-number {
        font-size: 15px;
    }
    .card-form::v-deep .credit-card-container {
        margin-top: 40px !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .card-form::v-deep > .col-md-6 {
        width: 70%;
        padding-inline: 45px;
        margin-inline: auto;
    }    
    .card-form::v-deep .credit-card-container {
        margin-bottom: 10px;
        width: 360px;
    }
    .card-form::v-deep div.card-container:first-of-type {
        display: block !important;
        padding-left: 150px;
    }
    .card-form::v-deep div.card-container:nth-child(3){
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .card-form::v-deep .credit-card-container {
        margin: 30px 0px 0px 115px !important;
    }
    .card-form::v-deep .card-container {
        width: 100% !important;
        padding-inline: 0 !important;
    }
    .card-form::v-deep .main-input .input-group {
        margin-bottom: 0 !important;
    }
    /* mala mía*/
    .card-form::v-deep > .col-md-6 { 
        width: 80%;
        padding-inline: 45px;
        margin-inline: auto;
    }
    .card-form::v-deep div.card-container:first-of-type {
        display: block !important;
        padding-left: 50px;
    }
    .card-form::v-deep div.card-container:nth-child(3){
        display: none !important;
    }
}
@media (min-width : 576px) {
    .card-form::v-deep .credit-card-container {
        margin-left: 7px;
    }
    .card-form::v-deep .main-input .input-group {
        margin-bottom: 0 !important;
    }
    .card-form::v-deep .card-number {
        font-size: 17px;
    }
}
@media (min-width: 1200px) {
    .card-form::v-deep .credit-card-container {
        margin-top: 30px !important;
    }
}


</style>
