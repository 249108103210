<template>
    <main-confirm @confirm="$emit('closeModal')" @cancel="$emit('closeModal')" confirm-title="checkout.transaction_not_completed" :oneOption="true"
    :description="description ? description : 'checkout.description_transaction_not_completed'" :buttonConfirmTitle="'checkout.close'" :isQuestion="products.length == 0">
        <icon-warning :size="60"/>
    </main-confirm>
</template>
<script>
import MainConfirm from '../../../../common/components/modals/MainConfirm.vue'
import IconWarning from '../../../../common/svg/iconWarning.vue'
export default {
    components: { MainConfirm, IconWarning},
    name: "MakeOrderCard",
    props:{
        description:{
            type: String
        },
        products:{
            type: Array
        }
    }
}
</script>

<style scoped>
    .main-confirm::v-deep> .modal-main div p{
        font-size: 15px;
        width: 400px;
    }   
</style>