<template>
    <div class="row mt-2">
        <div class="col-md-6" @click="validate">
            <label class="mb-2 subtitle-15" id="cash-2">{{$t("checkout.enter_amount_to_pay")}}</label>
            <div class="input-group">
                <span class="input-group-text" id="balance"><icon-dollar :size="15" class="me-2" :color="'var(--dark-gray-200)'"/></span>
                <input type="number" class="form-control" placeholder="RD$ 0.00" v-model="amount" aria-label="RD$ 0.00" aria-describedby="balance" 
                :disabled="showAllPaid && !depositExpressSelected.balance > 0">
            </div>
        </div>
        <div class="col-md-6 d-flex align-items-end justify-content-end  mt-2 mt-md-0">
            <main-button :title="'checkout.see_bank_accounts'" @click="showBanks = true"/>
        </div>
        <div class="col-md-12 mt-4">
            <vue-dropzone ref="depositDropZone" id="imgDropZone" @vdropzone-max-files-exceeded="limit" :useCustomSlot="true"     
            @vdropzone-complete="afterComplete" :options="DepositOptions" @vdropzone-removed-file="removeThisFile">
                <div>
                    <icon-add-image :size="60" :color="'var(--gray-1001)'"/>
                    <h6 class="m-0 my-1" style="font-family: 'Rubik'; font-weight: 500;">{{$t('checkout.upload_image')}}</h6>
                    <span style="color: #a5b2b7; font-family: 'Rubik'; font-weight: 300;">{{$t('checkout.drag_or_upload_your_voucher_here')}}</span>
                </div>
            </vue-dropzone>
        </div>
        <bank-accounts v-if="showBanks" @closeModal="showBanks = false"/>
        <single-payment v-if="showSinglePayment" @closeModal="cancel" @confirm="confirm" :title="'checkout.single_payment'" 
        :description="'checkout.msg_have_a_single_payment_method_selected'"/>
        <all-paid v-if="showAllPaid && !depositExpressSelected.balance > 0" @closeModal="showAllPaid = false" @confirm="reset"/>
    </div>
</template>
<script>
import MainButton from '../../../../common/components/buttons/MainButton.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import vueDropzone from 'vue2-dropzone'
import { mapGetters } from "vuex"
import SinglePayment from '../modals/SinglePayment.vue'
import IconDollar from '../../assets/svg/iconDollar.vue'
import AllPaid from '../modals/AllPaid.vue'
import BankAccounts from '../modals/BankAccounts.vue'
import IconAddImage from '../../../../common/svg/iconAddImage.vue'

export default {
    components: { 
        MainButton, 
        vueDropzone,
        SinglePayment,
        IconDollar,
        AllPaid,
        BankAccounts,
        IconAddImage
    },
    name: "UploadDeposit",
    data() {
        return {
            amount: null,
            showSinglePayment: false,
            DepositOptions: {
                    url: '/',
                    maxFiles: 2,
                    thumbnailWidth: 200,
                    resizeQuality: .6,
                    addRemoveLinks: true,
                    autoProcessQueue: false,
                    maxFilesize: 2,
                    addedfiles: (file) => { this.afterComplete(file)},
                    resizeWidth: 200,
                    acceptedFiles: ".jpeg,.jpg,.png",
            },
            files: [],
            images: [],
            showAllPaid: false,
            showBanks: false
        }
    },
    computed:{
        ...mapGetters({
            difference: "checkout/difference",
            creditSelected: "checkout/creditSelected",
            cardSelected: "checkout/cardSelected",
            paypalSelected:"checkout/paypalSelected",
            singlePaymentActive: "checkout/singlePaymentActive",
            amountSelected: "checkout/amountSelected",
            depositExpressSelected: "checkout/depositExpressSelected",
            orderConfirm: "checkout/orderConfirm",

        }),
    },
    watch:{
        amount(val){
            this.showAllPaid = false
            if(val){
                if(this.singlePaymentActive){
                    this.showSinglePayment = true
                    return
                }else{
                    this.$store.commit("checkout/SET_NEW_DEPOSIT", this.amount)
                    if(this.depositExpressSelected.images.length > 0){
                        if(parseFloat(this.difference) <= 0){
                            this.$store.commit("checkout/SET_NEW_DEPOSIT", 0)
                            this.amount = parseFloat(this.difference)
                            this.$store.commit("checkout/SET_NEW_DEPOSIT", this.amount) 
                        }
                    }else{
                        if(this.amountSelected + parseFloat(this.amount) > parseFloat(this.orderConfirm.total_order)){
                            this.$store.commit("checkout/SET_NEW_DEPOSIT", 0)
                            this.amount = parseFloat(this.difference)
                            this.$store.commit("checkout/SET_NEW_DEPOSIT", this.amount)
                        }
                    }
                }
            }else{
                this.$store.commit("checkout/SET_NEW_DEPOSIT", 0)
            }
        },
        'depositExpressSelected.balance'(val){
            if(val == 0){
                this.amount = null
                this.$refs.depositDropZone.removeAllFiles()
            }
        },
        difference(val){
            if(this.amount > parseFloat(val) && this.depositExpressSelected.images.length == 0){
                this.amount = parseFloat(val)
            }
        }
    },
    methods:{
        async limit(){
            this.$refs.depositDropZone.removeAllFiles()
        },
        async afterComplete(upload) {
            try {
                let file = upload[0];
                this.images.push({  name: file.name, file });
                this.$store.commit('checkout/SET_IMAGES_TO_NEW_DEPOSIT',   this.images);
                if (this.images.length === 2){
                    this.$refs.depositDropZone.disable();
                }
            } catch (error) {
                console.log(error)
            }
        },       
        confirm(){
            this.$store.commit("checkout/RESET_PAYMENTS")
            this.showSinglePayment = true
        },
        cancel(){
            this.amount = null
            this.showSinglePayment = false
        },
        async removeThisFile(file){
            try{
                let FileName = file.name;
                this.$refs.depositDropZone.enable();
                this.images = await this.images.filter((image)=> image.name !== FileName);
                this.$store.commit('checkout/SET_IMAGES_TO_NEW_DEPOSIT',  this.images);
                if(this.images.length == 0){
                    this.$store.commit("checkout/SET_NEW_DEPOSIT", 0)
                }
            }catch (e) {
                console.log(e);
            }

        },
        validate(){
            if(this.difference == 0){
                this.showAllPaid = true
            }
        },
        reset(){
            this.$store.commit("checkout/RESET_PAYMENTS")
            this.showAllPaid = false
        }
    },
    created(){
        if(this.depositExpressSelected.balance > 0){
            this.amount = this.depositExpressSelected.balance
        }
    }
}
</script>

<style scoped>
    .vue-dropzone {
        border: none;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23C6D4DBFF' stroke-width='3' stroke-dasharray='16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 15px;
    }
    label{
        font-size: 15px;
        color: var(--main-navy); 
        font-weight: 600;
    }
    .form-control:disabled{
        background: transparent;
    }
    .form-control{
        padding: 0.1rem 3px;
    }
    .input-group-text{
        background: transparent;
        border-color: var(--gray-100) !important;
        border-radius: 7px;
        padding-right: 0px;
    }
    input::placeholder{
        font-size: 14px;
    }
    input:focus{
        box-shadow: none;
    }
    input{
        height: 38px !important;
        border-color: var(--gray-100) !important;
        border-radius: 7px;
        border: 1px solid;
        width: 100%;
        font-size: 14px;
        border-left: 0;
        background-color: none  !important;
    }
</style>
