<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 100 81.235">
        <g id="Delete_card_icon_" data-name="Delete card icon " transform="translate(-922.951 -1874.305)">
            <path id="Path_1222" data-name="Path 1222" d="M-533.052,278.162a17.458,17.458,0,0,1-16.327-.916c-6.234-.218-13.139-.338-20.412-.338-27.614,0-50,1.749-50,3.908s22.385,3.907,50,3.907,50-1.75,50-3.907c0-1.023-5.032-1.954-13.26-2.65" transform="translate(1542.741 1670.816)" fill="#f0f4f9"/>
            <path id="Union_39" data-name="Union 39" d="M-2004.466,18566.363a2.528,2.528,0,0,1-2.53-2.529v-22.525h60.108v14.375a16.175,16.175,0,0,0-15.148,10.68Zm1.831-8.437v3.107a.45.45,0,0,0,.452.453h29.6a.453.453,0,0,0,.452-.453v-3.107a.453.453,0,0,0-.452-.453h-29.6A.449.449,0,0,0-2002.635,18557.926Zm0-7.031v3.111a.449.449,0,0,0,.452.449h19.3a.449.449,0,0,0,.452-.449v-3.111a.45.45,0,0,0-.452-.453h-19.3A.453.453,0,0,0-2002.635,18550.895Zm-4.366-17.846v-3.52a2.53,2.53,0,0,1,2.53-2.529h55.048a2.534,2.534,0,0,1,2.535,2.529v3.52Z" transform="translate(2949.237 -16637.391)" fill="#212e3d" stroke="rgba(0,0,0,0)" stroke-width="1"/>
            <path id="Path_2806" data-name="Path 2806" d="M-540.347,250.591A13.052,13.052,0,0,0-553.4,263.645v0a13.05,13.05,0,0,0,13.056,13.046,13.052,13.052,0,0,0,13.045-13.054A13.053,13.053,0,0,0-540.347,250.591Zm6.375,18.183A1.231,1.231,0,0,1-535.2,270a1.357,1.357,0,0,1-.2-.016,1.307,1.307,0,0,1-.714-.388l-4.228-4.228-4.228,4.228a1.318,1.318,0,0,1-.712.388,1.245,1.245,0,0,1-.4,0,1.232,1.232,0,0,1-1.01-1.414,1.312,1.312,0,0,1,.389-.707l4.228-4.227-4.226-4.226a1.32,1.32,0,0,1-.39-.707,1.22,1.22,0,0,1,.338-1.077,1.258,1.258,0,0,1,1.74,0l4.272,4.272,4.272-4.272a1.258,1.258,0,0,1,1.74,0,1.227,1.227,0,0,1,.338,1.077,1.326,1.326,0,0,1-.39.707l-4.224,4.226,4.226,4.227.01,0a1.324,1.324,0,0,1,.389.707,1.253,1.253,0,0,1,.017.2Z" transform="translate(1542.741 1670.816)" fill="#bc2c35"/>
            <rect id="Rectangle_489" data-name="Rectangle 489" width="99.998" height="81.231" transform="translate(922.953 1874.305)" fill="none"/>
        </g>
    </icon-base>
</template>
<script>

export default {
name: 'iconDeleteCard',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>
    .st0{fill:#DCE4EA;}
    .st1{fill:#111820;}
</style>
