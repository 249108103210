<template>
  <div v-if="checkoutSteps.addressOptionSelected === 1 || checkoutSteps.addressOptionSelected === 2">
    <template v-if="addressSelected" >
    <div class="d-flex justify-content-between px-md-4">
      <div>
        <h3 class="m-0 title-20 weight-400">{{$t("checkout.delivery_address")}}</h3>
        <span class="m-0 mt-3 subtitle-16 color-dark-gray-300">{{$t("checkout.confirm_or_change_your_address")}}</span>
      </div>
      <a class="main-link d-flex align-items-center ms-1" @click.prevent="editAddress">
        <icon-edit :color="'var(--blue-600)'" class="me-2" :size="15"/>
        {{$t("checkout.edit")}}
      </a>
    </div>
      <div class="row justify-content-between ms-0 me-0 px-md-4">
        <div class="col-sm-6 p-0 d-flex flex-column">
              <div class="d-flex align-items-center mt-4">
                <icon-person :size="15" :color="'var(--blue-600)'"/>
                <span class="title-15 mb-0 ms-2 text-capitalize">{{addressSelected.name | textFormat}}</span>
                <span v-if="addressSelected.default" class="ms-2">|</span>
                <div class="d-flex align-items-center ms-2" v-if="addressSelected.default">
                  <icon-star :size="15" class="me-2"/>
                  <span class="title-15 mb-0">{{$t("checkout.default")}}</span>
                </div>
              </div>
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center mt-1">
                    <icon-checkout-address :size="18" :color="'var(--blue-600)'" class="me-2" style="margin-left: -2px;"/>
                    <span class="text-limited weight-300 size-15 mb-0 text-capitalize">{{addressSelected.address_line2 || addressSelected.address_line | textFormat}}, {{addressSelected.city.city | textFormat}}</span>
                </div>
                <div class="d-flex align-items-center mt-1">
                    <icon-reference :size="15" :color="'var(--blue-600)'" class="me-2"/>
                    <span class="text-limited size-15 mb-0">Ref: <span class="weight-300">{{addressSelected.reference | textFormat}}</span></span>
                </div>
                <div class="d-flex align-items-center mt-1">
                    <icon-phone :size="15" :color="'var(--blue-600)'" class="me-2"/>
                    <span class="size-15 mb-0">Tel:<span class="ms-2 weight-300">{{addressSelected.phone | phoneFormat}}</span></span>
                </div>
              </div>
              <div class="mt-auto">
                <div class="d-flex my-3 my-sm-0 justify-content-between justify-content-sm-start align-items-center">
                  <span v-if="!carrierSelected.object_id"  class="size-15">{{$t("checkout.delivery_price")}}:  <strong>{{addressSelectedAvailable.price | moneyFormat}}</strong></span>
                  <span v-if="carrierSelected.object_id"  class="size-15">{{$t("checkout.delivery_price")}}:  <strong>{{carrierSelected.amount | moneyFormat}}</strong></span>
                  <span v-if="!carrierSelected.object_id" class="ms-4 size-14 main-link" @click.prevent="showDeliveryDetail = true">{{$t("checkout.see_details")}}</span>
                </div>
                <div class="d-flex flex-column align-items-start">
                  <span v-if="!carrierSelected.object_id" class="size-15 color-dark-gray-400 weight-400 mb-0">
                    <icon-check-order/>
                    {{$t("checkout.delivery_from_4_to_5")}}
                  </span>
                  <span v-if="carrierSelected.object_id" class="size-15 color-dark-gray-400 weight-400 mb-0">
                      <img :src="carrierSelected.provider_image_75" :size="20">
                        {{carrierSelected.servicelevel.display_name || carrierSelected.provider }} {{ carrierSelected.estimated_days }} {{ carrierSelected.estimated_days != 1 ? ' Days' : ' Day'  }}
                  </span>
                </div>
              </div>
        </div>
        <div class="col-sm-5 p-0 flex-column align-items-end">
          <div class="d-flex align-items-center justify-content-between mb-3 mt-4">
            <div class="d-flex pt-sm-4 pt-md-0">
              <icon-checkout-address :color="'#c40316'" :size="18" class="me-1"/>
              <h5 class="mb-0 weight-400 title-16">{{$t("checkout.my_location")}}</h5>
            </div>
            <ul class="nav nav-pills d-flex flex-row flex-sm-column flex-md-row align-items-sm-end">
              <li v-for="(style, index) in mapStyles" :key="index" class="nav-item">
                <div class="d-flex align-items-center">
                  <a @click.prevent="styleSelected = index" :class="{ active: index == styleSelected  }" href="#" class="main-link option color-dark-gray-200">
                    {{ $t("cart."+index) }}
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <MglMap v-if="addressSelected.longitud && addressSelected.latitud"  style="height: 210px;border-radius:10px; overflow: hidden;" :center="[addressSelected.longitud, addressSelected.latitud]" 
          :accessToken="accessToken" :zoom="14" :dragRotate="false"  @load="onMapLoaded" 
          :mapStyle="mapStyles[styleSelected]" >
            <MglMarker style="cursor: pointer" :coordinates="[addressSelected.longitud, addressSelected.latitud]" :draggable="false"  color="#c40316" />
            <MglNavigationControl position="top-right" />
          </MglMap>
        </div>
      </div>
      <delivery-detail @closeModal="showDeliveryDetail = false" :branch="branchSelected" :delivery="addressSelectedAvailable.delivery" :lat="addressSelected.latitud" :long="addressSelected.longitud" :quantity="quantity" v-if="showDeliveryDetail"/>
    </template>
  </div>
  <div v-else-if="checkoutSteps.addressOptionSelected === 3">
      <div class="d-flex justify-content-between px-md-4">
        <div>
          <h3 class="m-0 title-20 weight-400">{{$t("checkout.pick_up_address")}}</h3>
          <span class="m-0 mt-3 subtitle-16 color-dark-gray-300">{{$t("checkout.confirm_or_change_your_parner")}}</span>
        </div>
        <a class="main-link d-flex align-items-center ms-1" @click.prevent="editAddress">
          <icon-edit :color="'var(--blue-600)'" class="me-2" :size="15"/>
          {{$t("checkout.edit")}}
        </a>
      </div>
      <div class="row justify-content-between ms-0 me-0 px-0 px-md-4" style="padding: 20px 0px">
        <div class="col-sm-5 p-0">
              <div class="d-flex align-items-center mt-3">
                  <icon-person :size="15" :color="'var(--blue-600)'"/>
                  <span class="size-15 mb-0 ms-2 text-capitalize">{{partnerSelected.full_name | textFormat}}</span>
              </div>
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center mt-1" v-if="partnerSelected.identification">
                    <icon-last-name :size="15" :color="'var(--blue-600)'" class="me-2"/>
                    <span class="text-limited weight-300 size-15 mb-0">{{partnerSelected.identification}}</span>
                </div>
                <div class="d-flex align-items-center mt-1">
                    <icon-checkout-address :size="15" :color="'var(--blue-600)'" class="me-2"/>
                    <span class="text-limited weight-300 size-15 mb-0 text-capitalize">{{branchSelected.description | textFormat}}, {{branchSelected.city_name | textFormat}}</span>
                </div>
                <div class="d-flex align-items-center mt-1">
                    <div>
                      <icon-reference :size="15" :color="'var(--blue-600)'" class="me-2"/>
                    </div>
                    <span class="text-limited size-15 mb-0 text-capitalize">Ref: <span class="weight-300">{{branchSelected.address | textFormat}}</span></span>
                </div>
                <div class="d-flex align-items-center mt-1" v-if="branchSelected.phone">
                    <icon-phone :size="15" :color="'var(--blue-600)'" class="me-2"/>
                    <span class="size-15 mb-0">Tel:<span class="ms-2 weight-300">{{branchSelected.phone | phoneFormat}}</span></span>
                </div>
              </div>
        </div>
            <div class="col-sm-5 p-0 flex-column align-items-end mt-3 mt-sm-0">
              <div class="d-flex align-items-center justify-content-between mb-3 mt-3">
                <div class="d-flex">
                    <icon-checkout-address :color="'#c40316'" :size="18" class="me-1"/>
                    <h5 class="branch-location mb-0 weight-400 title-16">{{$t("checkout.branch_location")}}</h5>
                </div>
                <ul class="nav nav-pills d-flex justify-content-end">
                  <li class="nav-item" v-for="(style, index) in mapStyles" :key="index">
                    <div class="d-flex align-items-center">
                      <a @click.prevent="styleSelected = index" :class="{ active: index == styleSelected  }" href="#" class="main-link option color-dark-gray-200">
                        {{ $t("cart."+index)}}
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
              <MglMap v-if="branchSelected.long && branchSelected.lat" style="height: 210px;border-radius:10px; overflow: hidden;" :center="[branchSelected.long, branchSelected.lat]" 
              :accessToken="accessToken" :zoom="14" :dragRotate="false" @load="onMapLoaded" 
              :mapStyle="mapStyles[styleSelected]" >
                <MglMarker  style="cursor: pointer" :coordinates="[branchSelected.long, branchSelected.lat]" :draggable="false"  color="#c40316">
                </MglMarker>
                <MglNavigationControl position="top-right"/>
              </MglMap>
            </div>
          </div>
  </div>
</template>

<script>
  import { MglMap, MglMarker, MglNavigationControl } from 'v-mapbox'
  import Mapbox from "mapbox-gl";
  import { mapGetters } from "vuex";
  import 'mapbox-gl/dist/mapbox-gl.css'
  import IconPerson from '../../../../common/svg/iconPerson.vue';
  import IconStar from '../../../../common/svg/iconStar.vue';
  import IconCheckoutAddress from '../../assets/svg/iconCheckoutAddress.vue';
  import IconReference from '../../../../common/svg/iconReference.vue';
  import IconPhone from '../../../../common/svg/iconPhone.vue';
  import IconCheckOrder from '../../assets/svg/iconCheckOrder.vue';
  import IconEdit from '../../assets/svg/iconEdit.vue';
  import DeliveryDetail from '../modals/DeliveryDetail.vue';
  import IconLastName from '../../../account/assets/svg/iconLastName.vue';

  export default {
    name: "toDelivery",
    components: {
      MglMap,
      MglMarker,
      MglNavigationControl,
      IconPerson,
      IconStar,
      IconCheckoutAddress,
      IconReference,
      IconPhone,
      IconCheckOrder,
      IconEdit,
      DeliveryDetail,
      IconLastName,
    },
    data(){
      return{
        showAddressModal: false,
        addressEditData: null,
        accessToken: process.env.VUE_APP_MAPBOX_KEY,
          mapStyles: {
              Mapa: "mapbox://styles/fbernabe/clasqq2sj002w14qp6xg4dkus",
              Satellite: "mapbox://styles/fbernabe/clbcjvwmq002016nkqy7vxtzt",
          },
        styleSelected: "Mapa",
        showDeliveryDetail: false
      }
    },
    computed: {
      branchSelected(){
        return this.$store.getters['branches/branchSelected']
      },
      ...mapGetters({
          addressSelectedAvailable: "account/addressSelectedAvailable",
          getbranchSelected: "branches/branchSelected",
          partnerSelected: 'checkout/partnerSelected',
          checkoutSteps: "checkout/checkoutSteps",
          addressSelected: "account/addressSelected",
          branchSelected: "branches/branchSelected",
          quantity: 'cart/quantityProducts',
          carrierSelected: 'account/carrierSelected'
        })
    },
    methods: {
      editAddress(){
        this.$store.commit("checkout/SET_PAYMENT_STEP", false)
        this.$store.commit("checkout/SET_CONFIRMATION_STEP", false)
      },
      onMapLoaded(){
        this.mapbox = Mapbox;
      },
      functionBack(){
        console.log('emitiste')
        this.$emit('backDelivery')
      },
    },
  }
</script>

<style scoped>
  .option.active {
    color: var(--blue-600) !important;
    background: transparent;
    font-weight: 600 !important;
  }
  .text-details {
    color: #6a9ce5;
    font-size: 12px;
    cursor: pointer;
  }
/* Edit */
  .edit-btn > svg {
    transition: all .05s ease-in-out;
    fill: var(--blue-600);
  }
  .edit-btn:hover > svg {
    fill: var(--main-red) !important;
  }
  .edit-btn:hover {
    color: var(--main-red) !important;
  }
  .edit-btn {
    transition: var(--transition-1);
    color: var(--blue-600);
  }

  /* option */
  .option {
    transition: var(--transition-1);
    color: var(--gray-1001) !important;
  }
  .option:hover {
    color: var(--dark-gray-300);
  }
  .selected {
    font-weight: 600 !important;
    color: var(--blue-600) !important;
  }
  .selected:hover {
    color: var(--blue-600) !important;
  }

  li.nav-item:not(:last-child) div .option {
    border-right: 1.5px solid var(--gray-100);
    padding-right: .5rem;
    margin-right: .5rem;
  }

@media (min-width : 576px) {
  li.nav-item:not(:last-child) div .option {
    border-right: 0;
    padding-right: 0;
    margin-right: 0;
  }
  .mgl-map-wrapper ::v-deep > .mapboxgl-map > .mapboxgl-canvas-container > .mapboxgl-canvas{
    border-radius: 8px;
  }
}
@media (min-width : 768px) {
  .branch-location {
    width: 110px;
  }
  li.nav-item:not(:last-child) div .option {
    border-right: 1.5px solid var(--gray-100);
    padding-right: .5rem;
    margin-right: .5rem;
  }
}
@media (min-width : 1200px) {
  .branch-location{
    width: 110px;
  }
}
@media (min-width : 1400px) {
  .branch-location{
    width: 180px;
  }
}
</style>
