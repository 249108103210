<template>
    <icon-base :width="size + 'px'" :fill="color">
            <g id="Layer_9">
                <g>
                    <g>
                        <path class="st0" d="M196.8,155.7h-25.2c-16.9,0-31-13.4-31.2-30.3c-0.3-17.2,13.6-31.3,30.8-31.3h25.7l0,0c2.5,0,4.6-2,4.6-4.5     V79.5c0-8.6-7-15.6-15.6-15.6h-129c-8.6,0-15.6,7-15.6,15.6v90.8c0,8.6,7,15.6,15.6,15.6h129c8.6,0,15.6-7,15.6-15.6v-10.1     C201.4,157.7,199.4,155.7,196.8,155.7L196.8,155.7z"/>
                        <path class="st0" d="M197.4,102.1h-26.6c-12.6,0-22.9,10.2-22.9,22.9l0,0c0,12.6,10.2,22.9,22.9,22.9h26.6     c6.3,0,11.4-5.1,11.4-11.4v-22.9C208.8,107.2,203.7,102.1,197.4,102.1z M170.9,132.5c-4.1,0-7.5-3.4-7.5-7.5     c0-4.1,3.4-7.5,7.5-7.5s7.5,3.4,7.5,7.5C178.4,129.1,175,132.5,170.9,132.5z"/>
                        <path class="st1" d="M84.9,59l55.6-33.7c4.7-2.8,10.6-1.7,14.1,2.3L102.7,59H150l17.4-10.5l6.4,10.5h9l-20.5-33.9     c-5.3-8.8-16.8-11.6-25.6-6.3L70,59H84.9z"/>
                    </g>
                    <ellipse class="st2" cx="125" cy="223.7" rx="124.5" ry="10.2"/>
                </g>
            </g>
    </icon-base>
</template>
<script>

export default {
name: 'iconSinglePayment',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>
	.st0{fill:#212E3D;}
	.st1{fill:#4979B2;}
	.st2{fill:#F0F4F9;}
</style>
